import type { PurchaseItemResponse } from "@9amhealth/openapi";
import {
  GetSuggestedTreatmentPlanResponse,
  SubscriptionControllerService,
  SubscriptionDetailsResponse,
  TreatmentPlanControllerService
} from "@9amhealth/openapi";
import { Cubit } from "blac";
import FunnelKey from "src/constants/funnelKey";
import { globalEvents } from "src/constants/globalEvents";
import {
  MEDICARE_PROGRAM_SKU,
  PRESCRYPTIVE_PROGRAM_ITEM_SKU
} from "src/constants/misc";
import { TEST_STRIPS_SKU } from "src/constants/purchaseItemsSku";
import { dateLocal } from "src/lib/date";
import type { ParsedPurchaseItemResponse } from "src/lib/parsePurchaseItem";
import parsePurchaseItem from "src/lib/parsePurchaseItem";
import reportErrorSentry from "src/lib/reportErrorSentry";
import { LoadingKey } from "src/state/LoadingCubit/LoadingCubit";
import { KnownProgram } from "src/state/ProgramBloc/ProgramBloc";
import type { SubscriptionState } from "src/state/SubscriptionCubit/SubscriptionState";
import {
  ItemType,
  SubscriptionInterval
} from "src/state/TreatmentPlanCubit/TreatmentPlanCubit";
import { apiMiddleware, loadingState, userState } from "src/state/state";
import SignupCustomBloc from "src/ui/components/SignupCustomContent/state/SignupCustomBloc";
import { StorageController } from "../StorageBloc/StorageBloc";
import { PayerId } from "src/constants/payers";
import asTemplateValue from "lib/asTemplateValue";

export type SubscriptionId = string | (() => string | undefined) | undefined;

export default class SubscriptionCubit extends Cubit<SubscriptionState> {
  isDemoFunnel = false;
  constructor() {
    super({});

    window.addEventListener(globalEvents.USER_CLEAR, () => {
      this.emit({});
    });

    const cached =
      StorageController.activeUserId &&
      StorageController.getItem(this.cacheKey);
    if (cached) {
      this.emit(JSON.parse(cached) as SubscriptionState);
    }
  }

  cacheKey = "subscriptions";
  emitAndCache = (state: SubscriptionState): void => {
    this.emit(state);
    StorageController.setItem(this.cacheKey, JSON.stringify(state));
  };

  reset = (): void => {
    this.emitAndCache({});
  };

  setDemoFunnel = (isDemoFunnel: boolean): void => {
    this.isDemoFunnel = isDemoFunnel;
  };

  public readonly getFullSubscription = (
    status?: SubscriptionDetailsResponse.status[]
  ): SubscriptionDetailsResponse | undefined => {
    const filtered = this.filterAllSubscriptions({
      duration: [SubscriptionInterval.monthly, SubscriptionInterval.quarterly],
      status
    });
    return filtered[0] as SubscriptionDetailsResponse | undefined;
  };

  public readonly getSubscriptionById = (
    subscriptionId: SubscriptionId
  ): SubscriptionDetailsResponse | undefined => {
    if (!subscriptionId) return;
    const subId =
      typeof subscriptionId === "function" ? subscriptionId() : subscriptionId;
    const allSubs = this.state.allSubscriptions ?? [];
    const match = allSubs.find((subscription) => subscription.id === subId);
    // typecast SubscriptionDetailsResponse to SubscriptionDetailsResponse
    return match;
  };

  readonly getItemBySku = (
    subscriptionId: SubscriptionId,
    sku: string
  ): PurchaseItemResponse | undefined => {
    if (!subscriptionId) return;
    const subscription = this.getSubscriptionById(subscriptionId);
    if (!subscription?.purchaseItems) {
      return;
    }
    return subscription.purchaseItems.find((i) => i.sku === sku);
  };

  readonly fetchAndFilterSubscriptions = async (
    filter: {
      oneTime?: boolean;
      durations?: SubscriptionInterval[];
      status?: SubscriptionDetailsResponse.status[];
      items?: string[];
      ignoreItems?: string[];
      looseCheck?: boolean;
    } = {}
  ): Promise<SubscriptionDetailsResponse | undefined> => {
    loadingState.start(LoadingKey.subscriptionState);
    let subscription: SubscriptionDetailsResponse | undefined;
    try {
      await this.loadAllSubscriptions();
      const list = this.state.allSubscriptions ?? [];
      if (list.length === 0) {
        loadingState.finish(LoadingKey.subscriptionState);

        return;
      }

      const items = this.filterAllSubscriptions({
        duration:
          // use filter.durations first, if undefined use default durations
          filter.durations?.length
            ? filter.durations
            : filter.oneTime
              ? [SubscriptionInterval.oneTime]
              : [SubscriptionInterval.monthly, SubscriptionInterval.quarterly],
        status: filter.status,
        items: filter.items,
        ignoreItems: filter.ignoreItems,
        looseCheck: filter.looseCheck
      });

      // newest subscription from all subscriptions
      items.sort((a, b) => {
        return dateLocal(a.createdDate).unix() < dateLocal(b.createdDate).unix()
          ? -1
          : 1;
      });

      subscription = items[0] as SubscriptionDetailsResponse | undefined;
    } catch (e: unknown) {
      reportErrorSentry(e);
    }
    loadingState.finish(LoadingKey.subscriptionState);
    return subscription;
  };

  readonly knownTreatmentPlanTargets = {
    CDC_DPP: KnownProgram.CDC_DPP
  } as const;
  readonly checkTreatmentTargetEligibility = async (
    target: string
  ): Promise<GetSuggestedTreatmentPlanResponse | undefined> => {
    loadingState.start(LoadingKey.subscriptionState);
    let eligibility: GetSuggestedTreatmentPlanResponse | undefined;
    try {
      const response =
        await TreatmentPlanControllerService.suggestTreatmentPlanForTarget(
          target
        );
      eligibility = response.data;
    } catch (error: unknown) {
      reportErrorSentry(error);
    }
    loadingState.finish(LoadingKey.subscriptionState);
    return eligibility;
  };

  private loadAllSubscriptionsPromise:
    | Promise<SubscriptionDetailsResponse[]>
    | undefined;

  readonly loadAllSubscriptions = async (
    options: { cachedSubscriptionsAcceptable?: boolean } = {}
  ): Promise<void> => {
    if (
      options.cachedSubscriptionsAcceptable &&
      typeof this.state.allSubscriptions !== "undefined"
    ) {
      return;
    }

    let promise = this.loadAllSubscriptionsPromise;

    if (!promise) {
      promise = new Promise((resolve, reject) => {
        void SubscriptionControllerService.listSubscriptions()
          .then((d) => resolve(d.data))
          .catch((e) => reject(e as Error))
          .finally(() => {
            this.loadAllSubscriptionsPromise = undefined;
          });
      });
      this.loadAllSubscriptionsPromise = promise;
    }

    try {
      const response = await promise;

      // sort, newest first
      response.sort(
        (a, b) =>
          new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
      );

      const isSame =
        JSON.stringify(this.state.allSubscriptions) ===
        JSON.stringify(response);
      if (isSame) {
        return;
      }

      const newState: SubscriptionState = {
        ...this.state,
        allSubscriptions: response,
        hiddenItems: []
      };

      this.emitAndCache(newState);
    } catch (error: unknown) {
      reportErrorSentry(error);
    }
  };

  readonly filterAllSubscriptions = (filter: {
    status?: SubscriptionDetailsResponse.status[];
    duration?: SubscriptionInterval[];
    items?: string[];
    ignoreItems?: string[];
    metadataPartnerFunnel?: string[];
    metadataPayerId?: PayerId;
    /**
     * If looseCheck is true, the response can include subscriptions
     * which contain at least the SKUs defined in
     * "items", but can include more
     * -- Ignores: "ignoreItems"
     */
    looseCheck?: boolean;
  }): SubscriptionDetailsResponse[] => {
    const list = this.state.allSubscriptions ?? [];
    // sort
    list.sort((a, b) => {
      return dateLocal(a.createdDate).unix() < dateLocal(b.createdDate).unix()
        ? -1
        : 1;
    });

    // filter
    return list.filter((subscription) => {
      const matchStatus = filter.status
        ? filter.status.indexOf(subscription.status) !== -1
        : true;

      const matchDuration = filter.duration
        ? filter.duration.indexOf(
            subscription.renewalInterval as SubscriptionInterval
          ) !== -1
        : true;

      let itemsMatch = true;

      const filterItems = filter.items;
      if (filterItems) {
        const ignoreItems: string[] = [];
        const { purchaseItems } = subscription;

        if (filter.ignoreItems) {
          ignoreItems.push(...filter.ignoreItems);
        }

        if (filter.looseCheck) {
          const itemsIncludedInSubscription = purchaseItems
            .filter((item) => filterItems.includes(item.sku))
            .map((item) => {
              return item.sku;
            });

          itemsMatch =
            itemsIncludedInSubscription.length === filterItems.length;
        } else {
          const purchaseItemsFiltered = purchaseItems.filter(
            (i) => !ignoreItems.includes(i.sku as unknown as string)
          );

          const itemsMatchFilter = purchaseItemsFiltered.map((purchaseItem) => {
            return (
              filterItems.indexOf(purchaseItem.sku as unknown as string) !== -1
            );
          });

          itemsMatch = !itemsMatchFilter.some((itemMatch) => !itemMatch);
        }
      }

      let matchMetadata = true;

      // filter for metadataPartnerFunnel
      const filterMetadataPartnerFunnel = filter.metadataPartnerFunnel;
      if (filterMetadataPartnerFunnel) {
        const { purchaseItems } = subscription;

        matchMetadata = purchaseItems.some((purchaseItem) =>
          filterMetadataPartnerFunnel.includes(
            asTemplateValue(purchaseItem.metadata["partner.funnel"]) as string
          )
        );
      }

      // filter for metadata payerId
      const filterMetadataPayerId = filter.metadataPayerId;
      if (filterMetadataPayerId) {
        const { purchaseItems } = subscription;

        matchMetadata = purchaseItems.some(
          (purchaseItem) =>
            filterMetadataPayerId ===
            (asTemplateValue(purchaseItem.metadata["partner.payer"]) as
              | PayerId
              | string)
        );
      }

      return matchStatus && matchDuration && itemsMatch && matchMetadata;
    });
  };

  get hasActiveSubscription(): boolean | undefined {
    if (this.state.allSubscriptions === undefined) return undefined;

    const allowedStates = [
      SubscriptionDetailsResponse.status.ACTIVE,
      SubscriptionDetailsResponse.status.PAUSED,
      SubscriptionDetailsResponse.status.IN_REVIEW
    ];
    return (
      this.filterAllSubscriptions({
        status: allowedStates
      }).length > 0
    );
  }

  get hasEverHadSubscription(): boolean | undefined {
    if (this.state.allSubscriptions === undefined) return undefined;

    const allowedStates = [
      SubscriptionDetailsResponse.status.ACTIVE,
      SubscriptionDetailsResponse.status.PAUSED,
      SubscriptionDetailsResponse.status.IN_REVIEW,
      SubscriptionDetailsResponse.status.REJECTED,
      SubscriptionDetailsResponse.status.FINISHED
    ];
    return (
      this.filterAllSubscriptions({
        status: allowedStates
      }).length > 0
    );
  }

  get hasInactiveSubscription(): boolean | undefined {
    if (this.state.allSubscriptions === undefined) return undefined;

    const allowedStates = [
      SubscriptionDetailsResponse.status.PAUSED,
      SubscriptionDetailsResponse.status.REJECTED,
      SubscriptionDetailsResponse.status.FINISHED
    ];
    return (
      this.filterAllSubscriptions({
        status: allowedStates
      }).length > 0
    );
  }

  readonly buySubscription = async (
    subscriptionId: SubscriptionId,
    options: {
      onError?: (e?: Error | unknown) => void;
      onSuccess?: () => void;
    } = {}
  ): Promise<boolean> => {
    if (!subscriptionId) return false;
    let success = false;
    apiMiddleware.clearAll();
    const subscription = this.getSubscriptionById(subscriptionId);
    const id = subscription?.id;
    if (!id) {
      return false;
    }

    try {
      loadingState.start(LoadingKey.treatmentPlan);

      await SubscriptionControllerService.buySubscription(id);

      if (this.isDemoFunnel) {
        await userState.populateUserprofileWithDemoData();
      }

      await this.getSubscriptionDetails(id);
      options.onSuccess?.();
      success = true;
    } catch (e: unknown) {
      reportErrorSentry(e);
      options.onError?.(e);
      success = false;
    }

    loadingState.finish(LoadingKey.treatmentPlan);
    apiMiddleware.clearAll();
    return success;
  };

  readonly getSubscriptionDetails = async (
    subscriptionId: string
  ): Promise<void> => {
    loadingState.start(LoadingKey.treatmentPlan);
    try {
      const details =
        await SubscriptionControllerService.subscriptionDetails(subscriptionId);
      this.insertSubscription(details.data);
    } catch (e: unknown) {
      reportErrorSentry(e);
    }
    loadingState.finish(LoadingKey.treatmentPlan);
  };

  readonly getSubscriptionPurchaseItems = (
    subscriptionId: SubscriptionId,
    itemType: ItemType | ItemType[]
  ): PurchaseItemResponse[] => {
    if (!subscriptionId) return [];
    if (!Array.isArray(itemType)) {
      itemType = [itemType];
    }
    const subscription = this.getSubscriptionById(subscriptionId);
    return (subscription?.purchaseItems ?? [])
      .filter((item) =>
        itemType.includes(item.externalItemType as unknown as ItemType)
      )
      .sort((a, b) => {
        // sort alphabetically by sku
        return b.sku < a.sku ? -1 : 1;
      });
  };

  readonly insertSubscription = (
    subscription: SubscriptionDetailsResponse
  ): void => {
    const currentList = this.state.allSubscriptions ?? [];

    let replacedCurrent = false;
    const allSubscriptions: SubscriptionDetailsResponse[] = [];

    for (const sub of currentList) {
      if (sub.id === subscription.id) {
        replacedCurrent = true;
        allSubscriptions.push(subscription);
      } else {
        allSubscriptions.push(sub);
      }
    }

    if (!replacedCurrent) {
      allSubscriptions.push(subscription);
    }

    this.emitAndCache({
      ...this.state,
      allSubscriptions
    });
  };

  readonly getParsedMeds = (
    subscriptionId: SubscriptionId
  ): ParsedPurchaseItemResponse[] => {
    if (!subscriptionId) return [];
    return [
      ...this.getSubscriptionPurchaseItems(
        subscriptionId,
        ItemType.MEDICATION_SUGGESTION
      ),
      ...this.getSubscriptionPurchaseItems(
        subscriptionId,
        ItemType.PRESCRIBED_MEDICATION
      ),
      ...this.getSubscriptionPurchaseItems(
        subscriptionId,
        ItemType.PRELIMINARY_MEDICATION_SUGGESTION
      ),
      ...this.getSubscriptionPurchaseItems(
        subscriptionId,
        ItemType.prescription
      )
    ].map(parsePurchaseItem);
  };

  readonly getParsedSupplies = (
    subscriptionId: SubscriptionId
  ): ParsedPurchaseItemResponse[] => {
    if (!subscriptionId) return [];
    return [
      ...this.getSubscriptionPurchaseItems(
        subscriptionId,
        ItemType.PRESCRIBED_SUPPLY
      )
    ].map(parsePurchaseItem);
  };

  readonly checkHasLabs = (
    subscriptionId: SubscriptionId
  ): PurchaseItemResponse | undefined => {
    if (!subscriptionId) return;
    return this.getSubscriptionPurchaseItems(
      subscriptionId,
      ItemType.LAB_TEST_SUGGESTION
    )[0] as PurchaseItemResponse | undefined;
  };

  readonly checkHasTestStrips = (): PurchaseItemResponse | undefined => {
    const allowedStates = [
      SubscriptionDetailsResponse.status.ACTIVE,
      SubscriptionDetailsResponse.status.IN_REVIEW
    ];

    const subscription = this.filterAllSubscriptions({
      status: allowedStates,
      items: [TEST_STRIPS_SKU],
      looseCheck: true
    })[0] as SubscriptionDetailsResponse | undefined;

    return this.getSubscriptionPurchaseItems(
      subscription?.id,
      ItemType.OVER_THE_COUNTER_STRIP_ITEM
    )[0] as PurchaseItemResponse | undefined;
  };

  readonly checkHasPlan = (
    subscriptionId: SubscriptionId
  ): PurchaseItemResponse | undefined => {
    if (!subscriptionId) return;
    return this.getSubscriptionPurchaseItems(
      subscriptionId,
      ItemType.SUBSCRIPTION_PLAN_SUGGESTION
    )[0] as PurchaseItemResponse | undefined;
  };

  readonly checkHasPlaceholderMedications = (
    subscriptionId: SubscriptionId
  ): boolean => {
    if (!subscriptionId) return false;

    return (
      this.getSubscriptionPurchaseItems(
        subscriptionId,
        ItemType.PRELIMINARY_MEDICATION_SUGGESTION
      ).length > 0
    );
  };

  get coveredByInsurance(): boolean | undefined {
    if (this.state.allSubscriptions === undefined) return undefined;

    const coveredItemsSkus = [
      PRESCRYPTIVE_PROGRAM_ITEM_SKU,
      MEDICARE_PROGRAM_SKU
    ];

    const filtered = coveredItemsSkus.filter(
      (sku) =>
        this.filterAllSubscriptions({
          status: [
            SubscriptionDetailsResponse.status.ACTIVE,
            SubscriptionDetailsResponse.status.IN_REVIEW
          ],
          items: [sku],
          looseCheck: true
        }).length > 0
    );

    return filtered.length > 0;
  }

  private getEligibilityForCurrentPartnerPromise: Promise<GetSuggestedTreatmentPlanResponse | null> | null =
    null;

  readonly getEligibilityForCurrentPartner =
    async (): Promise<GetSuggestedTreatmentPlanResponse | null> => {
      let promise = this.getEligibilityForCurrentPartnerPromise;

      if (!promise) {
        promise = new Promise<GetSuggestedTreatmentPlanResponse | null>(
          (resolve, reject) => {
            // eslint-disable-next-line @typescript-eslint/no-deprecated
            void TreatmentPlanControllerService.suggestTreatmentPlan()
              .then((response) => {
                resolve(response.data);
              })
              .catch((e) => {
                reject(e as Error);
              })
              .finally(() => {
                this.getEligibilityForCurrentPartnerPromise = null;
              });
          }
        );
        this.getEligibilityForCurrentPartnerPromise = promise;
      }

      try {
        return await promise;
      } catch (e) {
        reportErrorSentry(e);
        return null;
      }
    };

  extractPayerInfo = (
    filter: {
      status?: SubscriptionDetailsResponse.status[];
    } = {}
  ): {
    lastSignupFunnel?: FunnelKey;
    payerIsCashPay?: boolean;
  } => {
    const activeSub = this.filterAllSubscriptions({
      status: filter.status ?? [SubscriptionDetailsResponse.status.ACTIVE],
      looseCheck: true
    });
    const draftSub = this.filterAllSubscriptions({
      status: filter.status ?? [SubscriptionDetailsResponse.status.DRAFT],
      looseCheck: true
    });

    const useSub = [...activeSub, ...draftSub];

    let lastSignupFunnel: FunnelKey | undefined = undefined;
    let payerIsCashPay: boolean | undefined = undefined;
    useSub.forEach((sub) => {
      for (const item of sub.purchaseItems) {
        if (item.metadata["partner.payer"]) {
          payerIsCashPay =
            asTemplateValue(item.metadata["partner.payer"]) ===
            PayerId.CASH_PAY;
        }

        if (item.metadata["partner.funnel"]) {
          lastSignupFunnel = asTemplateValue(
            item.metadata["partner.funnel"]
          ) as FunnelKey;
        } else if (!lastSignupFunnel && payerIsCashPay) {
          const isActiveSub =
            sub.status === SubscriptionDetailsResponse.status.ACTIVE;
          if (!isActiveSub) {
            lastSignupFunnel = FunnelKey.universal;
          }
        }
      }
    });

    return {
      lastSignupFunnel,
      payerIsCashPay
    };
  };

  getUnfinishedStepPath: () => Promise<string | undefined> = async () => {
    const payerInfo = this.extractPayerInfo();

    if (!payerInfo.lastSignupFunnel || this.hasInactiveSubscription) {
      return;
    }

    const signupCubit = new SignupCustomBloc({
      parameters: {
        campaign: payerInfo.lastSignupFunnel
      },
      options: { disableInit: true }
    });

    const step = await signupCubit.findFirstIncompleteStep(true);
    const foundCheckoutStep = step?.path === "checkout";

    if ((foundCheckoutStep && this.hasActiveSubscription) || !step) {
      return;
    }

    return `/signup/${payerInfo.lastSignupFunnel}/${step.path}`;
  };

  get activeSubscriptionPayerId(): PayerId | string | undefined {
    const activeSub = this.getFullSubscription([
      SubscriptionDetailsResponse.status.ACTIVE
    ]);

    if (!activeSub) return undefined;

    const partnerPayerId = activeSub.purchaseItems.find((item) =>
      Boolean(item.metadata["partner.payer"])
    )?.metadata["partner.payer"] as PayerId | string | undefined;

    return partnerPayerId;
  }

  get activeSubscriptionSupportsHybridApp(): boolean {
    const partnerPayerId = this.activeSubscriptionPayerId;

    // yes if no payerId is found
    if (!partnerPayerId) return true;

    const payerIdsNotSupported = [PayerId.TRANSCARENT] as const;

    // yes if payerId is not in the list of unsupported payerIds, no otherwise
    return !payerIdsNotSupported.includes(partnerPayerId);
  }

  get activeSubscriptionHasBranding(): PayerId | false {
    const partnerPayerId = this.activeSubscriptionPayerId as
      | PayerId
      | undefined;
    if (!partnerPayerId) return false;
    const payerIdsWithBranding = [""] as const;
    const hasBranding = payerIdsWithBranding.includes(partnerPayerId);
    return hasBranding ? partnerPayerId : false;
  }

  get activeSubscriptionDetails(): {
    partnerFunnel?: FunnelKey;
    cashPay?: boolean;
  } {
    const activeSub = this.getFullSubscription([
      SubscriptionDetailsResponse.status.ACTIVE
    ]);

    if (!activeSub) return {};

    const partnerFunnel = activeSub.purchaseItems.find((item) =>
      Boolean(item.metadata["partner.funnel"])
    )?.metadata["partner.funnel"] as FunnelKey | undefined;

    const cashPay =
      asTemplateValue(
        activeSub.purchaseItems.find((item) =>
          Boolean(item.metadata["partner.payer"])
        )?.metadata["partner.payer"]
      ) === PayerId.CASH_PAY;

    return {
      partnerFunnel,
      cashPay
    };
  }
}
