import type { AnsweredQuestionnaire } from "@9amhealth/openapi";
import styled from "@emotion/styled";
import type { FC } from "react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { APP_BREAKPOINT } from "src/constants/layout";
import {
  CSAT_FEEDBACK_QUESTIONNAIRE_ID,
  METADATA_CONTENT_VALUE,
  METADATA_CSAT_FEEDBACK_REPLY,
  METADATA_CSAT_FEEDBACK_SOURCE_ID,
  METADATA_MESSAGE_TYPE,
  METADATA_ORIGINAL_SOURCE,
  METADATA_ORIGINAL_SOURCE_9AM_FRONTEND
} from "src/constants/misc";
import translate from "src/lib/translate";
import { TrackEvent } from "src/state/Track/TrackCubit";
import { chatState, tracker, useBloc } from "src/state/state";
import IconEmojiGlasshour from "src/ui/assets/icons/emoji-glasshour.webp";
import IconEmojiThumbsDown from "src/ui/assets/icons/emoji-thumbs-down.webp";
import IconEmojiThumbsUp from "src/ui/assets/icons/emoji-thumbs-up.webp";
import {
  AppPopup,
  AppQueryPopupsController
} from "src/ui/components/AppQueryPopups/AppQueryPopupsBloc";
import { ChatBloc, ChatMessageMetaType } from "src/ui/components/Chat/ChatBloc";
import ChatMessageContext from "src/ui/components/Chat/ChatMessageContext";
import Translate from "src/ui/components/Translate/Translate";

//#region Styled components
const Button = styled.button`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  width: 4.625rem;
  aspect-ratio: 1;
  border-radius: 0.5rem;
  margin-right: 0.625rem;
  background-color: var(--color-gray-fog);

  img {
    width: 2rem;
    aspect-ratio: 1;
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover,
  &:active {
    &::after {
      content: "";
      position: absolute;
      inset: 0;
      background: rgba(0, 0, 0, 0.06);
      mix-blend-mode: color-burn;
    }

    &:active {
      &::after {
        background: rgba(0, 0, 0, 0.12);
      }
    }
  }

  @media screen and (min-width: 550px) {
    margin-right: 0.5rem;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (min-width: 550px) {
    justify-content: flex-start;
  }
`;

const Text = styled.small`
  font-weight: 500;
  line-height: 1;
`;

const ReplyBanner = styled.button`
  background-color: var(--background-color);
  border-radius: 0.5rem;
  padding: 0.75rem;
  text-align: center;
  position: relative;
  width: 100%;
  overflow: hidden;

  &:not([disabled]) {
    &:hover,
    &:active {
      &::after {
        content: "";
        position: absolute;
        inset: 0;
        background: rgba(0, 0, 0, 0.06);
        mix-blend-mode: color-burn;
      }

      &:active {
        &::after {
          background: rgba(0, 0, 0, 0.12);
        }
      }
    }
  }

  &[data-reply="yes"] {
    --background-color: var(--color-afternoon-green);
  }

  &[data-reply="no"] {
    --background-color: var(--color-afternoon-yellow);
  }

  &[data-reply="notNow"] {
    --background-color: var(--color-cream-darker);
  }

  p,
  a {
    font-weight: 500;
    font-size: 0.875rem;
  }

  @media screen and (min-width: ${APP_BREAKPOINT}px) {
    padding: 1rem;

    p,
    a {
      font-size: 1rem;
    }
  }
`;
//#endregion

export type Reply = "yes" | "no" | "notNow";

export interface Answer {
  questionId: string;
  fieldValue: number | string;
  fieldType: "number" | "text";
}

export const icons: Record<Reply, { imageSrc: string; imageAlt: string }> = {
  yes: {
    imageSrc: IconEmojiThumbsUp,
    imageAlt: translate("icon.alt.thumbsUp")
  },
  no: {
    imageSrc: IconEmojiThumbsDown,
    imageAlt: translate("icon.alt.thumbsDown")
  },
  notNow: {
    imageSrc: IconEmojiGlasshour,
    imageAlt: translate("icon.alt.glasshour")
  }
};

const CSATFeedbackButton = ({
  reply,
  handleClick
}: {
  reply: Reply;
  handleClick: (reply: Reply) => void;
}) => {
  const text = translate("csatFeedback_reply", { context: reply });
  const { imageSrc, imageAlt } = icons[reply];

  return (
    <Button onClick={() => handleClick(reply)}>
      <img src={imageSrc} alt={imageAlt} />
      <Text>{text}</Text>
    </Button>
  );
};

const CSATFeedback: FC<{ sourceId?: string }> = () => {
  const [replyState, setReplyState] = useState<Reply>();
  const userReply = useRef<Reply | undefined>(undefined);
  const messageContext = useContext(ChatMessageContext);
  const [, { getMessagesThatContainMetadataAttribute }] = useBloc(ChatBloc);

  useEffect(() => {
    return () => {
      document.removeEventListener("nineQuestionnaireSaved", eventListener);
    };
  }, []);

  const messages = getMessagesThatContainMetadataAttribute(
    METADATA_CSAT_FEEDBACK_SOURCE_ID,
    messageContext.id,
    true
  );

  useEffect(() => {
    const messagesLength = messages.length;

    if (messagesLength > 0) {
      const lastMessage = messages[messagesLength - 1];
      const lastMessageReply = lastMessage.apiItem?.metadata?.[
        METADATA_CSAT_FEEDBACK_REPLY
      ] as Reply | undefined;

      if (lastMessageReply) {
        setReplyState(lastMessageReply);
      }
    }
  }, [messages]);

  const eventListener = (event: Event) => {
    if (!userReply.current) {
      return;
    }

    const customEvent = event as CustomEvent<AnsweredQuestionnaire>;

    if (
      customEvent.detail.questionnaireRef.id === CSAT_FEEDBACK_QUESTIONNAIRE_ID
    ) {
      sendEmptyMessage();
    }

    return;
  };

  const openCSATQuestionnaire = () => {
    AppQueryPopupsController.openPopup(AppPopup.questionnaire, {
      additionalParameters: {
        questionnaireId: CSAT_FEEDBACK_QUESTIONNAIRE_ID,
        title: translate("yourFeedback"),
        fullscreen: "true",
        "dialog-type": "feedback"
      }
    });

    document.addEventListener("nineQuestionnaireSaved", eventListener, {
      once: true
    });
  };

  const sendEmptyMessage = () => {
    if (userReply.current && messageContext.id) {
      const metadata = {
        [METADATA_CONTENT_VALUE]: true,
        [METADATA_MESSAGE_TYPE]: ChatMessageMetaType.csatFeedback,
        [METADATA_ORIGINAL_SOURCE]: METADATA_ORIGINAL_SOURCE_9AM_FRONTEND,
        [METADATA_CSAT_FEEDBACK_REPLY]: userReply.current,
        [METADATA_CSAT_FEEDBACK_SOURCE_ID]: messageContext.id
      };

      void chatState.sendMessageWithFallback({
        content: "",
        meta: metadata
      });
    }
  };

  const handleReply = (reply: Reply) => {
    userReply.current = reply;

    if (reply === "yes") {
      openCSATQuestionnaire();
    } else {
      setReplyState(reply);
      sendEmptyMessage();
    }

    tracker.track(TrackEvent.CSATExperienceOptionClicked, {
      data: {
        Option: reply
      }
    });
  };

  const handleReplyAgain = () => {
    userReply.current = "yes";
    openCSATQuestionnaire();

    tracker.track(TrackEvent.CSATExperienceClickHereButtonClicked);
  };

  return (
    <>
      {replyState && (
        <ReplyBanner
          data-reply={replyState}
          disabled={replyState === "yes"}
          onClick={handleReplyAgain}
        >
          <p>
            <Translate
              msg="csatFeedback_youReplied"
              variables={{ context: replyState }}
            />
            {replyState !== "yes" && (
              <>
                <br />
                <Translate msg="csatFeedback_clickHere" />
              </>
            )}
          </p>
        </ReplyBanner>
      )}

      {!replyState && (
        <Wrapper data-id={messageContext.id}>
          <CSATFeedbackButton reply="yes" handleClick={handleReply} />
          <CSATFeedbackButton reply="no" handleClick={handleReply} />
          <CSATFeedbackButton reply="notNow" handleClick={handleReply} />
        </Wrapper>
      )}
    </>
  );
};

export default CSATFeedback;
