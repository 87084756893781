/* istanbul ignore file */
import { BlacReact, BlocObserver } from "blac";
import AppVersionBloc from "src/hybrid/AppVersionBloc";
import { BiometricVerificationBloc } from "src/hybrid/components/BiometricVerification";
import KeyboardBloc from "src/hybrid/components/KeyboardControls";
import PushNotificationsBloc from "src/hybrid/components/PushNotificationsControls";
import StatusBarBloc from "src/hybrid/components/StatusBarBloc";
import { featureFlags } from "src/lib/featureFlags";
import setupOpenApi from "src/lib/setupOpenApi";
import ApiMiddleware from "src/state/ApiMiddleware";
import AppViewCubit from "src/state/AppViewCubit/AppViewCubit";
import DevicesCubit from "src/state/DevicesCubit/DevicesCubit";
import FilesCubit from "src/state/FilesCubit/FilesCubit";
import HistoryBloc from "src/state/HistoryBloc/HistoryBloc";
import LoadingCubit from "src/state/LoadingCubit/LoadingCubit";
import ObservationBundleBloc from "src/state/ObservationBundleBloc/ObservationBundleBloc";
import OverlayCubit from "src/state/OverlayCubit/OverlayCubit";
import PatientPCPBloc from "src/state/PatientPCPBloc/PatientPCPBloc";
import PatientPharmacyBloc from "src/state/PatientPharmacyBloc/PatientPharmacyBloc";
import ProgramBloc from "src/state/ProgramBloc/ProgramBloc";
import SubscriptionCubit from "src/state/SubscriptionCubit/SubscriptionCubit";
import TaskManagementBloc from "src/state/TaskManagementBloc/TaskManagementBloc";
import ToastBloc from "src/state/Toast/ToastBloc";
import TrackCubit, { setupMixpanel } from "src/state/Track/TrackCubit";
import UserPreferencesCubit from "src/state/UserPreferencesCubit/UserPreferencesCubit";
import VideoPlayerCubit from "src/state/VideoPlayerCubit/VideoPlayerCubit";
import WebSocketBloc from "src/state/WebSocketBloc/WebSocketBloc";
import { ChatBloc } from "src/ui/components/Chat/ChatBloc";
import HealthSyncBloc from "./HealthSyncBloc/HealthSyncBloc";
import RxDietCubit from "./RxDietCubit/RxDietCubit";
import AuthenticationBloc from "./UserCubit/AuthenticationBloc";
import UserCubit from "./UserCubit/UserCubit";
import { getApiController } from "src/api/ApiController";

setupOpenApi();
setupMixpanel();

export const apiMiddleware = new ApiMiddleware();

// Hybrid App components
export const appVersionState = new AppVersionBloc();
export const pushNotificationState = new PushNotificationsBloc();
export const keyboardState = new KeyboardBloc();
export const biometricAuthState = new BiometricVerificationBloc();

export const authenticationState = new AuthenticationBloc();
getApiController({
  authenticationState,
  appVersionState
});
export const loadingState: LoadingCubit = new LoadingCubit();
export const websocketState = new WebSocketBloc();
export const userState = new UserCubit();
export const subscriptionState = new SubscriptionCubit();
export const userPreferences = new UserPreferencesCubit();
export const fileState = new FilesCubit();
export const tracker = new TrackCubit();
export const overlayState = new OverlayCubit();
export const appViewState = new AppViewCubit();
export const taskManagementState = new TaskManagementBloc();
export const programState = new ProgramBloc();
export const historyState = new HistoryBloc();
export const appStatusBarState = new StatusBarBloc();
export const patientPharmacyState = new PatientPharmacyBloc();
export const patientPCPState = new PatientPCPBloc();
export const devicesState = new DevicesCubit();
export const observationBundleState = new ObservationBundleBloc();
export const toast = new ToastBloc();
export const chatState = new ChatBloc();
export const rxDietState = new RxDietCubit();
export const healthSyncState = new HealthSyncBloc();

const debugObserver = new BlocObserver({
  onChange: (bloc, event) => {
    const debugModeActive =
      sessionStorage.getItem("debugModeActive") === "true";

    if (debugModeActive) {
      const blocName = bloc.constructor.name;
      // eslint-disable-next-line no-console
      console.log(`[BLAC].change: ${blocName}`, { bloc, event });
    }
  }
});

const state = new BlacReact(
  [
    authenticationState,
    healthSyncState,
    loadingState,
    historyState,
    userState,
    fileState,
    subscriptionState,
    userPreferences,
    overlayState,
    pushNotificationState,
    keyboardState,
    biometricAuthState,
    appViewState,
    taskManagementState,
    programState,
    appStatusBarState,
    patientPharmacyState,
    patientPCPState,
    new VideoPlayerCubit(),
    websocketState,
    featureFlags,
    devicesState,
    observationBundleState,
    toast,
    appVersionState,
    chatState,
    rxDietState
  ],
  {
    observer: debugObserver
  }
);

// eslint-disable-next-line @typescript-eslint/unbound-method
export const { useBloc, BlocProvider } = state;
export default state;
