import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import type { FC, ReactNode } from "react";
import React from "react";

const underline = keyframes`
  0% {
    width: 0;
  }
  100% {
    width: calc(100% + 0.2em);
  }
`;

const Underlined = styled.span`
  position: relative;
  z-index: 1;
  white-space: nowrap;

  &::after {
    content: "";
    position: absolute;
    left: -0.1em;
    width: calc(100% + 0.2em);
    bottom: 0;
    z-index: -1;
    height: 0.35em;
    background-image: var(--gradient-sunrise-light);
    animation: ${underline} 1.2s ease-in-out;
  }
`;

const Underline: FC<{ children?: ReactNode | undefined }> = ({ children }) => {
  return <Underlined>{children}</Underlined>;
};

export default Underline;
