export const SKU_SEPARATOR = "#";
export const MEDICAL_ASSESSMENT_ID = "xhuRrhH9";
export const CDCDPP_ELIGIBILITY_QUESTIONNAIRE_ID = "h7S07G4Y";
export const CSAT_FEEDBACK_QUESTIONNAIRE_ID = "fnZKqImJ";
export const FEEDBACK_GOOD_QUESTIONNAIRE_ID = "RbfVQcyi";
export const FEEDBACK_BAD_QUESTIONNAIRE_ID = "fpmAyZSJ";
export const RISK_QUIZ_QUESTIONNAIRE_ID = "UdChB1PZ";

export const FUNNEL_QUESTIONNAIRE_ELIGIBILITY = "J3TCSVXD";
export const FUNNEL_QUESTIONNAIRE_MEDICAL = "wRvs6BNP";
export const ONBOARDING_QUESTIONNAIRE_INITIAL_LAB = "OgCAbwRC";

export const METADATA_CONTENT_VALUE = "content-value:generated";
export const METADATA_MESSAGE_TYPE = "message:type";
export const METADATA_ORIGINAL_SOURCE = "message:original-source";

export const METADATA_ORIGINAL_SOURCE_9AM_FRONTEND = "9am-frontend";

export const FEEDBACK_GOOD_QUESTIONNAIRE_RATING_QUESTION_ID = "IO6j6rqY1RZe";
export const FEEDBACK_GOOD_QUESTIONNAIRE_RATING_TEXT_QUESTION_ID =
  "jbcJT8E3mSDu";
export const FEEDBACK_BAD_QUESTIONNAIRE_RATING_TEXT_QUESTION_ID =
  "kOo6PZPaJrP4";

export const BEHAVIORAL_HEALTH_QUESTIONNAIRE_PHQ8 = "vPliohYa";
export const BEHAVIORAL_HEALTH_QUESTIONNAIRE_GAD7 = "JvW9N8Bm";
export const BEHAVIORAL_HEALTH_QUESTIONNAIRE_HOW_DIFFICULT_QUESTION_ID =
  "i3YTldsNrt4u";
export const BEHAVIORAL_HEALTH_QUESTIONNAIRE_HOW_DIFFICULT_ANSWER_NOT_DIFFICULT =
  "b3taslRS528V";
export const BEHAVIORAL_HEALTH_QUESTIONNAIRE_HOW_DIFFICULT_ANSWER_SOMEWHAT =
  "eP6mBmOE3wF7";
export const BEHAVIORAL_HEALTH_QUESTIONNAIRE_HOW_DIFFICULT_ANSWER_VERY =
  "b54U0yAocbS6";
export const BEHAVIORAL_HEALTH_QUESTIONNAIRE_HOW_DIFFICULT_ANSWER_EXTREMELY =
  "mdQxCvPcVPoF";

export const PHLEBOTOMY_SKU = "AT_HOME_PHLEBOTOMY"; // At home lab
export const BLOOD_SPOT_SKU = "DRY_BLOOD_SPOT_LAB_TEST"; // Regular lab

export const MEDICARE_PROGRAM_SKU = "care:medicare";
export const WEIGHT_LOSS_PROGRAM_SKU = "care:weightloss";
export const PRESCRYPTIVE_PROGRAM_ITEM_SKU = "care:pbm:prescryptive";
export const CDCDPP_PROGRAM_ITEM_SKU = "care:cdcdpp";

export const BASE_MEMBERSHIP_SKU = "base_membership";
export const ONBOARDING_FEE_SKU = "care:onboarding";

export const AVERAGE_DELIVERY_LENGTH_IN_DAYS = 4;

export const METADATA_NEW_MEDS_KEY = "medication.new-med-for-patient";
export const METADATA_DAILY_DOSAGE_TIMES = "medication.daily-dosage-times";
export const METADATA_RAMPING_UP_DOSAGE = "medication.ramping-up-dosage";

export const METADATA_NPS_FEEDBACK_RATING = "nps-feedback:rating";
export const METADATA_NPS_FEEDBACK_RATING_NUMBER = "nps-feedback:ratingNumber";
export const METADATA_NPS_FEEDBACK_RATING_TEXT = "nps-feedback:ratingText";
export const METADATA_NPS_FEEDBACK_SOURCE_ID = "nps-feedback:sourceId";

export const METADATA_CSAT_FEEDBACK_REPLY = "csat-feedback:reply";
export const METADATA_CSAT_FEEDBACK_SOURCE_ID = "csat-feedback:sourceId";

export const ANDROID_APP_DOWNLOAD_LINK =
  "https://play.google.com/store/apps/details?id=com.join9am.app.android";
export const IOS_APP_DOWNLOAD_LINK =
  "https://apps.apple.com/us/app/9am-at-home-diabetes-clinic/id6443864010";
