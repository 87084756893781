import { logSentryBreadcrumb } from "src/lib/addSentryBreadcrumb";
import { FeatureFlagName, featureFlags } from "src/lib/featureFlags";
import reportErrorSentry from "src/lib/reportErrorSentry";
import {
  ApiController,
  ApiControllerConfig
} from "lib/ApiController/ApiController";
import type AuthenticationBloc from "src/state/UserCubit/AuthenticationBloc";
import type AppVersionBloc from "src/hybrid/AppVersionBloc";

const REFRESH_TOKEN_ENDPOINT = "/v1/users/token/refresh";

/*
 * Lazy ApiController instance
 */
let apiControllerInstance: ApiController | null = null;

/*
 * Get the ApiController instance
 */
export function getApiController(options?: {
  authenticationState: AuthenticationBloc;
  appVersionState: AppVersionBloc;
}): ApiController {
  if (!apiControllerInstance) {
    if (!options) {
      const error = new Error("getApiController: options are required");
      reportErrorSentry(error);
      throw error;
    }
    const config: ApiControllerConfig = {
      refreshTokenEndpoint: REFRESH_TOKEN_ENDPOINT,
      ignoreTraceForSuccessResponse: [/track$/, /features$/, /status$/],
      requestsWithoutAuth: [
        "/v1/users/anonymous",
        "/v1/users/me/password/reset",
        "/v1/users/me/password/reset/confirm",
        "/v1/users/email/verification/initiate",
        "/v1/users/email/verification/confirm",
        "/v1/users/login",
        "/v1/auth/saml2/exchange",
        "/v1/integrations/transcarent/exchange"
      ],
      requestsWithExpected401: [
        "/v1/users/me/mfa",
        "/v1/users/me/password/reset/confirm"
      ],
      priorityRequestsWithAuth: [REFRESH_TOKEN_ENDPOINT],
      loggingEnabled: featureFlags.getFlag(FeatureFlagName.loggingApiCache),
      clientId: `web|${options.appVersionState.currentVersionString}`,
      logBreadcrumb: logSentryBreadcrumb,
      reportError: reportErrorSentry,
      authState: options.authenticationState
    };

    apiControllerInstance = new ApiController(config);
    apiControllerInstance.patchGlobalFetch();
  }
  return apiControllerInstance;
}

export function processRequestQueue() {
  return getApiController().processRequestQueue();
}

export function logAverageTimes() {
  return getApiController().logAverageTimes();
}
