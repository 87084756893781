import type {
  PaymentMethod,
  PhoneNumber,
  UserAddressApiDto
} from "@9amhealth/openapi";
import {
  AddressesControllerService,
  PaymentControllerService,
  SmsControllerService
} from "@9amhealth/openapi";
import { Cubit } from "blac";
import Path from "src/constants/path";
import { OpenBrowser } from "src/hybrid/components/Browser";
import { extractErrorCode } from "src/lib/errors";
import formatPhoneNumber from "src/lib/formatPhoneNumber";
import reportErrorSentry from "src/lib/reportErrorSentry";
import type { FileItem } from "src/state/FilesCubit/FilesCubit";
import { LoadingKey } from "src/state/LoadingCubit/LoadingCubit";
import { loadingState, userState } from "src/state/state";
import { ErrorCode } from "./../../constants/errorCodes";
import { globalEvents } from "src/constants/globalEvents";
import asTemplateValue from "lib/asTemplateValue";

interface ProfileData {
  id: string;
  email?: string;
  addressData?: UserAddressApiDto;
  paymentMethods?: PaymentMethod[];
  editBillingUrl?: string;
  avatar?: FileItem;
  number?: PhoneNumber;
}

interface UserPlanState {
  data: ProfileData;
  addressErrorCode?: string;
}

export default class ProfileCubit extends Cubit<UserPlanState> {
  constructor() {
    super({
      data: {
        id: ""
      }
    });

    window.addEventListener(globalEvents.USER_CLEAR, () => {
      this.emit({
        data: {
          id: ""
        }
      });
    });
  }

  public readonly openEditBilling = async (): Promise<void> => {
    loadingState.start(LoadingKey.paymentRedirect);
    try {
      const { data } = await PaymentControllerService.getUserPortalUrl(
        `https://app.join9am.com/${Path.actionDone}`
      );

      await OpenBrowser(data.portalUrl, {
        useBaseUrl: false,
        presentationStyle: "popover"
      });
    } catch (e: unknown) {
      reportErrorSentry(e);
    }

    loadingState.finish(LoadingKey.paymentRedirect);
  };

  public readonly clearAllData = (): void => {
    this.emit({
      data: {
        id: ""
      }
    });
  };

  public readonly loadData = async (): Promise<void> => {
    if (userState.isTempUser) return;

    loadingState.start(LoadingKey.profileData);
    await Promise.all([
      this.loadProfile(),
      this.loadNumber(),
      this.loadAddress(),
      this.loadPaymentMethods()
    ]);
    loadingState.finish(LoadingKey.profileData);
  };

  readonly loadPaymentMethods = async (): Promise<void> => {
    loadingState.start(LoadingKey.payment);
    try {
      const paymentMethods = await PaymentControllerService.getPaymentMethods();
      this.emit({
        ...this.state,
        data: {
          ...this.state.data,
          paymentMethods: paymentMethods.data.paymentMethods
        }
      });
    } catch (e: unknown) {
      reportErrorSentry(e);
    }
    loadingState.finish(LoadingKey.payment);
  };

  readonly loadAddress = async (): Promise<void> => {
    if (userState.isTempUser) return;

    loadingState.start(LoadingKey.shippingAddress);
    try {
      const shipping =
        await AddressesControllerService.getDefaultAddress("SHIPMENT");
      const { addressData } = shipping.data;

      if (!addressData.aptSuite) {
        addressData.aptSuite = "-";
      }

      this.emit({
        ...this.state,
        data: {
          ...this.state.data,
          addressData
        },
        addressErrorCode: undefined
      });
    } catch (e: unknown) {
      const errorCode = extractErrorCode(e);
      this.emit({
        ...this.state,
        addressErrorCode: errorCode
      });

      if (errorCode !== ErrorCode.addressNotFound) {
        reportErrorSentry(e);
      }
    }
    loadingState.finish(LoadingKey.shippingAddress);
  };

  private readonly loadNumber = async (): Promise<void> => {
    if (userState.isTempUser) return;

    const number = await ProfileCubit.getPhoneNumber();
    this.emit({
      ...this.state,
      data: {
        ...this.state.data,
        number
      }
    });
  };

  readonly loadProfile = async (): Promise<void> => {
    if (userState.isTempUser) return;

    try {
      const profileData = await userState.fetchUserProfile();
      this.emit({
        ...this.state,
        data: {
          ...this.state.data,
          id: profileData.id,
          email: asTemplateValue(profileData.profile.email) as string
        }
      });
    } catch (e: unknown) {
      reportErrorSentry(e);
    }
  };

  public readonly setPhoneNumber = async (number: string): Promise<void> => {
    const formatted = formatPhoneNumber(number);
    if (!formatted) return;

    try {
      await SmsControllerService.registerPhoneNumber({
        phoneNumber: formatted,
        verify: false
      });
    } catch (e) {
      reportErrorSentry(e);
    }
  };

  static getPhoneNumber = async (): Promise<PhoneNumber | undefined> => {
    loadingState.start(LoadingKey.number);
    let number: PhoneNumber | undefined = undefined;

    try {
      const response = await SmsControllerService.listPhoneNumbers();
      const numbers = response.data;
      const selected = numbers.find((n) => n.primary);
      number = selected;
    } catch (e: unknown) {
      reportErrorSentry(e);
    }
    loadingState.finish(LoadingKey.number);

    return number;
  };
}
