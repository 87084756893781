import { TaskResponse } from "@9amhealth/openapi";
import { Blac, Cubit } from "blac-next";
import {
  AppRemoteConfigCubit,
  JourneyContentResponse
} from "src/state/AppRemoteConfigCubit/AppRemoteConfigCubit";
import { KnownProgram } from "src/state/ProgramBloc/ProgramBloc";
import { taskManagementState } from "src/state/state";
import {
  TaskObserverEvent,
  TaskResponseKnown
} from "src/state/TaskManagementBloc/TaskManagementBloc";

export interface UserJourneyLessonItem {
  type: "typeform" | "article" | "coaching";
  typeformId?: string;
  url?: string;
  task?: TaskResponseKnown;
}

export interface UserJourneyLesson {
  name: string;
  taskGroup?: string;
  items: UserJourneyLessonItem[];
}

export interface UserJourneyModule {
  name: string;
  subtitle: string;
  group: string;
  lessons: UserJourneyLesson[];
  index: number;
  totalModules: number;
}

export interface UserJourneyState {
  enableAll: boolean;
  modules?: UserJourneyModule[];
  name?: string;
  loading?: boolean;
}

interface UserJourneyProps {
  journey: string;
  taskProgram: string;
}

export default class UserJourneyBloc extends Cubit<
  UserJourneyState,
  UserJourneyProps
> {
  tasks: (TaskResponseKnown | undefined)[] = [];
  journeyContent: JourneyContentResponse | null = null;

  constructor(props: UserJourneyProps) {
    super({
      enableAll: false
    });
    this.props = props;
    void this.loadContent();
  }

  loadContent = async () => {
    if (!this.props) return;
    const { journey, taskProgram } = this.props;
    if (!journey || !taskProgram) return;

    this.patch({ loading: true });
    await Promise.all([this.loadJourney(journey), this.loadTasks()]);
    this.patch({ loading: false });
    this.prepareState();
    this.addObserverForTaskChanges();
  };

  onTasksChanged = (programs: Map<KnownProgram, TaskResponseKnown[]>): void => {
    if (!this.props?.taskProgram) return;
    const tasks = programs.get(this.props.taskProgram as KnownProgram);
    if (!tasks) return;
    this.tasks = tasks;
    this.prepareState();
  };

  addObserverForTaskChanges = (): void => {
    taskManagementState.addObserver(
      TaskObserverEvent.TASK_LIST_CHANGED,
      this.onTasksChanged
    );
  };

  loadJourney = async (journey: string) => {
    const response =
      await Blac.getBloc(AppRemoteConfigCubit).loadJourneyContent(journey);

    this.journeyContent = response.journey;

    this.patch({
      name: response.journey ? response.journey.name : ""
    });
  };

  loadTasks = async () => {
    if (!this.props?.taskProgram) return;
    const tasks = await taskManagementState.loadProgramTasks(
      this.props.taskProgram as KnownProgram
    );
    this.tasks = tasks as TaskResponseKnown[];
  };

  prepareState = () => {
    const { journeyContent, tasks } = this;
    const taskProgram = this.props?.taskProgram ?? "";

    if (!journeyContent || tasks.length === 0) return;
    const classes = journeyContent.listOfClassesContentAvailable;
    const modules: UserJourneyModule[] = classes.map((c, index) => {
      const lessons = c.listOfLessons.map((l) => ({
        name: l.name,
        taskGroup: l.taskGroup,
        items: l.items
          .map((i) => ({
            type: i.type,
            typeformId: i.typeformId,
            taskSlug: i.taskSlug,
            url: i.url,
            task: tasks.find((t) => {
              return (
                t?.program === taskProgram &&
                t.slug === i.taskSlug &&
                t.group === l.taskGroup
              );
            })
          }))
          // filter out items without tasks
          .filter((i) => Boolean(i.task))
      }));

      return {
        name: c.name,
        index,
        totalModules: classes.length,
        subtitle: c.subtitle,
        group: c.taskGroupReference,
        lessons
      };
    });

    this.patch({
      modules
    });
  };

  onTaskStatusUpdated = (
    task: TaskResponseKnown | undefined,
    status: string
  ) => {
    if (!task) return;
    this.tasks = this.tasks.map((t) => {
      if (
        t?.program === task.program &&
        t.group === task.group &&
        t.slug === task.slug
      ) {
        return {
          ...t,
          status
        } as TaskResponseKnown;
      }

      return t;
    });
    this.prepareState();
  };

  isNextAvailableTask = (task: TaskResponseKnown | undefined) => {
    const noAvailableOrInProgressTasks =
      this.tasks.find(
        (task) =>
          task?.status === TaskResponse.status.AVAILABLE ||
          task?.status === TaskResponse.status.IN_PROGRESS
      ) === undefined;
    const completedTask = this.tasks.find(
      (task) => task?.status === TaskResponse.status.COMPLETED
    );
    const firstLockedTask = this.tasks.find(
      (task) => task?.status === TaskResponse.status.LOCKED
    );
    const isLocked = task?.status === TaskResponse.status.LOCKED;

    if (
      isLocked &&
      task.slug === firstLockedTask?.slug &&
      noAvailableOrInProgressTasks &&
      completedTask
    ) {
      return true;
    }
    return false;
  };

  get questionnaireTasks(): TaskResponseKnown[] {
    return this.tasks.filter((t) =>
      t?.slug.includes("questionnaire")
    ) as TaskResponseKnown[];
  }
}
