import * as Sentry from "@sentry/react";
import React from "react";
import { createRoot } from "react-dom/client";
import envVariables from "src/lib/envVariables";
import { tracker } from "src/state/state";

import App from "src/ui/components/App/App";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";

import { TrackEvent } from "src/state/Track/TrackCubit";
import "./page.css";

import "@9amhealth/wcl";
import "@9amhealth/wcl/generated/modules";
import { defineCustomElements } from "@ionic/pwa-elements/loader";

import { SplashScreen } from "@capacitor/splash-screen";
import { sentryConfig } from "./lib/sentry";
import "./rebrand_styles.scss";
import "./partner_styles_transcarent.scss";
import "./tab_nav.scss";

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
const w = window as any;
// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
w.initTracked = true;

window.nineConfig = {
  ...window.nineConfig,
  mainScrollTarget: document.body
};

Sentry.init(sentryConfig);

tracker.track(TrackEvent.appStart, {
  data: {
    Environment: envVariables.ENV_NAME
  }
});

const container = document.getElementById("root");
if (container) {
  const root = createRoot(container);
  root.render(<App />);
}

void defineCustomElements(window);
void SplashScreen.hide();
