import { NineButton, NineHeading } from "@9amhealth/wcl/generated/react";
import { keyframes } from "@emotion/css";
import styled from "@emotion/styled";
import type { FC } from "react";
import React from "react";
import { appViewState, useBloc } from "src/state/state";
import BlockingLoadingOverlayController, {
  BlockingLoadingOverlayBloc
} from "src/ui/components/BlockingLoadingOverlay/BlockingLoadingOverlayController";
import LoaderIconImage from "./LoadingIconSmall.webp";
import LoaderIconImagePartner from "./LoadingIconSmallPartner.webp";
import LoadingCompleteIconImage from "./LoadingComplete.svg";
import clsx from "clsx";
import translate from "src/lib/translate";
import Translate from "src/ui/components/Translate/Translate";
import AppViewCubit from "src/state/AppViewCubit/AppViewCubit";
import Logo from "src/ui/assets/icons/Logo";

const SpinKeyframes = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const EndSpinKeyframes = keyframes`
    0% {
      opacity: 0;
        transform: rotate(180deg);
    }
    100% {
      opacity: 1;
        transform: rotate(360deg);
    }
`;

const Fullpage = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99999999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  height: 100svh;
  opacity: 0;
  pointer-events: none;
  --spinner-scale: 1;
  transition:
    opacity 0.3s ease-in-out,
    background-color 0.3s ease-in-out;

  &[data-status="loading"],
  &[data-status="error"],
  &[data-status="success"] {
    opacity: 1;
    pointer-events: all;

    &[data-fade-in="false"] {
      transition: none;
    }
  }

  &[data-status="error"] {
    background-color: rgba(0, 0, 0, 0.75);
  }

  &[data-bg="solid"] {
    background: #000;
    &::before {
      content: "";
      position: absolute;
      inset: 0;
      opacity: 0.4;
      background: linear-gradient(
        60deg,
        var(--color-sunrise-blue) 13.49%,
        var(--color-sunrise-pink) 51.22%,
        var(--color-sunrise-orange) 85.11%
      );
    }
  }

  &[data-bg="partner"] {
    background: var(--color-cream);
    --spinner-scale: 0.6;

    #branding-logo {
      display: flex;
    }
  }

  &[data-bg="branded"] {
    background: var(--color-cream);
    --logo-pos: calc(var(--safe-area-top, 0) + 1.8rem);

    #branding-logo {
      display: flex;
    }
  }

  #branding-logo {
    display: none;
  }
`;

const ImgWrap = styled.div`
  position: absolute;
  width: 4rem;
  height: 4rem;
  opacity: 0;
  transform: scale(var(--spinner-scale, 1));
  transition: opacity 0.3s ease-in-out;

  img {
    width: 100%;
    height: 100%;
    display: block;
  }

  &.loading-progress img {
    animation: ${SpinKeyframes} 1.2s linear infinite;
  }

  &.loading-complete img {
    display: none;
    animation: ${EndSpinKeyframes} 0.6s ease-out forwards;
  }

  [data-status="success"] &.loading-complete,
  [data-status="loading"] &.loading-progress {
    opacity: 1;
    img {
      display: block;
    }
  }
`;

const ShowErrorKeyframes = keyframes`
  0% {
    opacity: 0;
    transform: translateY(1rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const ErrorContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: min(100%, 370px);
  padding: 0 1.6rem;
  position: relative;
  z-index: 10;
  animation: ${ShowErrorKeyframes} 0.6s var(--ease-type) forwards;

  .color {
    color: white;
  }

  h3,
  p {
    text-wrap: balance;
  }
`;

const NineLogo = styled.div`
  position: absolute;
  top: var(--logo-pos, calc(50svh - 4.5rem));
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;

  svg {
    display: block;
    transform: scale(1.2);
  }
`;

export const LoadingSpinner: FC<{ style?: React.CSSProperties }> = (props) => {
  return (
    <ImgWrap className="loading-progress" {...props}>
      <img src={appViewState.state.partnerStyles ? LoaderIconImagePartner : LoaderIconImage} alt={translate("loading")} />
    </ImgWrap>
  );
};

const BlockingLoadingOverlay: FC = () => {
  const [{ status, error, bg, fadeIn }] = useBloc(BlockingLoadingOverlayBloc, {
    create: () => BlockingLoadingOverlayController
  });
  const [{ partnerSource }] = useBloc(AppViewCubit);

  const handleRetry = () => {
    BlockingLoadingOverlayController.callErrorRetry();
  };

  const handleCancel = () => {
    BlockingLoadingOverlayController.callOnCancel();
    BlockingLoadingOverlayController.endLoading();
  };

  return (
    <Fullpage
      data-status={status}
      data-bg={bg}
      data-partner={partnerSource}
      data-fade-in={String(fadeIn)}
    >
      <NineLogo id="branding-logo">
        <Logo />
      </NineLogo>
      <LoadingSpinner />
      <ImgWrap className="loading-complete">
        <img
          src={LoadingCompleteIconImage}
          alt={translate("loadingCompleted")}
        />
      </ImgWrap>
      {error && (
        <ErrorContent>
          <NineHeading>
            <h3 className="as-h4-large color">
              {error.title ?? translate("error.generic.short")}
            </h3>
            <nine-spacer s="xs"></nine-spacer>
            <p className="m0 color">
              {error.message ?? translate("error.generic.short.tryAgain")}
            </p>
          </NineHeading>
          <nine-spacer s="md"></nine-spacer>
          {error.retry && (
            <NineButton onClick={handleRetry}>
              <Translate msg="tryAgain" />
            </NineButton>
          )}
          <nine-spacer s="xs"></nine-spacer>

          <NineButton
            onClick={handleCancel}
            arrow=""
            variant={error.retry ? "ghost" : "fill"}
            className={clsx({
              color: Boolean(error.retry)
            })}
            padding="equal"
          >
            <Translate msg="cancel" />
          </NineButton>
        </ErrorContent>
      )}
    </Fullpage>
  );
};

export default BlockingLoadingOverlay;
