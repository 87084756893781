import styled from "@emotion/styled";
import { z } from "zod";
import { FactoryArg } from "imask";
import { Label, Text } from "react-aria-components";
import { AriaTextFieldProps } from "react-aria";
import { FormFieldBaseState } from "atom/autoform/AutoFormBloc";
import React from "react";

export const TextAreaContainer = styled.div`
  label: TextAreaEl;
  font-weight: 400;
  display: flex;
  flex-direction: column;

  &[data-disabled="true"] * {
    opacity: 0.7;
    filter: grayscale(1);
    cursor: not-allowed;
    resize: none;
  }
`;

export const TextAreaWrap = styled.div`
  label: InputWrap;
  border-radius: 0.5rem;
  background: var(--greys-white, #fff);
  min-height: 4rem;
  display: flex;
  position: relative;
  background: var(--greys-charcoal-60, rgba(33, 33, 33, 0.6));
  transition: all 0.2s ease-in-out;

  &::before {
    content: "";
    position: absolute;
    --inset-size: 1px;
    inset: var(--inset-size);
    transition: all 0.2s ease-in-out;
    display: block;
    background: white;
    z-index: 2;
    border-radius: calc(0.5rem - var(--inset-size));
    pointer-events: none;
  }

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    transition: all 0.2s ease-in-out;
    display: block;
    opacity: 0;
    background: var(--input-border-bg, linear-gradient(
      46deg,
      #ffd199 13.49%,
      #fad0e8 50.42%,
      #a6c6ff 85.11%
    ));
    pointer-events: none;
    z-index: 1;
    border-radius: inherit;
  }

  &:focus-within {
    background: transparent;
    box-shadow:
      0px 0px 0px 0px rgba(69, 49, 22, 0.08),
      -5px 5px 16px 0px rgba(69, 49, 22, 0.08),
      -20px 20px 29px 0px rgba(69, 49, 22, 0.07),
      -46px 45px 39px 0px rgba(69, 49, 22, 0.03),
      -82px 81px 46px 0px rgba(69, 49, 22, 0.01);

    &::before {
      --inset-size: 2px;
    }

    &::after {
      opacity: 1;
    }
  }

  > * {
    position: relative;
    z-index: 3;
  }

  &[data-invalid="true"] {
    background: var(--primary-warning-red, #bf1902);
  }
`;

export const TextAreaInputEl = styled.textarea`
  display: block;
  width: 100%;
  border: none;
  background: transparent;
  font-size: inherit;
  font-family: inherit;
  padding: 1.28rem 1rem;
  border-top: 0 solid transparent;
  min-height: 4rem;
  height: 100%;
  outline: none;
  resize: vertical;
  box-sizing: border-box;

  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.4rem */
  letter-spacing: -0.02rem;

  transition: all 0.2s ease-in-out;
  transition-property: margin, padding, color, min-height, border;

  &:not(:focus)::placeholder {
    color: transparent;
  }

  &[aria-labelledby] {
    &:focus,
    &:not(textarea[value=""]),
    &:not(textarea[placeholder=""]) {
      border-top: 2rem solid transparent;
      padding: 0 1rem 0;
    }
  }

  &[data-large] {
    height: 160px;
  }
`;

export const TextAreaInputLabelEl = styled(Label)`
  opacity: 0.6;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.2s ease-in-out;
  transform-origin: top left;
  left: 1rem;

  &[data-large] {
    top: 2rem;
  }

  textarea:focus + &,
  textarea:not(textarea[data-value=""]) + & {
    top: 0.625rem;
    transform: translateY(0%) scale(0.75);
  }
`;

export const TextAreaInputTextEl = styled(Text)`
  padding: 0.5rem 0.125rem 0rem 0.125rem;
  opacity: 0.8;
`;

export const TextAreaInputErrorEl = styled.span`
  padding: 0.5em 1em 1em;
  display: block;
  color: var(--primary-warning-red, #bf1902);

  @keyframes show {
    from {
      opacity: 0;
      transform: translateY(-0.5rem);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  animation: show 0.2s linear forwards;
`;

export interface TextareaProps extends AriaTextFieldProps<HTMLTextAreaElement> {
  maxRows?: number;
  errorParser?: (props: TextareaProps) => string;
  mask?: FactoryArg;
  error?: string | z.ZodIssue;
  large?: boolean;
  ref?: React.Ref<FormFieldBaseState | null>;
}
