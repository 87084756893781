import React from "react";
import AppViewCubit from "src/state/AppViewCubit/AppViewCubit";
import { useBloc } from "src/state/state";

const DebugPartnerSession = () => {
  const [state, bloc] = useBloc(AppViewCubit);

  const updatePartnerSession = () => {
    bloc.emit({ ...state, partnerSession: !state.partnerSession });
  };

  const setPartnerSessionStyles = () => {
    bloc.emit({ ...state, partnerStyles: !state.partnerStyles });
    bloc.setPartnerSessionStyles();
  };

  return (
    <div>
      <label>
        <input
          type="checkbox"
          checked={!!state.partnerSession}
          onChange={() => {
            updatePartnerSession();
          }}
        />
        <span style={{ marginLeft: "4px", fontSize: "1rem" }}>
          Is Partner Session Transcarent
        </span>
      </label>
      <br />
      <label>
        <input
          type="checkbox"
          checked={!!state.partnerStyles}
          onChange={() => {
            setPartnerSessionStyles();
          }}
        />
        <span style={{ marginLeft: "4px", fontSize: "1rem" }}>
          Paartner Styles Active
        </span>
      </label>
    </div>
  );
};

export default DebugPartnerSession;
