import type {
  AddAnswerToSessionRequest,
  AnswerItemDto,
  ApiError,
  FeatureResponse,
  JsonNode,
  SubmitAnswersDataRequest
} from "@9amhealth/openapi";
import {
  LabOrderControllerService,
  QuestionnaireControllerService,
  QuestionnaireRef,
  StartSessionRequest,
  UserLifelineItemControllerService
} from "@9amhealth/openapi";
import { matchesAmazonEligibilityCriteria } from "@9amhealth/shared/src/lib/amazon/AmazonTreatmentTarget";
import { Cubit } from "blac";
import { QuestionnaireDiagnosisOption } from "lib/constants/QuestionnaireDiagnosisOption";
import appErrors from "src/constants/appErrors";
import { LoincCodingCode } from "src/constants/fhir";
import { addSentryBreadcrumb } from "src/lib/addSentryBreadcrumb";
import { DateFormats, dateLocal } from "src/lib/date";
import { featureFlags } from "src/lib/featureFlags";
import type { CustomObservation } from "src/lib/fhir";
import Fhir, { FhirObservationType } from "src/lib/fhir";
import {
  formatPhoneNumberNational,
  numbersAreEqual
} from "src/lib/formatPhoneNumber";
import parseChoice from "src/lib/parseChoice";
import { removeQuestionnaireKeywordsFromText } from "src/lib/removeQuestionnaireKeywordsFromText";
import reportErrorSentry from "src/lib/reportErrorSentry";
import { LoadingKey } from "src/state/LoadingCubit/LoadingCubit";
import ProfileCubit from "src/state/ProfileCubit/ProfileCubit";
import type {
  CustomQuestionnaireAnswer,
  CustomQuestionnaireAnswerType,
  CustomQuestionnaireAnswerValue,
  CustomQuestionnaireChoice,
  CustomQuestionnaireFilterAnswerOptions,
  CustomQuestionnaireResult,
  GlobalConfigs,
  MedicalInputData,
  MultipleTextInputData,
  QuestionnaireState
} from "src/state/QuestionnaireCubit/QuestionnaireState";
import {
  CustomQuestionnaireSchema,
  TimeCode
} from "src/state/QuestionnaireCubit/QuestionnaireState";
import { CustomFieldPropertyRegex } from "src/state/QuestionnaireStepCubit/CustomFields";
import type {
  QuestionFieldValidation,
  QuestionnaireField,
  QuestionnaireSelectChoice
} from "src/state/QuestionnaireStepCubit/QuestionnaireStepCubit";
import QuestionnaireStepCubit, {
  QuestionnaireFieldDataType,
  QuestionnaireType
} from "src/state/QuestionnaireStepCubit/QuestionnaireStepCubit";
import {
  apiMiddleware,
  historyState,
  loadingState,
  subscriptionState,
  userPreferences,
  userState
} from "src/state/state";
import UserPreferencesCubit, {
  UserPreferenceKeys
} from "src/state/UserPreferencesCubit/UserPreferencesCubit";
import type { TranslationKey } from "src/types/translationKey";
import { QuestionnaireParsedSelectChoice } from "src/ui/components/MultipleChoiceInput/MultipleChoiceInput";
import { StorageController } from "../StorageBloc/StorageBloc";
import UserCubit from "../UserCubit/UserCubit";

export const VALUE_NOT_AVAILABLE = "N/A";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ComputedVariablesConfig<U = any> = {
  fieldRef: string;
  variableName: string;
  idToVarValueMap: Record<string, U>;
  additionalParse?: (varValue: Set<U>, selectedIds: Set<string>) => Set<U>;
};

type QuestionnaireAnswerValue =
  | Record<string, number | string>
  | boolean
  | number
  | string
  | undefined;

export const questionnaireFieldValidation: Record<
  QuestionFieldValidation,
  RegExp
> = {
  // numbers between 4 and 16 with one optional decimal point
  a1c: /^[4-9]([,.][0-9])?$|^[1][0-6]([,.][0-9])?$/,

  // Two numbers separated by a slash, 2-3 digits each
  bloodpressure: /^[1-9]\d{1,2}\/[1-9]\d{1,2}$/,

  // Two or 3 digits, the first one is not 0
  bloodglucose: /^[1-9]\d{1,2}([.]\d{1,3})?$/,

  // Two or 3 digits, the first one is not 0
  weight: /^[1-9]\d{1,2}$/,

  // Two or 3 digits, the first one is not 0
  waistCircumference: /^[1-9]\d{1,2}$/
};

const questionHiddenFields = {
  hyperlipidemia: {
    choiceId: "RwvfbGaa9trK",
    hiddenField: "mjs"
  },
  hypertension: {
    choiceId: "Ct27aDq5UmIL",
    hiddenField: "bsu"
  },
  prediabetes: {
    choiceId: "8av8yhLUfV5K",
    hiddenField: "nsi"
  },
  t2d: {
    choiceId: "BkrbtDEDl95A",
    hiddenField: "ksh"
  },
  name: {
    choiceId: "nJWoQ4iXqW4S",
    hiddenField: "kwb"
  }
};
const { prediabetes, t2d, name, hypertension, hyperlipidemia } =
  questionHiddenFields;

export enum QuestionnaireStepTypeOutput {
  TEXT = "text",
  MULTIPLE_CHOICE = "multiple-choice",
  DROPDOWN = "dropdown",
  BOOLEAN = "boolean",
  CHOICE = "choice",
  INTEGER = "number",
  MEDICATION = "medication_amounts",
  MULTIPLE_TEXT = "multiple-text"
}

export type QuestionnaireValue =
  | Record<string, number | string>
  | string[]
  | boolean
  | number
  | string
  | undefined;

export type QuestionnaireLogicConditionVarInnerType =
  | "choice"
  | "constant"
  | "field"
  | "hidden"
  | "variable";

export type QuestionnaireLogicConditionOperation =
  | "always"
  | "and"
  | "begins_with"
  | "contains"
  | "equal"
  | "greater_equal_than"
  | "greater_than"
  | "is_not"
  | "is"
  | "lower_equal_than"
  | "lower_than"
  | "not_contains"
  | "not_equal"
  | "or";

export interface QuestionnaireLogicConditionVarInner {
  type: QuestionnaireLogicConditionVarInnerType;
  value: QuestionnaireValue;
}

export interface QuestionnaireLogicConditionVarInnerResolved {
  field?: QuestionnaireField;
  comparandValue: QuestionnaireValue[];
  match: boolean;
  subjectValue: QuestionnaireValue[];
}

export interface QuestionnaireLogicCondition {
  op: QuestionnaireLogicConditionOperation;
  vars: QuestionnaireLogicCondition[] | QuestionnaireLogicConditionVarInner[];
}

export interface QuestionnaireLogicDetailSet {
  target: {
    type: "variable";
    value: string;
  };
  value: {
    type: "constant" | "field" | "hidden" | "variable";
    value: string;
  };
}

export interface QuestionnaireLogicDetailJump {
  to: {
    type: "field" | "thankyou";
    value: string;
  };
}

export interface QuestionnaireLogicAction {
  action: "add" | "divide" | "jump" | "multiply" | "set";
  details: QuestionnaireLogicDetailJump | QuestionnaireLogicDetailSet;
  condition: QuestionnaireLogicCondition;
}

export interface QuestionnaireLogic {
  type: "field" | "hidden";
  ref?: string; // is undefined for logic that runs at beginning of questionnaire
  actions: QuestionnaireLogicAction[];
}

type HiddenFields = Record<string, string>;
export type CachedObject = Record<string, QuestionnaireValue>;

export const CACHE_KEY_PREFIX = "9am.form_fields.";

type KeyValueList = [string, number | string][];

export default class QuestionnaireCubit extends Cubit<QuestionnaireState> {
  customFormData: CachedObject = {};
  customFormVariables: CachedObject = {};
  allowTracking = true;
  preview = false;
  dryRunSteps: string[] = [];
  lastUpdated = 0;
  lastSaved = 0;
  disableAutoSubmit = false;
  instanceId = "";
  preventLoadingUserAnswers = false;
  allFieldStatus = new Map<string, boolean>();
  customKeyList = new Set<string>();
  onDataSentCallback?: (data: CustomQuestionnaireResult) => void;
  onLastStepCompleted?: () => void;
  answerOverrides: CustomQuestionnaireAnswer[];
  filterAnswerOptions: CustomQuestionnaireFilterAnswerOptions[];
  activeField?: QuestionnaireField;
  forceRepeat = false;

  _dryRun = 0;
  _activeStepPreDryRun?: QuestionnaireField;
  get dryRun(): boolean {
    return this._dryRun > 0;
  }
  set dryRun(value: boolean) {
    if (this._dryRun === 0) {
      this.activeField = this._activeStepPreDryRun;
    } else if (this._dryRun === 1 && value) {
      this._activeStepPreDryRun = this.activeField;
    }

    this._dryRun += value ? 1 : -1;
  }

  constructor(
    id: string,
    options: {
      forceRepeat?: boolean;
      hiddenFields?: CachedObject;
      disableTracking?: boolean;
      preview?: boolean;
      disableAutoSubmit?: boolean;
      instanceId?: string;
      preventLoadingUserAnswers?: boolean;
      onDataSent?: (data: CustomQuestionnaireResult) => void;
      answerOverrides?: CustomQuestionnaireAnswer[];
      filterAnswerOptions?: CustomQuestionnaireFilterAnswerOptions[];
      onLastStepCompleted?: () => void;
    } = {}
  ) {
    super({
      formId: id,
      hiddenFields: options.hiddenFields ?? {},
      fields: [],
      endScreens: [],
      logic: [],
      formMeta: {},
      customFormData: {},
      error: "",
      logicSteps: []
    });

    this.answerOverrides = options.answerOverrides ?? [];
    this.filterAnswerOptions = options.filterAnswerOptions ?? [];
    this.onDataSentCallback = options.onDataSent;
    this.onLastStepCompleted = options.onLastStepCompleted;

    if (options.forceRepeat) {
      this.forceRepeat = true;
    }

    if (options.disableTracking) {
      this.allowTracking = false;
    }

    if (options.disableAutoSubmit) {
      this.disableAutoSubmit = true;
    }

    this.instanceId = options.instanceId ?? "";
    this.preventLoadingUserAnswers = options.preventLoadingUserAnswers ?? false;
    this.preview = Boolean(options.preview);
    this.customFormVariables = options.hiddenFields ?? {};
    this.insertCachedValues();
    void this.loadCustomFormData();
  }

  get loggingEnabled(): boolean {
    return false;
  }
  log = (message: string, ...data: unknown[]): void => {
    if (this.loggingEnabled) {
      // eslint-disable-next-line no-console
      console.warn(`[Q] ${message}`, data);
    }
  };

  staticAnswers: () => CustomQuestionnaireAnswer[] = () => {
    const additionalVars = {
      questionId: "questionnaire-vars",
      fieldType: "multiple-choice",
      fieldValue: this.collectVarsAsAnswers()
    } satisfies CustomQuestionnaireAnswer;

    const alwaysSendAnswers: CustomQuestionnaireAnswer[] = [
      {
        questionId: "r33X6Pmcju8c",
        fieldType: "text",
        fieldValue: dateLocal().format(DateFormats.ISO_FULL)
      }
    ];

    const legacyAnswers = this.generateLegacyAnswers();

    const allStatic: CustomQuestionnaireAnswer[] = [
      ...alwaysSendAnswers,
      ...legacyAnswers
    ];

    if (additionalVars.fieldValue.length > 0) {
      allStatic.push(additionalVars);
    }

    return allStatic;
  };

  generateLegacyAnswers = (): CustomQuestionnaireAnswer[] => {
    const legacyAnswers: CustomQuestionnaireAnswer[] = [];

    try {
      // Insurance Card Information
      // Get the values from the new question that has both fields
      const newInsQuestionValues: { groupno?: string; memberid?: string } = {};
      const formDataKeys = Object.keys(this.customFormData);
      for (const key of formDataKeys) {
        if (key.includes("groupno")) {
          newInsQuestionValues.groupno = String(this.customFormData[key]);
        }
        if (key.includes("memberid")) {
          newInsQuestionValues.memberid = String(this.customFormData[key]);
        }
      }
      if (newInsQuestionValues.groupno && newInsQuestionValues.memberid) {
        legacyAnswers.push({
          questionId: "WoKWiVuzOqoh",
          fieldType: "text",
          fieldValue: newInsQuestionValues.memberid
        });
        legacyAnswers.push({
          questionId: "x2MdbmI6mjTF",
          fieldType: "text",
          fieldValue: newInsQuestionValues.groupno
        });
      }
    } catch (error) {
      reportErrorSentry(error);
    }

    return legacyAnswers;
  };

  collectVarsAsAnswers = (): CustomQuestionnaireChoice[] => {
    const list: {
      value: string;
      choiceId: string;
    }[] = [];

    const sendFrom = this.customFormVariables;

    for (const key in sendFrom) {
      const value = sendFrom[key];
      if (
        value === undefined ||
        value === VALUE_NOT_AVAILABLE ||
        (typeof value === "number" && isNaN(value))
      )
        continue;
      list.push({ value: String(value), choiceId: key });
    }

    return list;
  };

  static readonly parseTypeformDataFieldChoice = (
    answer: AnswerItemDto
  ): HiddenFields => {
    const keyValueMap: HiddenFields = {};
    const data = answer.fieldValue as CustomQuestionnaireChoice;

    if (data.choiceId === t2d.choiceId) {
      keyValueMap[t2d.hiddenField] =
        data.value === "Type 2 Diabetes" ? "1" : "0";
    }
    return keyValueMap;
  };

  static readonly parseTypeformDataFieldMultiChoice = (
    answer: AnswerItemDto
  ): HiddenFields => {
    const keyValueMap: HiddenFields = {};
    const data = answer.fieldValue as CustomQuestionnaireChoice[];

    for (const field of data) {
      switch (field.choiceId) {
        case prediabetes.choiceId:
          keyValueMap[prediabetes.hiddenField] = "1";
          break;
        case hypertension.choiceId:
          keyValueMap[hypertension.hiddenField] = "1";
          break;
        case hyperlipidemia.choiceId:
          keyValueMap[hyperlipidemia.hiddenField] = "1";
          break;
      }
    }
    return keyValueMap;
  };

  static readonly parseAnswers = (items?: AnswerItemDto[]): CachedObject => {
    let keyValueMap: CachedObject = {};
    if (!items) return keyValueMap;

    items.forEach((answer): void => {
      try {
        let newValues: HiddenFields = {};
        if (answer.fieldType === "choice") {
          newValues = QuestionnaireCubit.parseTypeformDataFieldChoice(answer);
        } else if (answer.fieldType === "multiple-choice") {
          newValues =
            QuestionnaireCubit.parseTypeformDataFieldMultiChoice(answer);
        } else if (
          answer.fieldType === "text" &&
          answer.questionId === name.choiceId
        ) {
          keyValueMap[name.hiddenField] = answer.fieldValue;
        }

        keyValueMap = {
          ...keyValueMap,
          ...newValues
        };
      } catch (e: unknown) {
        reportErrorSentry(e);
      }
    });

    return keyValueMap;
  };

  readonly generateOutputData = (): CustomQuestionnaireAnswer[] => {
    this.collectLogicSteps();
    const answersJson = Object.keys(this.customFormData)
      .filter((id) => {
        const field = this.getFieldByRef(id);
        const includedInLogic = field && this.dryRunSteps.includes(field.ref);
        const alwaysIncluded = field?.properties?.always_send_data ?? false;

        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        return includedInLogic || alwaysIncluded;
      })
      .map((key) => this.parseAnswer(key))
      .filter(Boolean);

    const overrides = this.expandSimpleAnswers(this.answerOverrides);

    return this.cleanAnswers([
      ...overrides,
      ...answersJson,
      ...this.staticAnswers()
    ]);
  };

  expandSimpleAnswers = (
    answers: CustomQuestionnaireAnswer[]
  ): CustomQuestionnaireAnswer[] => {
    const full: CustomQuestionnaireAnswer[] = [];

    const supportedFieldTypes = [
      QuestionnaireType.MULTIPLE_CHOICE,
      QuestionnaireType.DROPDOWN
    ];

    answers.forEach((answer) => {
      const field = this.getFieldByRef(answer.questionId);
      if (!field) return;
      if (!supportedFieldTypes.includes(field.type)) {
        this.log(
          `Unsupported field type for answer overrides: ${
            field.type
          }. Supported types: ${supportedFieldTypes.join(", ")}`
        );
      } else {
        full.push(answer);
      }
    });

    return full;
  };

  cleanAnswers = (
    answers: CustomQuestionnaireAnswer[]
  ): CustomQuestionnaireAnswer[] => {
    const clean: CustomQuestionnaireAnswer[] = [];
    const questionIdAddedMap = new Set<string>();

    answers.forEach((answer) => {
      // remove duplicates, keep first
      if (questionIdAddedMap.has(answer.questionId)) return;
      questionIdAddedMap.add(answer.questionId);
      clean.push(answer);
    });

    return clean;
  };

  setFieldStatus = (id: string, hasIssue: boolean): void => {
    this.allFieldStatus.set(id, hasIssue);
  };

  postMessageDebuff = 0;
  sendDataToParent = (): void => {
    if (!userState.isTempUser) return;

    if (!this.state.formId) {
      reportErrorSentry("No formId in state");
      return;
    }

    const id = this.state.formId;

    window.clearTimeout(this.postMessageDebuff);

    this.postMessageDebuff = window.setTimeout(() => {
      const answersJson = this.generateOutputData();
      const allValid = true;

      const data: CustomQuestionnaireResult & { allValid: boolean } = {
        questionnaireRef: {
          id,
          type: "TYPEFORM"
        },
        answers: {
          schemaId: CustomQuestionnaireSchema.TYPEFORM,
          json: answersJson
        },
        allValid
      };

      window.parent.postMessage(
        {
          type: "questionnaireData",
          data
        },
        "*"
      );
    }, 200);
  };

  readonly setActiveFieldFromHash = (ref: string): void => {
    const field = this.getFieldByRef(ref.replace("#", ""));
    const active = this.state.activeField;
    if (!active || !field || field.ref === active.ref) return;

    const currentStep = this.state.fields.find(
      (item) => item.ref === active.ref
    );

    if (!currentStep || this.isFinishStep(currentStep)) {
      return;
    }

    const currentStepIndex = this.state.logicSteps.indexOf(currentStep.ref);
    const requestedStepIndex = this.state.logicSteps.indexOf(field.ref);
    if (currentStepIndex < requestedStepIndex) return;

    this.log("setActiveFieldFromHash", field);
    this.handleJump({
      to: {
        value: field.ref,
        type: "field"
      }
    });
  };

  readonly onComplete = async (
    options: { saveData?: boolean } = {}
  ): Promise<void> => {
    const { saveData = true } = options;

    if (!this.state.formId) {
      reportErrorSentry("No formId in state");
      return;
    }

    if (this.lastUpdated === this.lastSaved) {
      this.log("onComplete: No changes");
      return;
    }

    try {
      // store completed questionnaires in memory
      userState.completedQuestionnairesMemoryCache.add(this.state.formId);
      // set in session
      sessionStorage.setItem(
        "completedQuestionnaires",
        JSON.stringify(Array.from(userState.completedQuestionnairesMemoryCache))
      );
    } catch (error) {
      reportErrorSentry(error);
    }

    const answersJson = this.generateOutputData();

    const data = {
      questionnaireRef: {
        id: this.state.formId,
        type: QuestionnaireRef.type.TYPEFORM
      },
      answers: {
        schemaId: CustomQuestionnaireSchema.TYPEFORM,
        json: answersJson
      },
      sessionId: this.currentSessionId
    } satisfies SubmitAnswersDataRequest;

    this.sendDataToParent();

    if (this.disableAutoSubmit || userState.isTempUser) {
      return;
    }

    if (saveData) {
      this.log("saving data", data);
      loadingState.start(LoadingKey.saveQuestionnaireResult);
      this.lastSaved = this.lastUpdated;
      this.setSessionStorage();

      // save phone number, from phone input
      await this.savePhoneNumberToNotificationNumber();

      userState.clearAnsweredQuestionnairesCache();
      // save data
      try {
        // typecast because the OpenAPI docs expect some other fields that are actually not required
        await QuestionnaireControllerService.answerWithData(data);
        StorageController.removeItem(this.cacheKey);
        document.dispatchEvent(
          new CustomEvent("nineQuestionnaireSaved", {
            bubbles: true,
            composed: true,
            detail: { ...data, customFormVariables: this.customFormVariables }
          })
        );

        // delete session ID that has ended
        this.currentSessionId = undefined;

        // Clear cached data
        apiMiddleware.clearAll();

        this.onDataSentCallback?.(data);
      } catch (e: unknown) {
        this.emit({ ...this.state, error: appErrors.generic });
        reportErrorSentry(e);
      }

      loadingState.finish(LoadingKey.saveQuestionnaireResult);
    }
    this.emit({ ...this.state, questionnaireCompleted: true });
    this.setSessionStorage();
    return;
  };

  readonly savePhoneNumberToNotificationNumber = async (): Promise<void> => {
    const steps = this.state.fields;
    const phoneField = steps.find(
      (item) => item.type === QuestionnaireType.PHONE_NUMBER
    );
    if (!phoneField) return;

    const phoneValue = this.customFormData[phoneField.id];
    const value = phoneValue
      ? formatPhoneNumberNational(String(phoneValue))
      : "";
    if (!value) return;

    try {
      const currentNumber = await ProfileCubit.getPhoneNumber();

      if (numbersAreEqual(currentNumber?.number, value)) return;

      await UserCubit.setNotificationNumber(value, {
        showLoading: false
      });
    } catch (error) {
      reportErrorSentry(error);
    }
  };

  readonly getQuestionnaireData = async (id?: string): Promise<AnyObject> => {
    if (!id)
      return {
        fields: [],
        thankyou_screens: [],
        logic: [],
        variables: {},
        welcome_screens: []
      };

    const featureFlagDisableQuestionnaireCache =
      featureFlags.disableQuestionnaireCache;

    const useCache =
      !featureFlagDisableQuestionnaireCache && Boolean(!this.preview);

    const overwriteLanguage = historyState.initialLocale;

    const response = await QuestionnaireControllerService.getQuestionnaire(
      "TYPEFORM",
      id,
      useCache,
      overwriteLanguage
    );
    return response.data;
  };

  private readonly fillCustomFormMultipleChoice = (
    answer: CustomQuestionnaireAnswer,
    field: QuestionnaireField
  ): void => {
    const choices = field.properties?.choices ?? [];
    if (answer.fieldType === "multiple-choice") {
      // set data for the list of selected choices
      this.customFormData[field.id] = (
        answer.fieldValue as CustomQuestionnaireChoice[]
      ).map((item) => {
        // set data for each selected choice
        this.customFormData[item.choiceId] = true;
        return item.choiceId;
      });
    } else if (answer.fieldType === "choice") {
      // set data for the selected as it would be a multi-select
      this.customFormData[field.id] = [
        choices.find((item) => {
          const match =
            item.label ===
            (answer.fieldValue as CustomQuestionnaireChoice).value;
          if (match) {
            // set data for the selected choice
            this.customFormData[item.id] = true;
          }
          return match;
        })?.id
      ].filter(Boolean);
    }
  };

  private readonly fillCustomFormDropdown = (
    answer: CustomQuestionnaireAnswer,
    field: QuestionnaireField
  ): void => {
    const choices = field.properties?.choices ?? [];
    // set data for the selected as it would be a multi-select
    this.customFormData[field.id] = [
      choices.find((item) => {
        const match = item.label === answer.fieldValue;
        if (match) {
          // set data for the selected choice
          this.customFormData[item.id] = true;
        }
        return match;
      })?.id
    ].filter(Boolean);
  };

  readonly fillCustomFormData = (
    answer: CustomQuestionnaireAnswer,
    fields: QuestionnaireField[]
  ): void => {
    const field = fields.find((item) => item.id === answer.questionId);
    if (!field) return;

    const choices = field.properties?.choices ?? [];

    // remove all cached data for this steps selected choices
    for (const c of choices) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete this.customFormData[c.id];
    }

    switch (field.type) {
      case QuestionnaireType.MULTIPLE_CHOICE:
        this.fillCustomFormMultipleChoice(answer, field);
        break;

      case QuestionnaireType.DROPDOWN:
        this.fillCustomFormDropdown(answer, field);
        break;

      case QuestionnaireType.DATE:
      case QuestionnaireType.LONG_TEXT:
      case QuestionnaireType.SHORT_TEXT:
      case QuestionnaireType.EMAIL:
      case QuestionnaireType.PHONE_NUMBER:
        this.customFormData[field.id] = String(answer.fieldValue);
        break;

      case QuestionnaireType.NUMBER:
      case QuestionnaireType.OPINION_SCALE:
        this.customFormData[field.id] = parseFloat(answer.fieldValue as string);
        break;

      case QuestionnaireType.YES_NO:
        this.customFormData[field.id] = answer.fieldValue ? "Yes" : "No";
        break;

      case QuestionnaireType.MEDICATION:
        this.customFormData[field.id] = answer.fieldValue as Record<
          string,
          string
        >;
        break;

      case QuestionnaireType.MULTIPLE_TEXT:
        this.customFormData[field.id] = answer.fieldValue as Record<
          string,
          string
        >;
        break;

      case QuestionnaireType.ZIP_CODE:
        this.customFormData[field.id] = String(answer.fieldValue);
        break;

      case QuestionnaireType.STATEMENT: {
        throw new Error(
          "Not implemented yet: QuestionnaireType.STATEMENT case"
        );
      }
      case QuestionnaireType.THANK_YOU: {
        throw new Error(
          "Not implemented yet: QuestionnaireType.THANK_YOU case"
        );
      }
    }
  };

  readonly loadAnsweredQuestionnaireData = async (
    fields: QuestionnaireField[]
  ): Promise<void> => {
    if (this.preventLoadingUserAnswers) return;

    const allAnswers = await userState.fetchAnsweredQuestionnaires();

    const onlyAnswers: CustomQuestionnaireAnswer[] = allAnswers
      .map((item) => item.answers.json as CustomQuestionnaireAnswer)
      .flat(1)
      .reverse();

    for (const answer of onlyAnswers) {
      this.fillCustomFormData(answer, fields);
    }

    this.collectLogicSteps();
    this.emit({
      ...this.state,
      customFormData: this.customFormData
    });
    this.collectLogicSteps();
  };

  readonly prefillUserData = async (
    fields: QuestionnaireField[]
  ): Promise<void> => {
    if (this.preventLoadingUserAnswers) return;
    const stored = this.getCachedValues();

    for (const field of fields) {
      const dataType = QuestionnaireStepCubit.checkDataType(field);

      let value: QuestionnaireValue | undefined;
      const key = field.id;

      switch (dataType) {
        case QuestionnaireFieldDataType.NAME:
          value = {
            firstname:
              userPreferences.state[UserPreferenceKeys.userFirstName] ??
              stored[`${key}.firstname`],
            lastname:
              userPreferences.state[UserPreferenceKeys.userLastName] ??
              stored[`${key}.lastname`]
          } as Record<string, number | string>;
          break;
        case QuestionnaireFieldDataType.DATE_OF_BIRTH:
          value = userPreferences.state[UserPreferenceKeys.userDateOfBirth];
          break;
        case QuestionnaireFieldDataType.ZIP_CODE:
          value = userPreferences.state[UserPreferenceKeys.shipmentAddressZip];
          break;
        default:
          break;
      }

      if (key && typeof value !== "undefined") {
        this.saveValue(key, value);
      }

      // handle special cases
      if (dataType === QuestionnaireFieldDataType.ASSIGNED_SEX) {
        const match = field.properties?.choices?.find(
          (f) =>
            f.label.toLowerCase() ===
            userPreferences.state[UserPreferenceKeys.userSex]?.toLowerCase()
        )?.id;

        if (match) {
          this.saveValue(key, [match]);
          this.saveValue(match, true);
        }

        break;
      }
    }
  };

  readonly updateFieldMetadata = (
    field: QuestionnaireField
  ): QuestionnaireField => {
    const filterForField = this.filterAnswerOptions.find(
      (filter) => filter.questionId === field.id
    );

    // filter out choices that are not in the includeOptions list
    if (filterForField && field.properties && field.properties.choices) {
      const choices = field.properties.choices ?? [];
      field.properties.choices = choices.filter((choice) => {
        const match = filterForField.includeOptions.find(
          (filterChoice) =>
            filterChoice === choice.id || filterChoice === choice.ref
        );

        if (match) {
          return true;
        }

        return false;
      });
    }

    // always filter out choices
    const EMPLOYER_QUESTIONNAIRE_FIELD_ID = "nnr4MisyYRqS";
    const EMPLOYER_QUESTIONNAIRE_HIDDEN_OPTIONS: string[] = [];

    // remove apple option
    EMPLOYER_QUESTIONNAIRE_HIDDEN_OPTIONS.push("AjiuetUcuy5D");

    // always filter out amazon option
    const amazonEnabled = Boolean(
      featureFlags.getRemoteFlag("AMAZON_WEIGHTLOSS" as FeatureResponse.feature)
    );

    if (!amazonEnabled) {
      EMPLOYER_QUESTIONNAIRE_HIDDEN_OPTIONS.push("ALXY6RYKBVTr");
    }

    if (
      field.properties?.choices &&
      field.id === EMPLOYER_QUESTIONNAIRE_FIELD_ID
    ) {
      field.properties.choices = field.properties.choices.filter((choice) => {
        return !EMPLOYER_QUESTIONNAIRE_HIDDEN_OPTIONS.includes(choice.id);
      });
    }

    return field;
  };

  /**
   * out variables that should not be used.
   */
  readonly updateVariablesForSession = (
    variables: CachedObject
  ): CachedObject => {
    const filteredVariables: CachedObject = { ...variables };

    if (this.forceRepeat) {
      filteredVariables.selected_diagnosis = "N/A";
    }

    return filteredVariables;
  };

  readonly loadCustomFormData = async (): Promise<void> => {
    loadingState.start(LoadingKey.questionnaire);
    const id = this.state.formId;
    try {
      this.setSessionStorage();
      const data = await this.getQuestionnaireData(String(id));
      if (!data.fields) return;
      const { welcome_screens = [], fields = [] } = data;
      const welcomeScreens = (welcome_screens as QuestionnaireField[]).map(
        (field) => {
          return {
            ...field,
            type: QuestionnaireType.WELCOME
          };
        }
      );
      const combinedFields = [
        ...welcomeScreens,
        ...(fields as QuestionnaireField[])
      ];

      const unitedFields = combinedFields
        .map(QuestionnaireStepCubit.prepareFieldData)
        .map(this.updateFieldMetadata);
      const endScreens = (data.thankyou_screens as QuestionnaireField[]).map(
        QuestionnaireStepCubit.prepareFieldData
      );
      const logic = (data.logic ?? []) as QuestionnaireLogic[];

      let hiddenFields: CachedObject = {
        funnel_link: "not_set"
      };

      const { hidden = [] } = data;
      for (const key of hidden as string[]) {
        hiddenFields[key] = undefined;
      }

      const [variables] = await Promise.all([
        this.loadVariableData(data.variables as CachedObject),
        this.loadAnsweredQuestionnaireData(unitedFields),
        this.collectHiddenFieldData(hiddenFields),
        this.prefillUserData(unitedFields),
        this.startSession()
      ]);

      // merge variables and hidden_fields
      const allFields = { ...hiddenFields, ...variables };
      for (const key in allFields) {
        const value = allFields[key];
        if (typeof value === "undefined" || value === "") {
          allFields[key] = VALUE_NOT_AVAILABLE;
        }
      }

      try {
        const initialVarsOverride = JSON.parse(
          sessionStorage.getItem("vars") ?? "{}"
        ) as Record<string, string>;
        for (const key in initialVarsOverride) {
          allFields[key] = initialVarsOverride[key];
        }
      } catch (e: unknown) {
        // eslint-disable-next-line no-console
        console.error(e);
      }

      const updatedVariables = this.updateVariablesForSession(allFields);

      this.customFormVariables = updatedVariables;
      hiddenFields = updatedVariables;

      const globalConfigs = this.parseGlobalConfigs(unitedFields);

      this.collectLogicSteps();

      if (globalConfigs.disableDataPrefill) {
        // clear all data if data prefill is disabled
        this.customFormData = {};
      }

      this.emit({
        ...this.state,
        formMeta: data,
        fields: unitedFields,
        logic,
        formId: String(this.state.formId),
        hiddenFields,
        endScreens,
        customFormData: this.customFormData,
        globalConfigs
      });
      this.lastUpdated = Date.now();

      this.populateCalculatedVariables();
      this.insertDefaultValues();

      this.collectLogicSteps();
      this.activeField = this.findFirstFieldStep();
      this.setSessionStorage(this.activeField);
      this.emit({
        ...this.state,
        activeField: this.activeField
      });
    } catch (e: unknown) {
      reportErrorSentry(e);

      const apiError = e as ApiError;
      this.emit({
        ...this.state,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        error: `${apiError.body?.code ?? 0}`
      });
    }
    loadingState.finish(LoadingKey.questionnaire);
  };

  findFirstFieldStep = (): QuestionnaireField | undefined => {
    let first: QuestionnaireField | undefined;
    this.dryRun = true;

    // find first step by looking through the hidden fields logic, if any jump action is triggered here, we found the first step
    const hiddenLogic = this.state.logic.find((item) => item.type === "hidden");
    for (const action of hiddenLogic?.actions ?? []) {
      const actionResult = this.runLogicActions(action);
      if (actionResult.jumpDetails) {
        first = this.getFieldByRef(actionResult.jumpDetails.to.value);
        break;
      }
    }

    // if no jump action is triggered, find the first step by index
    if (!first) {
      for (const field of this.state.fields) {
        first = field;
        break;
      }
    }

    // if first field is welcome screen, find the next field
    if (first?.type === QuestionnaireType.WELCOME) {
      first = this.getNextField(first);
    }

    this.dryRun = false;
    return first;
  };

  parseGlobalConfigs = (fields: QuestionnaireField[]): GlobalConfigs => {
    const globalConfigs: GlobalConfigs = {};

    for (const field of fields) {
      if (field.properties?.global_disable_data_prefill) {
        globalConfigs.disableDataPrefill = true;
      }
    }

    return globalConfigs;
  };

  readonly insertDefaultValues = (): void => {
    const fieldsWithDefaultValue = this.state.fields.filter(
      (field) => field.properties?.default_value
    );

    for (const field of fieldsWithDefaultValue) {
      const currentValue = this.customFormData[field.id];
      if (currentValue) continue;

      const defaultValue = field.properties?.default_value ?? "";
      const value = this.replacePlaceholders(defaultValue);
      if (value === VALUE_NOT_AVAILABLE) continue;
      this.customFormData[field.id] = value;
    }

    this.emit({
      ...this.state,
      customFormData: this.customFormData
    });
  };

  readonly loadLabValues = async (): Promise<KeyValueList> => {
    const values: KeyValueList = [];
    if (userState.isTempUser) return values;

    try {
      const data = await UserLifelineItemControllerService.getLifelineItems([
        "hl7_fhir_r4_lab_value"
      ]);

      const allObservations: CustomObservation[] = data.data.map(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        (userLifelineItem): CustomObservation => ({
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          ...userLifelineItem.deserializedPayload.observation,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
          sourceType: userLifelineItem.deserializedPayload.source?.type ?? "",
          id: userLifelineItem.id
        })
      );

      const diagnosticObservations = Fhir.filterObservationsBySource(
        allObservations,
        [
          FhirObservationType.diagnostics,
          FhirObservationType.bioReferenceLabs,
          FhirObservationType.api
        ]
      );
      const observations = Fhir.filterUniqueObservationsByCode(
        diagnosticObservations
      );

      for (const observation of observations) {
        if (
          Fhir.getLoincCoding(observation.code.coding)?.code ===
          LoincCodingCode.hba1c
        ) {
          const value = observation.valueQuantity?.value;
          const date =
            observation.effectiveDateTime &&
            Fhir.getObservationDate(observation).format(DateFormats.ISO_FULL);

          if (value) values.push(["lab_a1c_value", value]);
          if (date) values.push(["lab_a1c_date", date]);
        }
      }
    } catch (e: unknown) {
      reportErrorSentry(e);
    }

    return values;
  };

  readonly loadLabOrderProviders = async (): Promise<KeyValueList> => {
    const values: KeyValueList = [];
    if (userState.isTempUser) return values;

    try {
      const response =
        await LabOrderControllerService.availableLabOrderProviders();
      const labProviders = response.data.labOrderProviders as string[];

      values.push(["lab_order_providers", labProviders.join(";")]);
      const homePhlebotomyAvailable =
        UserPreferencesCubit.isHomePhlebotomySupported(labProviders);
      values.push([
        "home_phlebotomy_available",
        homePhlebotomyAvailable ? "yes" : "no"
      ]);
    } catch (e: unknown) {
      reportErrorSentry(e);
    }

    return values;
  };

  checkChoiceKeyMapContainsId = (
    choiceKeyMap: Record<string, string[]>,
    id: string
  ): string | undefined => {
    for (const key in choiceKeyMap) {
      if (choiceKeyMap[key].includes(id)) return key;
    }
    return undefined;
  };

  loadUserFunnelLink = async (): Promise<KeyValueList> => {
    let link = userPreferences.lastSignupFunnel ?? "";

    // check url for current funnel key
    const match = /signup\/([a-z-]+)\//.exec(location.href);
    if (match) {
      // eslint-disable-next-line @typescript-eslint/prefer-destructuring
      link = match[1];
    }

    link = link.toLowerCase();
    link = link.replace(/ /g, "_");

    return [["funnel_link", link]];
  };

  loadFirstName = async (): Promise<KeyValueList> => {
    const firstName = userPreferences.state[UserPreferenceKeys.userFirstName];
    return [["first_name", firstName ?? ""]];
  };

  loadIsCashPay = async (): Promise<KeyValueList> => {
    const { cashPay } = subscriptionState.activeSubscriptionDetails;
    return [["cash_pay", cashPay ? "true" : "false"]];
  };

  loadSelectedValuesOnly = async (
    fn: () => Promise<KeyValueList>
  ): Promise<string[]> => {
    const response = await fn();
    const values = response.map(([, value]) => value);
    return String(values[0]).split(";");
  };

  readonly collectHiddenFieldData = async (
    fields: CachedObject
  ): Promise<void> => {
    const keys = Object.keys(fields);
    const promises: Promise<KeyValueList>[] = [];

    const loadLabs =
      keys.includes("lab_a1c_value") || keys.includes("lab_a1c_date");

    if (loadLabs) {
      promises.push(this.loadLabValues());
    }

    const labOrderProviders =
      keys.includes("lab_order_providers") ||
      keys.includes("home_phlebotomy_available");
    if (labOrderProviders) {
      promises.push(this.loadLabOrderProviders());
    }

    const funnelLink = keys.includes("funnel_link");
    if (funnelLink) {
      promises.push(this.loadUserFunnelLink());
    }

    const firstName = keys.includes("first_name");
    if (firstName) {
      promises.push(this.loadFirstName());
    }

    const isCashPay = keys.includes("cash_pay");
    if (isCashPay) {
      promises.push(this.loadIsCashPay());
    }

    const data = await Promise.all(promises);
    const flatList = data.flat(1);

    for (const [k, v] of flatList) {
      fields[k] = v;
    }
  };

  userHeightField: QuestionnaireField | undefined = undefined;

  get userHeight(): number {
    const heightField =
      this.userHeightField ??
      this.state.fields.find((field) => field.properties?.height_field);
    if (!this.userHeightField) this.userHeightField = heightField;

    return heightField
      ? Number(this.parseAnswerOnlyValue(heightField).value ?? 0)
      : 0;
  }

  userWeightFields: Record<string, QuestionnaireField | undefined> = {};

  getUserWeight(weightFieldKey: string): number {
    let weightField = this.userWeightFields[weightFieldKey];
    if (!weightField) {
      this.userWeightFields[weightFieldKey] = this.state.fields.find(
        (field) => {
          const { weight_field_keyword = "", weight_field = false } =
            field.properties ?? {};
          if (weight_field_keyword && weightFieldKey === weight_field_keyword) {
            return true;
          }
          if (weightFieldKey === "" && weight_field) {
            return true;
          }
          return false;
        }
      );
      weightField = this.userWeightFields[weightFieldKey];
    }
    return weightField
      ? Number(this.parseAnswerOnlyValue(weightField).value ?? 0)
      : 0;
  }

  readonly populateCalculatedVariables = (): void => {
    const alwaysCalculate = [["prefilled_bmi"]];

    this.state.fields
      .filter((field) => field.properties?.weight_field_keyword)
      .forEach((field) => {
        if (!field.properties?.weight_field_keyword) {
          return;
        }
        const customKey = field.properties.weight_field_keyword;
        const varName = `prefilled_${customKey}_bmi`;
        const bmi =
          this.getUserWeight(customKey) / Math.pow(this.userHeight / 100, 2);
        this.customFormVariables[varName] = bmi;
      });

    for (const [key] of [
      ...alwaysCalculate,
      ...Object.entries(this.customFormVariables)
    ]) {
      const bmi = this.getUserWeight("") / Math.pow(this.userHeight / 100, 2);
      switch (key) {
        // bmi from height and weight
        case "prefilled_bmi":
          this.customFormVariables[key] = isNaN(bmi)
            ? VALUE_NOT_AVAILABLE
            : bmi;
          break;
      }
    }
  };

  readonly loadVariableData = async (
    variables: CachedObject = {}
  ): Promise<CachedObject> => {
    const result: AnyObject = {};

    const preferences = await userPreferences.loadUserPreferences();

    for (const [key, value] of Object.entries(variables)) {
      let parsedValue = value;

      try {
        if (key === "prefilled_age") {
          const dob = preferences?.[UserPreferenceKeys.userDateOfBirth];
          if (dateLocal(dob).isValid()) {
            parsedValue = dateLocal().diff(dateLocal(dob), "years");
          }
        }
      } catch (error: unknown) {
        reportErrorSentry(error);
      }

      result[key] = parsedValue;
    }
    return result;
  };

  readonly isFinishStep = (field?: QuestionnaireField): boolean => {
    if (!field) {
      return false;
    }

    // statement screens with the button text "Finish" or "Go Back" are considered to be the end of the questionnaire
    const isFinishStatement =
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      field.properties?.ending ||
      (field.type === QuestionnaireType.STATEMENT &&
        (field.properties?.button_text === "Finish" ||
          field.properties?.button_text === "Go Back"));

    // Thank you pages are always the end of the questionnaire
    const isThankYou = field.type === QuestionnaireType.THANK_YOU;

    // check if any of the above is true
    return [isFinishStatement, isThankYou].some(Boolean);
  };

  readonly isFirstStep = (field?: QuestionnaireField): boolean => {
    const firstStep = this.findFirstFieldStep();
    return firstStep ? firstStep.id === field?.id : false;
  };

  readonly runValidations = (field?: QuestionnaireField): TranslationKey[] => {
    if (!field) return [];

    const fieldValue = this.parseAnswerOnlyValue(field.id);
    const fieldRequired = field.validations?.required;
    const { value } = fieldValue;

    if ((value === undefined || value === "") && fieldRequired)
      return ["error_validation_required"];

    if (this.isFinishStep(field)) return [];

    const validationKey = field.properties?.validation_key;

    if (!validationKey) return [];
    const validation = questionnaireFieldValidation[validationKey] as
      | RegExp
      | undefined;

    if (!validation) return [];

    if (value === undefined || value === "") return [];

    const matchesRegex = validation.exec(String(value));

    if (matchesRegex === null) {
      return [`error_validation_${validationKey}`];
    }

    return [];
  };

  readonly getFieldByRef = (ref: string): QuestionnaireField | undefined => {
    const found = this.state.fields.find(
      (item) => item.ref === ref || item.id === ref
    );
    if (found) {
      return found;
    }
  };

  static mapQuestionTypeToAnswerType = (
    qt: QuestionnaireType,
    singleSelect = false,
    medicationField = false
  ): CustomQuestionnaireAnswerType | string => {
    switch (qt) {
      case QuestionnaireType.SHORT_TEXT:
        return medicationField
          ? QuestionnaireStepTypeOutput.MEDICATION
          : QuestionnaireStepTypeOutput.TEXT;
      case QuestionnaireType.LONG_TEXT:
      case QuestionnaireType.EMAIL:
      case QuestionnaireType.DATE:
      case QuestionnaireType.ZIP_CODE:
      case QuestionnaireType.PHONE_NUMBER:
        return QuestionnaireStepTypeOutput.TEXT;
      case QuestionnaireType.DROPDOWN:
        return QuestionnaireStepTypeOutput.CHOICE;
      case QuestionnaireType.MULTIPLE_CHOICE:
        return singleSelect
          ? QuestionnaireStepTypeOutput.CHOICE
          : QuestionnaireStepTypeOutput.MULTIPLE_CHOICE;
      case QuestionnaireType.NUMBER:
      case QuestionnaireType.OPINION_SCALE:
        return QuestionnaireStepTypeOutput.INTEGER;
      case QuestionnaireType.YES_NO:
        return QuestionnaireStepTypeOutput.BOOLEAN;
      case QuestionnaireType.MEDICATION:
        return QuestionnaireStepTypeOutput.MEDICATION;
      case QuestionnaireType.MULTIPLE_TEXT:
        return QuestionnaireStepTypeOutput.MULTIPLE_TEXT;
      default:
        reportErrorSentry(
          `[mapQuestionTypeToAnswerType] Unknown questionnaire type: ${qt}`
        );
        return qt;
    }
  };

  get cacheKey(): string {
    if (this.state.formId) {
      return `${CACHE_KEY_PREFIX}${this.state.formId}${this.instanceId}`;
    }

    return `${CACHE_KEY_PREFIX}-${this.instanceId}`;
  }

  readonly insertCachedValues = (): void => {
    const values = this.getCachedValues();
    this.customFormData = values;
    this.collectLogicSteps();
    this.emit({
      ...this.state,
      customFormData: this.customFormData
    });
    this.lastUpdated = Date.now();
    this.collectLogicSteps();

    this.sendDataToParent();
  };

  readonly getCachedValues = (): CachedObject =>
    StorageController.activeUserId
      ? (JSON.parse(StorageController.getItem(this.cacheKey) ?? "{}") as Record<
          string,
          boolean | string
        >)
      : {};

  readonly saveValue = (
    key: string,
    value: QuestionnaireValue,
    saveOptions: {
      triggerAutoContinue?: boolean;
    } = {}
  ): void => {
    const { triggerAutoContinue = true } = saveOptions;
    // skip if value matches exact
    if (this.customFormData[key] === value) return;

    const customFormDataClone = { ...this.customFormData };

    const oldValue = customFormDataClone[key];
    const newValue = value;
    const hasChanged = oldValue !== newValue;
    let oldValueDefined = typeof oldValue !== "undefined";
    if (Array.isArray(oldValue)) {
      oldValueDefined = oldValue.length > 0;
    }

    if (hasChanged) {
      customFormDataClone[key] = value;
      if (Array.isArray(oldValue)) {
        for (const item of oldValue) {
          customFormDataClone[item] = false;
        }
      }
      if (Array.isArray(value)) {
        for (const item of value) {
          if (item) customFormDataClone[item] = true;
        }
      }

      this.collectLogicSteps();
      this.lastUpdated = Date.now();

      if (Array.isArray(oldValue)) {
        for (const k of oldValue) {
          customFormDataClone[k] = false;
        }
      }
      if (Array.isArray(newValue)) {
        for (const k of newValue) {
          customFormDataClone[k] = true;
        }
      }

      const allData = this.getCachedValues();
      allData[key] = value === false ? undefined : value;
      StorageController.setItem(this.cacheKey, JSON.stringify(allData));

      this.customFormData = customFormDataClone;
      this.emit({
        ...this.state,
        customFormData: customFormDataClone
      });

      // check if key has a separator "."
      const keyParts = key.split(".");
      if (keyParts.length > 1) {
        const [parentKey, localKey] = keyParts;
        const objectRepresentation = customFormDataClone[parentKey] ?? {};

        // update "object value" representation if the current key is with a separator
        if (objectRepresentation && typeof objectRepresentation === "object") {
          const valueClone = { ...objectRepresentation } as AnyObject;
          valueClone[localKey] = value;
          this.saveValue(parentKey, { ...valueClone });
        }
      }
      this.sendDataToParent();
    }

    this.collectLogicSteps();

    if (triggerAutoContinue && hasChanged && !oldValueDefined) {
      this.triggerAutoContinue("after-change");
    }
  };

  submittingField: QuestionnaireField | undefined = undefined;
  readonly handleSubmit = (field: QuestionnaireField): void => {
    if (this.dryRun || this.submittingField === field) return;
    const isFinishStep = this.isFinishStep(field);

    this.submittingField = field;
    this.populateCalculatedVariables();
    this.runLogicForField(field);
    const jumpTo = this.getNextFieldOrLogicJump(field);
    const lastLogicStep =
      this.state.logicSteps[this.state.logicSteps.length - 1];
    const isLastStepInLogic = lastLogicStep === field.ref;
    const isEndOfQuetsionnaire = isLastStepInLogic && !jumpTo;

    if (
      (isFinishStep || isEndOfQuetsionnaire) &&
      this.state.questionnaireCompleted
    ) {
      this.onLastStepCompleted?.();
    }

    if (!this.state.questionnaireCompleted) {
      if (isEndOfQuetsionnaire) {
        loadingState.start(LoadingKey.questionnaire);
      }
      void this.addAnswerToSession(field.id)
        .then(() => {
          this.submittingField = undefined;
        })
        .then(() => {
          if (isEndOfQuetsionnaire) {
            void this.onComplete().finally(() => {
              loadingState.finish(LoadingKey.questionnaire);
              this.onLastStepCompleted?.();
            });
          }
        });
    }
  };

  readonly triggerAutoContinue = (type: "after-change" | "initil"): void => {
    const { activeField } = this.state;
    if (!activeField) return;

    const options: QuestionnaireParsedSelectChoice[] = parseChoice(
      activeField.properties?.choices
    );

    const isStandaloneView = window.location.pathname.includes("/standalone/");
    const eligibleOptions = options.filter((option) =>
      Boolean(option.autoselectIfKey)
    );

    const autoselectKeysEnabled = eligibleOptions.filter((option) => {
      const key = option.autoselectIfKey;
      return key && this.checkIfKeyExists(key);
    });

    if (type === "initil") {
      // continue if its the only option, and auto-continue is enabled
      if (autoselectKeysEnabled.length === 1 && !isStandaloneView) {
        const [firstOption] = autoselectKeysEnabled;
        this.saveValue(activeField.id, [firstOption.id]);
        this.handleSubmit(activeField);
      }
    }

    if (type === "after-change") {
      const canSelectMultiple = Boolean(
        activeField.properties?.allow_multiple_selection
      );
      const value = this.getValue(activeField.id);
      const hasSelectedValue = Array.isArray(value) ? value.length > 0 : value;
      // continue if the field is single choice and user selected value
      if (
        !canSelectMultiple &&
        !isStandaloneView &&
        hasSelectedValue &&
        (activeField.type === QuestionnaireType.MULTIPLE_CHOICE ||
          activeField.type === QuestionnaireType.YES_NO)
      ) {
        setTimeout(() => {
          this.handleSubmit(activeField);
        }, 200);
      }
    }
  };

  readonly getValue = (key: string): QuestionnaireValue[] | undefined => {
    const value = this.customFormData[key];
    if (
      typeof value === "string" ||
      typeof value === "boolean" ||
      typeof value === "number" ||
      value instanceof Object
    ) {
      return [value];
    }
    return value;
  };

  readonly parseAnswer = (
    keyOrField: QuestionnaireField | string
  ): CustomQuestionnaireAnswer | undefined => {
    const field =
      typeof keyOrField === "string"
        ? this.getFieldByRef(keyOrField)
        : keyOrField;
    if (!field) return;

    const value = this.customFormData[field.id];

    let fieldValue:
      | CustomQuestionnaireAnswerValue
      | MedicalInputData
      | string[]
      | undefined = value;
    if (fieldValue === "" || fieldValue === undefined) return;

    const singleSelect = field.properties?.allow_multiple_selection === false;
    const medicationField = field.properties?.medication_field === true;

    const fieldType = QuestionnaireCubit.mapQuestionTypeToAnswerType(
      field.type,
      singleSelect,
      medicationField
    );

    // parse each field depending on its type
    switch (field.type) {
      case QuestionnaireType.MULTIPLE_TEXT:
        fieldValue = QuestionnaireCubit.parseFieldValueMultipleText(
          field,
          this.customFormData
        );
        break;
      case QuestionnaireType.MEDICATION:
        fieldValue = QuestionnaireCubit.parseFieldValueMedication(
          field,
          this.customFormData
        );
        break;
      case QuestionnaireType.DROPDOWN:
        fieldValue = QuestionnaireCubit.parseFieldValueDropdown(
          field,
          this.customFormData
        );
        break;
      case QuestionnaireType.MULTIPLE_CHOICE:
        fieldValue = QuestionnaireCubit.parseFieldValueMultipleChoice(
          field,
          this.customFormData
        );
        if (singleSelect) {
          // adding possible type undefined because TS assumes that the index 0 is always defined
          fieldValue = fieldValue[0] as CustomQuestionnaireChoice | undefined;
        }
        break;
      case QuestionnaireType.NUMBER:
      case QuestionnaireType.OPINION_SCALE:
        fieldValue = QuestionnaireCubit.parseFieldValueNumber(
          field,
          this.customFormData
        );
        break;

      case QuestionnaireType.YES_NO:
        fieldValue = QuestionnaireCubit.parseFieldValueBoolean(
          field,
          this.customFormData
        );
        break;
      default:
        break;
    }

    if (
      fieldValue === undefined ||
      fieldValue === VALUE_NOT_AVAILABLE ||
      (typeof fieldValue === "number" && isNaN(fieldValue))
    ) {
      return;
    }

    return {
      questionId: field.id,
      fieldType,
      fieldValue: fieldValue as CustomQuestionnaireAnswerValue
    };
  };

  readonly parseAnswerOnlyValue = (
    keyOrField: QuestionnaireField | string,
    options: { joinListBy?: string } = {}
  ): {
    value: QuestionnaireAnswerValue;
    multiple: boolean;
  } => {
    const { joinListBy = "\n" } = options;
    const fullParsedAnswer = this.parseAnswer(keyOrField);

    const result: {
      value: QuestionnaireAnswerValue;
      multiple: boolean;
    } = {
      value: undefined,
      multiple: false
    };

    if (!fullParsedAnswer) {
      // check overwrides for possible value
      if (typeof keyOrField === "string") {
        const field = this.getFieldByRef(keyOrField);
        if (field) {
          const prefilledValue = this.answerOverrides.find(
            (f) => f.questionId === field.id
          );
          if (prefilledValue) {
            const fieldValue =
              prefilledValue.fieldValue as QuestionnaireAnswerValue;
            const fieldValueString =
              typeof fieldValue === "object" ? fieldValue.value : fieldValue;
            result.value = fieldValueString;
          }
        }
      }
      return result;
    }

    if (typeof fullParsedAnswer.fieldValue === "string") {
      const properDelimiter = fullParsedAnswer.fieldValue.replace(",", ".");
      const toNumber = parseFloat(properDelimiter);

      // if the value looks like a number, return it as number
      if (`${toNumber}` === properDelimiter) {
        result.value = toNumber;
        return result;
      }

      result.value = properDelimiter;
      return result;
    }

    if (
      typeof fullParsedAnswer.fieldValue === "object" &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, no-prototype-builtins
      Object(fullParsedAnswer.fieldValue).hasOwnProperty("value")
    ) {
      result.value = (
        fullParsedAnswer.fieldValue as CustomQuestionnaireChoice
      ).value;
      return result;
    }

    if (Array.isArray(fullParsedAnswer.fieldValue)) {
      result.value = fullParsedAnswer.fieldValue
        .map((v) => v.value)
        .join(joinListBy);
      result.multiple = true;
      return result;
    }

    if (
      typeof fullParsedAnswer.fieldValue === "number" ||
      typeof fullParsedAnswer.fieldValue === "boolean"
    ) {
      result.value = fullParsedAnswer.fieldValue;
      return result;
    }

    if (
      fullParsedAnswer.fieldValue instanceof Object &&
      !("choiceId" in fullParsedAnswer.fieldValue)
    ) {
      result.value = fullParsedAnswer.fieldValue;
      return result;
    }

    reportErrorSentry(
      `Unknown field value type ${typeof fullParsedAnswer.fieldValue}`
    );
    return result;
  };

  static parseFieldValueMultipleText = (
    field: QuestionnaireField,
    formData: CachedObject
  ): MultipleTextInputData => {
    return (formData[field.id] ?? {}) as MultipleTextInputData;
  };

  static parseFieldValueMedication = (
    field: QuestionnaireField,
    formData: CachedObject
  ): MedicalInputData => {
    const value = (formData[field.id] ?? {}) as MedicalInputData;

    // replace undefined values with zero number to have all keys in answer value
    // keys are terms from the HL7 EventTiming code system
    // https://www.hl7.org/fhir/codesystem-event-timing.html#4.3.14.178.2
    // setValue(name, mapValues(values, parseValue));
    // loop over TimeCode
    const startObject = {} as Record<TimeCode, number>;
    const result = Object.keys(TimeCode).reduce<typeof startObject>(
      (acc, key) => {
        const code = key as TimeCode;
        const valid = Object.values(TimeCode).includes(code);
        if (!valid) return acc;
        const timeCodeValue = value[code] ?? 0;
        return {
          ...acc,
          [key]: parseFloat(`${timeCodeValue}`)
        };
      },
      startObject
    );
    return result;
  };

  static parseFieldValueDropdown = (
    field: QuestionnaireField,
    formData: CachedObject
  ): CustomQuestionnaireChoice => {
    const fieldChoices = field.properties?.choices ?? [];
    const fieldValue = fieldChoices.find((choice) => formData[choice.id]);

    if (!fieldValue) {
      return { choiceId: "", value: "" };
    }

    const value: CustomQuestionnaireChoice = {
      choiceId: fieldValue.id,
      value: fieldValue.label
    };

    return value;
  };

  static parseFieldValueMultipleChoice = (
    field: QuestionnaireField,
    formData: CachedObject
  ): CustomQuestionnaireChoice[] => {
    const fieldChoices = field.properties?.choices ?? [];
    return fieldChoices
      .map((choice) => {
        const selected = formData[choice.id];
        const value = selected
          ? ({
              choiceId: choice.id,
              value: choice.label
            } as CustomQuestionnaireChoice)
          : false;

        // remove comments from option
        if (value && typeof value.value === "string") {
          value.value = removeQuestionnaireKeywordsFromText(value.value, [
            CustomFieldPropertyRegex.SET,
            CustomFieldPropertyRegex.GROUP_COMMENT,
            CustomFieldPropertyRegex.CLEAR_OTHER,
            CustomFieldPropertyRegex.AFFIX_SUFFIX
          ]);
        }
        return value;
      })
      .filter(Boolean);
  };

  static parseFieldValueNumber = (
    field: QuestionnaireField,
    formData: CachedObject
  ): number => {
    return parseFloat(String(formData[field.id]));
  };

  static parseFieldValueBoolean = (
    field: QuestionnaireField,
    formData: CachedObject
  ): boolean => {
    const value = formData[field.id];

    if (QuestionnaireType.YES_NO === field.type) {
      return String(value).toLowerCase() === "yes";
    }

    return false;
  };

  readonly replacePlaceholders = (
    inputText?: string,
    joinListBy?: string
  ): string | undefined => {
    if (!inputText) return undefined;
    const { removeFieldPropertiesKeywords } = QuestionnaireStepCubit;
    const varValues = this.customFormVariables;
    const hiddenValues = this.state.hiddenFields;
    let text = inputText;
    const varRegex = /\{\{(hidden|var):(.*?)\}\}/g;
    text = text.replace(varRegex, (_match, _type, key: string): string => {
      const unescaped = key.replace(/\\/g, "");
      const value = varValues[unescaped] ?? hiddenValues[unescaped];

      // TODO: Consider how to handle in more proper way for object value
      if (value instanceof Object) return "";

      return value ? removeFieldPropertiesKeywords(`${value}`) : "";
    });

    const fieldRegex = /\{\{(field):(.*?)\}\}/g;
    text = text.replace(fieldRegex, (_match, _type, ref: string): string => {
      const unescaped = ref.replace(/\\/g, "");
      const { value } = this.parseAnswerOnlyValue(unescaped, { joinListBy });

      // TODO: Consider how to handle in more proper way for object value
      if (value instanceof Object) return "";

      return removeFieldPropertiesKeywords(`${value ?? ""}`);
    });

    return text;
  };

  readonly insertPlaceholders = (
    field: QuestionnaireField
  ): QuestionnaireField => {
    if (field.originalTitle) {
      field.title = this.replacePlaceholders(field.originalTitle, ", ") ?? "";
    }

    if (field.properties) {
      // description
      field.properties.description = this.replacePlaceholders(
        field.properties.originalDescription
      );

      // button text
      field.properties.button_text = this.replacePlaceholders(
        field.properties.button_text
      );

      // button link
      field.properties.button_link = this.replacePlaceholders(
        field.properties.button_link
      );
    }

    return QuestionnaireStepCubit.parseField(field);
  };

  /***************************************************************************
   *
   * Questionnaire Logic
   *
   ***************************************************************************/
  readonly resolveLogicInnerVars = (
    vars: QuestionnaireLogicConditionVarInner[],
    fields: QuestionnaireField[]
  ): QuestionnaireLogicConditionVarInnerResolved => {
    let field: QuestionnaireField | undefined;
    let hiddenFieldValue: QuestionnaireValue | undefined;
    let choice: QuestionnaireSelectChoice | undefined;
    const comparandValue: QuestionnaireValue[] = [];
    const subjectValue: QuestionnaireValue[] = [];
    let match = false;

    for (const v of vars) {
      // if type field, we get the field that matches the value
      if (v.type === "field") {
        field = fields.find((f) => f.ref === v.value);
      }

      if (v.type === "hidden") {
        hiddenFieldValue =
          this.customFormVariables[String(v.value)] ??
          this.state.hiddenFields[String(v.value)];
        comparandValue.push(hiddenFieldValue);
      }
    }

    for (const v of vars) {
      switch (v.type) {
        case "choice":
          choice = field?.properties?.choices?.find(
            (c) => c.ref === v.value || c.id === v.value
          );
          if (choice) {
            subjectValue.push(choice.id);
          }

          if (field) {
            field.properties?.choices?.forEach((c) => {
              const value = this.getValue(c.id)?.[0];
              if (value && c.id) {
                comparandValue.push(c.id);
              }
            });
          }

          match = Boolean(comparandValue.includes(subjectValue[0]));
          break;
        case "field":
        case "hidden":
          // these cases is handled above and can be ignored here
          break;
        case "variable":
          comparandValue.push(this.customFormVariables[String(v.value)]);
          match = Boolean(subjectValue.includes(comparandValue[0]));
          break;
        case "constant":
          if (field) {
            this.getValue(field.id)?.forEach((value) => {
              if (String(value).toLowerCase() === "yes") {
                comparandValue.push(true);
              } else if (String(value).toLowerCase() === "no") {
                comparandValue.push(false);
              } else {
                comparandValue.push(value);
              }
            });
          }

          match = Boolean(comparandValue.includes(v.value));

          subjectValue.push(v.value);
          break;
      }
    }

    return {
      field,
      comparandValue,
      match,
      subjectValue
    };
  };

  readonly evaluateLogicConditionVar = (
    check: QuestionnaireLogicCondition,
    fields: QuestionnaireField[]
  ): boolean => {
    let pass = true;
    // eslint-disable-next-line no-prototype-builtins
    const isFlatCondition = !check.vars.some((c) => c.hasOwnProperty("op"));

    if (isFlatCondition) {
      const { vars } = check;
      const { match, subjectValue, comparandValue } =
        this.resolveLogicInnerVars(
          vars as QuestionnaireLogicConditionVarInner[],
          fields
        );

      switch (check.op) {
        case "is_not":
        case "not_equal":
          pass = !match;
          break;
        case "is":
        case "equal":
          pass = match;
          break;
        case "always":
          pass = true;
          break;
        case "begins_with":
          // Assumes single values for the comparand and the subject
          pass = String(comparandValue[0]).startsWith(String(subjectValue[0]));
          break;
        case "contains":
          pass = String(comparandValue[0]).includes(String(subjectValue[0]));
          break;
        case "not_contains":
          pass = !String(comparandValue[0]).includes(String(subjectValue[0]));
          break;
        case "lower_than":
          // Assumes single values for the comparand and the subject
          pass = Number(subjectValue[0]) > Number(comparandValue[0]);
          break;
        case "lower_equal_than":
          // Assumes single values for the comparand and the subject
          pass = Number(subjectValue[0]) >= Number(comparandValue[0]);
          break;
        case "greater_than":
          // Assumes single values for the comparand and the subject
          pass = Number(subjectValue[0]) < Number(comparandValue[0]);
          break;
        case "greater_equal_than":
          // Assumes single values for the comparand and the subject
          pass = Number(subjectValue[0]) <= Number(comparandValue[0]);
          break;
        default:
          reportErrorSentry(
            `Not implemented yet: ${check.op} case for "evaluateLogicConditionVar"`
          );
      }

      return pass;
    }

    const conditionState = this.runLogicConditions(check, fields);
    if (!this.dryRun) this.log("conditionState", { conditionState, check });
    return this.runConditionLogic(check, conditionState);
  };

  readonly runLogicConditions = (
    condition: QuestionnaireLogicCondition,
    fields: QuestionnaireField[]
  ): boolean[] => {
    // eslint-disable-next-line no-prototype-builtins
    const hasFlatCondition = condition.vars.some((c) => c.hasOwnProperty("op"));

    const conditionState: boolean[] = hasFlatCondition
      ? condition.vars.map((check) => {
          return this.evaluateLogicConditionVar(
            check as QuestionnaireLogicCondition,
            fields
          );
        })
      : [this.evaluateLogicConditionVar(condition, fields)];

    return conditionState;
  };

  readonly evaluateLogicActionConditions = (
    action: QuestionnaireLogicAction,
    fields: QuestionnaireField[]
  ): boolean => {
    const { condition } = action;
    const conditionState = this.runLogicConditions(condition, fields);
    return this.runConditionLogic(condition, conditionState);
  };

  readonly runConditionLogic = (
    condition: QuestionnaireLogicCondition,
    conditionState: boolean[]
  ): boolean => {
    let pass = false;
    switch (condition.op) {
      case "and": // and is for conditions with multiple checks
      case "is": // is is for conditions with a single check
      case "equal": // equal is a inner var condition and will be checked in runLogicConditions
      case "greater_than": // greater_than is a inner var condition and will be checked in runLogicConditions
      case "lower_than": // greater_than is a inner var condition and will be checked in runLogicConditions
      case "is_not": // can be treated same as "is", evaluateLogicConditionVar flips the result if op is "is_not"
      case "greater_equal_than": // same as "greater_than" but includes equal
      case "not_contains":
      case "contains":
      case "lower_equal_than": // same as "lower_than" but includes equal
        pass = conditionState.every(Boolean);
        break;
      case "or":
        pass = conditionState.some(Boolean);
        break;
      case "always":
        pass = true;
        break;
      default:
        reportErrorSentry(
          `Not implemented yet: ${condition.op} case for "runConditionLogic"`
        );
    }

    return pass;
  };

  readonly runLogicActions = (
    action: QuestionnaireLogicAction
  ): {
    jumpActionFound: boolean;
    jumpDetails?: QuestionnaireLogicDetailJump;
  } => {
    const { fields = [] } = this.state;
    const runAction = this.evaluateLogicActionConditions(action, fields);

    if (runAction) {
      switch (action.action) {
        case "jump":
          return {
            jumpActionFound: true,
            jumpDetails: action.details as QuestionnaireLogicDetailJump
          };

        case "set":
          this.log("set", action.details);
          this.handleActionSet(action.details as QuestionnaireLogicDetailSet);
          return { jumpActionFound: false };

        case "add":
          if (!this.dryRun) {
            this.log("add", action.details);
            this.handleActionCalc(
              action.details as QuestionnaireLogicDetailSet,
              (a, b) => a + b
            );
          }
          return { jumpActionFound: false };

        case "multiply":
          if (!this.dryRun) {
            this.log("multiply", action.details);
            this.handleActionCalc(
              action.details as QuestionnaireLogicDetailSet,
              (a, b) => a * b
            );
          }
          return { jumpActionFound: false };

        case "divide":
          if (!this.dryRun) {
            this.log("divide", action.details);
            this.handleActionCalc(
              action.details as QuestionnaireLogicDetailSet,
              (a, b) => a / b
            );
          }

          return { jumpActionFound: false };

        default:
          reportErrorSentry(
            `Unknown action ${String(action.action)} in "runLogicForField"`
          );
      }
    }
    return { jumpActionFound: false };
  };

  readonly getNextFieldOrLogicJump = (
    field: QuestionnaireField
  ): QuestionnaireField | undefined => {
    const fieldRef = field.ref;
    const { logic = [] } = this.state;
    let jumpTo: QuestionnaireLogicDetailJump | undefined;

    const fieldLogic = logic.find((l) => l.ref === fieldRef);

    if (!this.dryRun) {
      this.log("getNextFieldOrLogicJump", field.title, {
        fieldRef,
        fieldLogic
      });
    }
    if (fieldLogic) {
      for (const action of fieldLogic.actions) {
        const result = this.runLogicActions(action);
        if (!jumpTo && result.jumpDetails) {
          jumpTo = result.jumpDetails;
        }
      }
    }

    if (!jumpTo) {
      const nextField = this.getNextField(field);

      if (nextField) {
        this.log("getNextFieldOrLogicJump, found jump:", nextField.title, {
          nextField
        });
        jumpTo = {
          to: {
            type: "field",
            value: nextField.id
          }
        };
      }
    }

    const target = jumpTo ? this.getFieldByRef(jumpTo.to.value) : undefined;
    return target;
  };

  applyMultiSelectOptionFieldCommands = (field: QuestionnaireField): void => {
    if (field.type !== QuestionnaireType.MULTIPLE_CHOICE) return;

    const options = parseChoice(field.properties?.choices);
    let selectedOptionIds = this.customFormData[field.id] as
      | string[]
      | string
      | undefined;
    if (typeof selectedOptionIds === "string") {
      selectedOptionIds = [selectedOptionIds];
    }

    for (const option of options) {
      const selected = selectedOptionIds?.includes(option.id);

      // handle set var
      if (option.setVar?.varName) {
        const currentVarValue = this.customFormVariables[option.setVar.varName];
        if (selected) {
          // set the var if the option is selected
          this.customFormVariables[option.setVar.varName] =
            option.setVar.varValue;
        } else if (currentVarValue === option.setVar.varValue) {
          // reset the var if the option is not selected, and the var is set to the value of the option
          this.customFormVariables[option.setVar.varName] = "N/A";
        }
      }
    }
  };

  updateComputedVariables = (field: QuestionnaireField): void => {
    if (!this.dryRun) {
      this.log("updateComputedVariables", field);
    }

    const availableConfigs: ComputedVariablesConfig[] = [
      {
        fieldRef: "7e7edcfc-938a-4d98-a276-ac9822d06c3b", // test questionnaire for computed variables: FUj4ArEO
        variableName: "varname",
        idToVarValueMap: {
          s5AKuRcqT1br: "option1",
          k5L84vWrdBIt: "option2",
          Of58W3ew3oKo: "option3"
        },
        additionalParse: (varValue: Set<string>, selectedIds) => {
          if (
            selectedIds.has("s5AKuRcqT1br") &&
            selectedIds.has("Of58W3ew3oKo")
          ) {
            varValue.add("extra option");
          }
          return varValue;
        }
      },
      {
        fieldRef: "e3d620f5-838b-4219-8c1e-3dbf42a3b319", // medical questionnaire diagnosed conditions question
        variableName: "selected_diagnosis",
        idToVarValueMap: {
          LOF130okuHlU: QuestionnaireDiagnosisOption.DIABETES,
          HVugNUDda0Kv: QuestionnaireDiagnosisOption.PREDIABETES,
          jaQO4SLgDcDa: QuestionnaireDiagnosisOption.HIGH_BLOOD_PRESSURE,
          WTjhurcqapP1: QuestionnaireDiagnosisOption.HIGH_CHOLESTEROL,
          FWI7CsL1Qc0e: QuestionnaireDiagnosisOption.FATTY_LIVER_DISEASE,
          r75q2RGID5u2: QuestionnaireDiagnosisOption.OBSTRUCTIVE_SLEEP_APNEA,
          zJunLFOFsSRa: QuestionnaireDiagnosisOption.POLYCYSTIC_OVARIES,
          FvBJc87NTl7r: QuestionnaireDiagnosisOption.NONE
        },
        additionalParse: (varValue: Set<QuestionnaireDiagnosisOption>) => {
          if (matchesAmazonEligibilityCriteria(varValue)) {
            varValue.add(
              QuestionnaireDiagnosisOption.TWO_CONDITIONS_NO_DIABETES
            );
          }

          return varValue;
        }
      },
      {
        fieldRef: "f90f6e82-e59b-4df1-bc8d-58038c743fae", // medical questionnaire goals question
        variableName: "selected_goals",
        idToVarValueMap: {
          MmvOy4abQUmx: "option1_medical_treatment",
          "6jlqzU7Yoh3n": "option2_reduce_meds",
          Te2YCpim4Weo: "option3_nutrition_good_habits",
          "2b8zygrHRGlU": "option4_weight_loss",
          I9EgRRisRQAv: "option5_sleep_better"
        }
      }
    ];

    for (const config of availableConfigs) {
      if (
        config.fieldRef === field.ref &&
        field.type === QuestionnaireType.MULTIPLE_CHOICE
      ) {
        const selectedOptionIds = this.customFormData[field.id] as
          | string[]
          | undefined;
        const selectedOptionsSet = new Set(selectedOptionIds);

        let varValues = new Set();

        for (const optionId of selectedOptionIds ?? []) {
          const varValue = config.idToVarValueMap[optionId];
          if (varValue) {
            varValues.add(varValue);
          }
        }

        if (config.additionalParse) {
          varValues = config.additionalParse(varValues, selectedOptionsSet);
        }

        const asString = Array.from(varValues).join(",") || VALUE_NOT_AVAILABLE;
        this.customFormVariables[config.variableName] = asString;
      }
    }
  };

  lastFieldLogicRun: QuestionnaireField | undefined = undefined;
  readonly runLogicForField = (field: QuestionnaireField): void => {
    if (this.lastFieldLogicRun === field) return;
    this.lastFieldLogicRun = field;
    // not needed at the moment
    // this.applyMultiSelectOptionFieldCommands(field);
    this.updateComputedVariables(field);

    if (!this.dryRun) this.log("runLogicForField", field.title, field);

    const target = this.getNextFieldOrLogicJump(field);
    if (target) {
      this.handleJump({
        to: {
          type: "field",
          value: target.id
        }
      });
    } else if (!this.dryRun) {
      void this.onComplete();
      this.onLastStepCompleted?.();
    }
  };

  readonly getNextField = (
    field: QuestionnaireField
  ): QuestionnaireField | undefined => {
    let nextIndex = 0;
    const { fields, endScreens } = this.state;
    const all = [...fields, ...endScreens];

    for (const f of all) {
      nextIndex++;
      if (f.id === field.id) {
        break;
      }
    }
    return all[nextIndex] as QuestionnaireField;
  };

  readonly handleActionCalc = (
    details: QuestionnaireLogicDetailSet,
    method: (a: number, b: number) => number
  ): void => {
    const key = details.target.value;
    const valueVar = this.customFormVariables[key];
    const currentValue = typeof valueVar === "number" ? valueVar : 0;

    const valueType = details.value.type;

    if (valueType === "field") {
      const value = this.parseAnswerOnlyValue(details.value.value);
      this.customFormVariables[key] = method(
        currentValue,
        parseFloat(String(value.value))
      );
    } else if (valueType === "constant") {
      const { value } = details.value;
      this.customFormVariables[key] = method(currentValue, parseFloat(value));
    } else if (valueType === "variable") {
      const varValue = this.customFormVariables[details.value.value];
      const value = typeof varValue === "number" ? varValue : 0;
      this.customFormVariables[key] = method(
        currentValue,
        parseFloat(`${value}`)
      );
    }
  };

  readonly handleActionSet = (details: QuestionnaireLogicDetailSet): void => {
    if (this.dryRun) return;

    const targetType = details.target.type;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (targetType === "variable") {
      const key = details.target.value;
      const { value } = details.value;
      this.customFormVariables[key] = value;
    } else {
      reportErrorSentry(`Unknown target type ${String(targetType)}`);
    }
  };

  readonly handleJump = (details: QuestionnaireLogicDetailJump): void => {
    const setTo = this.getFieldByRef(details.to.value);
    if (!setTo || this.state.activeField?.id === setTo.id) {
      return;
    }

    this.activeField = setTo;

    // reset last field logic run, so we can run logic again
    this.lastFieldLogicRun = undefined;

    if (this.dryRun) {
      return;
    }

    this.log("Jumping to", setTo.id);
    if (this.state.activeField?.id !== setTo.id) {
      this.continueToField(setTo);
    }
  };

  readonly setSessionStorage = (field?: QuestionnaireField): void => {
    if (field) {
      sessionStorage.setItem("currentStepRef", field.ref);
    }
    const saved = this.lastSaved >= this.lastUpdated;
    sessionStorage.setItem(
      "questionnaireSaved",
      saved ? this.state.formId : ""
    );
    sessionStorage.setItem(
      "questionnaireActiveFormId",
      saved ? "" : this.state.formId
    );
  };

  readonly continueToField = (field: QuestionnaireField): void => {
    const isFinish = this.isFinishStep(field);
    if (isFinish) {
      void this.onComplete();
    }

    this.setSessionStorage(field);

    this.emit({
      ...this.state,
      activeField: field
    });
  };

  readonly collectLogicSteps = (): void => {
    this.dryRun = true;
    const start = this.state.activeField;
    this.dryRunSteps = [];
    const { fields = [], endScreens = [] } = this.state;

    let currentStep = this.findFirstFieldStep();
    if (!currentStep) {
      this.dryRun = false;
      this.activeField = start;
      return;
    }

    this.dryRunSteps.push(currentStep.ref);

    this.runLogicForField(currentStep);
    let nextStep = this.getNextFieldOrLogicJump(currentStep);

    let tries = 0;

    while (nextStep) {
      currentStep = nextStep;

      this.runLogicForField(currentStep);
      this.dryRunSteps.push(currentStep.ref);

      nextStep = this.getNextFieldOrLogicJump(currentStep);

      tries++;
      if (tries > fields.length + endScreens.length) {
        break;
      }
    }

    this.dryRun = false;
    this.activeField = start;

    if (this.dryRunSteps.join() !== this.state.logicSteps.join()) {
      this.log("dryRunSteps", this.dryRunSteps);
      this.emit({
        ...this.state,
        logicSteps: this.dryRunSteps
      });
    }
  };

  customKeyPrefix = "custom_";
  addToKeyList = (key: string): void => {
    this.customKeyList.add(key);
    this.customFormVariables[`${this.customKeyPrefix}${key}`] = "true";
    this.collectLogicSteps();
  };

  removeFromKeyList = (key: string): void => {
    this.customKeyList.delete(key);
    this.customFormVariables[`${this.customKeyPrefix}${key}`] = "false";
    this.collectLogicSteps();
  };

  checkIfKeyExists = (key: string): boolean => {
    return this.customKeyList.has(key);
  };

  /**
   * Session management
   **/
  currentSessionId?: string;
  sessionStarted = false;

  startSession = async () => {
    if (userState.isTempUser) return;
    if (this.sessionStarted) return;

    this.log("Starting session");

    this.sessionStarted = true;
    const questionnaireId = this.state.formId;
    if (!questionnaireId) return;
    try {
      document.dispatchEvent(
        new CustomEvent("nineQuestionnaireStarted", {
          bubbles: true,
          composed: true,
          detail: { ...this.state }
        })
      );

      const sessionRequest = QuestionnaireControllerService.startSession({
        id: questionnaireId,
        type: StartSessionRequest.type.TYPEFORM
      });
      const session = await sessionRequest;

      this.currentSessionId = session.data.sessionId;
    } catch (e) {
      this.sessionStarted = false;
      reportErrorSentry(e);
    }
  };

  addAnswerToSession = async (questionId: string) => {
    if (!this.sessionStarted) {
      throw new Error("Session not started");
    }
    if (!this.currentSessionId) {
      addSentryBreadcrumb(
        "api",
        `Session not started when adding answer, creating new one`
      );
      this.log("Session not started when adding answer, creating new one");
      await this.startSession();
    }

    const typeNoAnswer = [
      QuestionnaireType.STATEMENT,
      QuestionnaireType.THANK_YOU
    ];
    const field = this.getFieldByRef(questionId);
    const fullParsedAnswer = this.parseAnswer(questionId);

    const sendAnswer = field ? !typeNoAnswer.includes(field.type) : false;

    if (!sendAnswer) {
      return;
    }

    try {
      if (!this.currentSessionId) {
        throw new Error("Session not started");
      }
      this.log("Adding answer to session", questionId);

      const answer = {
        questionId,
        answer: (fullParsedAnswer?.fieldValue ?? "") as unknown as JsonNode
      } satisfies AddAnswerToSessionRequest;

      await QuestionnaireControllerService.addAnswerToSession(
        this.currentSessionId,
        answer
      );
    } catch (e) {
      reportErrorSentry(e);
    }
  };
}
