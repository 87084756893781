import { SubscriptionDetailsResponse } from "@9amhealth/openapi";
import WithBackground from "components/WithBackground/WithBackground";
import asTemplateValue from "lib/asTemplateValue";
import type { FC, ReactNode } from "react";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_MEMBERSHIP_SKU, ONBOARDING_FEE_SKU } from "src/constants/misc";
import translate from "src/lib/translate";
import useContentForProgram from "src/lib/useContentForProgram";
import useSubscription from "src/lib/useSubscription";
import type { SubscriptionInterval } from "src/state/TreatmentPlanCubit/TreatmentPlanCubit";
import {
    PayerTrait,
    ProfileAttributesKeys,
    ProfileProgramMembership
} from "src/state/UserCubit/UserCubit";
import { subscriptionState, userState } from "src/state/state";
import { TranslationKey } from "src/types/translationKey";
import Loader from "../Loader/Loader";
import type { SignupCheckoutPageContentCartSection } from "../SignupCustomContent/steps/SignupCustomStepCheckoutCustomRender";

export interface CheckoutCartItemViewData {
  title?: TranslationKey;
  fee: number;
  show: boolean;
}

export type CheckoutCartItemKey = "onboarding" | "ongoingCare";

interface DynamicContentProps {
  eligibleForMedicalBilling: boolean;
  cartSection?: SignupCheckoutPageContentCartSection;
  legalAppendix?: ReactNode;
  totalPrice: number;
  priceAfterDiscount: number;
  paymentInterval?: SubscriptionInterval;
  subscriptionId?: string;
  title: string;
  program_title: string;
  subtitle: string;
  items: Record<CheckoutCartItemKey, CheckoutCartItemViewData>;
}

export const prepareComputedProps = (
  subscription: SubscriptionDetailsResponse | undefined
): DynamicContentProps => {
  const subscriptionPrice = subscription?.totalPrice ?? 0;
  const userHasToPay = subscriptionPrice > 0;
  const payerTraits =
    userState.state.profileAttributes?.[
      ProfileAttributesKeys.partnerPayersCurrent
    ]?.partner.traits ?? [];
  const isEligibleForMedicalBilling = payerTraits.includes(
    PayerTrait.ELIGIBLE_FOR_MEDICAL_BILLING
  );

  const ongoingCareFee = subscription
    ? (subscriptionState.getItemBySku(subscription.id, BASE_MEMBERSHIP_SKU)
        ?.price ?? 0)
    : 0;
  const onboardingFee = subscription
    ? (subscriptionState.getItemBySku(subscription.id, ONBOARDING_FEE_SKU)
        ?.price ?? 0)
    : 0;

  // show onboarding fee item if onboarding fee is different from ongoing care fee, and not 0
  const showOnboardingFeeItem =
    onboardingFee !== 0 && ongoingCareFee !== onboardingFee;

  const showOngoingCareText = showOnboardingFeeItem;

  let subProgram: ProfileProgramMembership | null = null;
  for (const purchaseItem of subscription?.purchaseItems ?? []) {
    const pricingMembership = asTemplateValue(
      purchaseItem.metadata["program.pricing-membership"]
    );
    if (pricingMembership !== undefined) {
      subProgram = pricingMembership as ProfileProgramMembership;
    }
  }

  const returnProps: DynamicContentProps = {
    program_title: subProgram
      ? translate(`program.title_${subProgram}`)
      : translate("signup_funnel_checkout_title"),
    title: translate(`signup_funnel_checkout_title`),
    subtitle: translate("signup_funnel_checkout_subtitle"),
    cartSection: undefined,
    totalPrice: subscriptionPrice,
    priceAfterDiscount: subscriptionPrice,
    paymentInterval: subscription?.paymentInterval as SubscriptionInterval,
    subscriptionId: subscription?.id,
    items: {
      onboarding: {
        title: "signup_funnel_checkout_item_title_onboarding",
        fee: onboardingFee,
        show: showOnboardingFeeItem
      },
      ongoingCare: {
        title: showOngoingCareText
          ? "signup_funnel_checkout_item_title_ongoing_care"
          : "signup_funnel_checkout_item_title_monthly_membership",
        fee: ongoingCareFee,
        show: true
      }
    },
    eligibleForMedicalBilling: isEligibleForMedicalBilling
  };

  returnProps.cartSection = userHasToPay
    ? {
        title: returnProps.title,
        subtitle: translate("membership.checkout.subtitle"),
        image: undefined
      }
    : undefined;

  return returnProps;
};

const SignupCustomStepCheckoutDynamicContent: FC<{
  render: (props: DynamicContentProps) => ReactNode;
}> = (props) => {
  const { campaign = "" } = useParams<{ campaign?: string }>();
  const navigate = useNavigate();
  const funnelSpecificSubscription = useSubscription({
    status: [
      SubscriptionDetailsResponse.status.DRAFT,
      SubscriptionDetailsResponse.status.ACTIVE
    ],
    metadataPartnerFunnel: [campaign]
  });

  useEffect(() => {
    if (!funnelSpecificSubscription) {
      const anyDraftSubscriptions = subscriptionState.filterAllSubscriptions({
        status: [SubscriptionDetailsResponse.status.DRAFT]
      });

      anyDraftSubscriptions.forEach(({ purchaseItems }) => {
        for (const item of purchaseItems) {
          const partnerFunnel = asTemplateValue(
            item.metadata["partner.funnel"]
          );
          if (partnerFunnel && partnerFunnel !== campaign) {
            navigate(`/signup/${partnerFunnel}`, { replace: true });
            return;
          }
        }
      });
    }
  }, [funnelSpecificSubscription]);

  const content = useContentForProgram();

  const renderChild = React.useCallback(() => {
    return props.render(prepareComputedProps(funnelSpecificSubscription));
  }, [content, funnelSpecificSubscription]);

  if (!funnelSpecificSubscription || !content.imageSrc) {
    return <Loader active fullPage />;
  }

  return (
    <WithBackground variant="animate-primary" fixed>
      {renderChild()}
    </WithBackground>
  );
};

export default SignupCustomStepCheckoutDynamicContent;
