import { RxDietControllerService } from "@9amhealth/openapi";
import { Cubit } from "blac";
import { addSentryBreadcrumb } from "src/lib/addSentryBreadcrumb";
import envVariables from "src/lib/envVariables";
import reportErrorSentry from "src/lib/reportErrorSentry";

interface IframeMessage {
  type: string;
  data: {
    username: string;
    password: string;
  };
}

enum IframeMessageType {
  APP_LOADED = "app.loaded",
  APP_LOGIN = "app.login"
}

interface RxDietState {
  username?: string;
  password?: string;
}

export default class RxDietCubit extends Cubit<RxDietState> {
  constructor() {
    super({});
  }

  private readonly getRxDietUserCredentials = async () => {
    if (!this.state.username || !this.state.password) {
      try {
        const { data } = await RxDietControllerService.getUserCredentials();

        this.emit({
          ...this.state,
          username: data.username,
          password: data.password
        });
      } catch (e) {
        addSentryBreadcrumb(
          "errorHandler",
          "getRxDietUserCredentials error",
          "info",
          {
            error: e
          }
        );
      }
    }
  };

  private readonly createRxDietUserAccount = async () => {
    try {
      const { data } = await RxDietControllerService.createUser();

      this.emit({
        ...this.state,
        username: data.username,
        password: data.password
      });
    } catch (e) {
      reportErrorSentry(e);
    }
  };

  private readonly logInToRxDietAccount = (credentials: {
    username?: string;
    password?: string;
  }) => {
    const { username, password } = credentials;

    if (!username || !password) {
      const errorMessage =
        "Cannot log in to RxDiet account, no username and/or password available";
      reportErrorSentry(errorMessage);
      throw new Error(errorMessage);
    }

    this.sendMessageToIframe({
      type: IframeMessageType.APP_LOGIN,
      data: {
        username,
        password
      }
    });
  };

  private readonly sendMessageToIframe = (message: IframeMessage) => {
    const iframeDOMElement = document.getElementById(
      "rxdiet-app"
    ) as HTMLIFrameElement | null;

    iframeDOMElement?.contentWindow?.postMessage(
      JSON.stringify(message),
      new URL(envVariables.RXDIET_IFRAME_URL).origin
    );
  };

  private readonly handleMessageFromIframe = async (event: MessageEvent) => {
    if (event.origin === new URL(envVariables.RXDIET_IFRAME_URL).origin) {
      try {
        const message = JSON.parse(event.data as string) as IframeMessage;

        // Try to log user in to RxDiet account when RxDiet app is loaded inside iframe
        if (message.type === IframeMessageType.APP_LOADED) {
          await this.getRxDietUserCredentials();

          // If there are no RxDiet user credentials, create new user
          if (!this.state.username || !this.state.password) {
            await this.createRxDietUserAccount();
          }

          this.logInToRxDietAccount({
            username: this.state.username,
            password: this.state.password
          });
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error("Failed to handle message from iframe");
        reportErrorSentry(e);
      }
    }
  };

  public readonly messageEventListener = (event: MessageEvent) => {
    void this.handleMessageFromIframe(event);
  };
}
