import type { PaymentMethod } from "@9amhealth/openapi";
import type { FC } from "react";
import React, { useMemo } from "react";
import { Helmet } from "react-helmet";
import asTemplateValue from "lib/asTemplateValue";
import LoadingCubit, { LoadingKey } from "src/state/LoadingCubit/LoadingCubit";
import SubscriptionCubit from "src/state/SubscriptionCubit/SubscriptionCubit";
import { TrackEvent, TrackType } from "src/state/Track/TrackCubit";
import { useBloc } from "src/state/state";
import Collection from "src/ui/components/StyledComponents/Collection";
import CollectionItemLink from "src/ui/components/StyledComponents/CollectionItemLink";
import ErrorBox from "src/ui/components/StyledComponents/ErrorBox";
import Track from "src/ui/components/Track/Track";
import Translate from "src/ui/components/Translate/Translate";

const DisplayPaymentMethod: FC<{
  defaultPaymentMethod?: PaymentMethod;
}> = ({ defaultPaymentMethod }) => {
  const [, { isLoading }] = useBloc(LoadingCubit);
  const [, { coveredByInsurance }] = useBloc(SubscriptionCubit);
  const loading = isLoading(LoadingKey.payment);

  const showCard = useMemo(
    () => loading || defaultPaymentMethod,
    [loading, defaultPaymentMethod]
  );

  return (
    <div>
      <Track event={TrackEvent.profile} type={TrackType.open} />
      <Helmet>
        <link rel="preconnect" href="https://billing.stripe.com/" />
        <link rel="dns-prefetch" href="https://billing.stripe.com/" />
      </Helmet>
      {showCard && (
        <Collection>
          <CollectionItemLink
            label="Card"
            detail={
              loading
                ? undefined
                : `**** ${asTemplateValue(defaultPaymentMethod?.properties?.lastFourDigits)}`
            }
          />
          <CollectionItemLink
            label="Expires"
            detail={
              loading
                ? undefined
                : `${asTemplateValue(defaultPaymentMethod?.properties?.expirationMonth)}/${asTemplateValue(defaultPaymentMethod?.properties?.expirationYear)}`
            }
          />
        </Collection>
      )}
      {!showCard && !coveredByInsurance && (
        <ErrorBox data-severity="warning">
          <Translate msg="payment.addDefault" />
        </ErrorBox>
      )}
      {!showCard && coveredByInsurance && (
        <Translate msg="payment.subscriptionCovered" />
      )}
    </div>
  );
};

export default DisplayPaymentMethod;
