/* eslint-disable @typescript-eslint/no-deprecated */
import type { Node, SelectState } from "react-stately";
import { useSelectState } from "react-stately";
import {
  AriaListBoxOptions,
  AriaSelectProps,
  HiddenSelect,
  Key,
  useListBox,
  useOption,
  useSelect,
  useListBoxSection
} from "react-aria";
import Popover from "molecule/popover/Popover";
import React from "react";
import {
  DropdownButtonEl,
  DropdownCard,
  DropdownErrorEl,
  DropdownIcon,
  DropdownLabelEl,
  DropdownTextEl,
  DropdownValue,
  ListBoxWrapper,
  StyledOption,
  StyledSection
} from "atom/dropdown/dropdownComponents";
import { useElementDimensions } from "lib/useElementDimensions";
import { z } from "zod";
import { DropdownState, FormFieldState } from "atom/autoform/AutoFormBloc";

export function ListBox<T>(
  props: AriaListBoxOptions<T> & {
    state: SelectState<T>;
    listBoxRef?: React.RefObject<HTMLDivElement | null>;
  }
) {
  const ref = React.useRef(null);
  const { listBoxRef = ref, state } = props;
  const { listBoxProps } = useListBox(props, state, listBoxRef);

  return (
    <ListBoxWrapper {...listBoxProps} ref={listBoxRef}>
      {[...state.collection].map((item) =>
        item.type === "section" ? (
          <ListBoxSection key={item.key} section={item} state={state} />
        ) : (
          <Option key={item.key} item={item} state={state} />
        )
      )}
    </ListBoxWrapper>
  );
}

function ListBoxSection<T>({
  section,
  state
}: {
  section: Node<T>;
  state: SelectState<T>;
}) {
  const { itemProps, headingProps, groupProps } = useListBoxSection({
    heading: section.rendered,
    "aria-label": section["aria-label"]
  });

  return (
    <>
      <div {...itemProps}>
        {section.rendered && (
          <StyledSection {...headingProps}>{section.rendered}</StyledSection>
        )}
        <div {...groupProps}>
          {[...section.childNodes].map((node) => (
            <Option key={node.key} item={node} state={state} />
          ))}
        </div>
      </div>
    </>
  );
}

export function Option<T>({
  item,
  state
}: {
  item: Node<T>;
  state: SelectState<T>;
}) {
  const ref = React.useRef(null);
  const { optionProps, isSelected } = useOption({ key: item.key }, state, ref);

  return (
    <StyledOption
      // set tabIndex to 0 if this option is focused
      tabIndex={state.selectionManager.focusedKey === item.key ? 0 : -1}
      data-selected={isSelected}
      {...optionProps}
      ref={ref}
    >
      {item.rendered}
    </StyledOption>
  );
}

export interface DropdownProps<T extends object> extends AriaSelectProps<T> {
  label?: string;
  errorParser?: (props: DropdownProps<T>) => string;
  defaultValue?: Key;
  error?: string | z.ZodIssue;
  ref?: React.Ref<FormFieldState | null>;
}

function Dropdown<T extends object>(props: DropdownProps<T>) {
  const {
    errorParser = ({ error }) => {
      if (!error) return "";
      if (typeof error === "string") return error;
      return error.message;
    },
    ...dropdownFieldProps
  } = props;

  const state = useSelectState({
    ...props,
    defaultSelectedKey: props.defaultValue
  });

  const elRef = React.useRef<HTMLButtonElement>(null);
  const { triggerProps, menuProps } = useSelect(props, state, elRef);

  const [{ width }] = useElementDimensions(
    elRef as React.RefObject<HTMLButtonElement>
  );

  React.useImperativeHandle(
    props.ref,
    () => {
      return {
        setValue: (value: string) => {
          setTimeout(() => {
            state.setSelectedKey(value);
          });
        }
      } as DropdownState;
    },
    [state]
  );

  const errorString = errorParser(props);

  return (
    <>
      <HiddenSelect
        state={state}
        isDisabled={props.isDisabled}
        triggerRef={elRef}
        label={props.label}
        name={props.name}
      />
      <DropdownButtonEl
        {...dropdownFieldProps}
        data-invalid={props.isInvalid || Boolean(props.error)}
        ref={elRef}
        {...triggerProps}
      >
        <DropdownValue
          data-value={state.selectedItem ? state.selectedItem.value : ""}
          data-label={props.label}
        >
          {state.selectedItem ? state.selectedItem.rendered : ""}
        </DropdownValue>

        <DropdownIcon />

        {props.label && (
          <DropdownLabelEl
            data-expanded={state.isOpen || Boolean(state.selectedItem)}
            className="body1"
          >
            {props.label}
          </DropdownLabelEl>
        )}
      </DropdownButtonEl>

      {props.description && (
        <DropdownTextEl slot="description" className="little1">
          {props.description}
        </DropdownTextEl>
      )}

      <DropdownErrorEl className={"little1 data-field-error"}>
        {errorString}
      </DropdownErrorEl>

      {state.isOpen && (
        <Popover
          hideArrow
          title={`${props.label ?? props.name} options`}
          state={state}
          triggerRef={elRef}
          placement="bottom start"
        >
          <DropdownCard
            style={{
              width
            }}
          >
            <ListBox {...menuProps} state={state} />
          </DropdownCard>
        </Popover>
      )}
    </>
  );
}

export default Dropdown;
