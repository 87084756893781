import styled from "@emotion/styled";
import type { FC } from "react";
import React from "react";
import Path from "src/constants/path";
import { createAppPath } from "src/lib/createAppPath";
import AuthenticationBloc from "src/state/UserCubit/AuthenticationBloc";
import { useBloc } from "src/state/state";
import LoginForm from "src/ui/components/LoginForm/LoginForm";
import { Pad } from "src/ui/styled/Pad";
import Loader from "../Loader/Loader";
import { keyframes } from "@emotion/css";
import AppViewCubit from "src/state/AppViewCubit/AppViewCubit";
import { NiceError } from "src/ui/styled/NiceError";
import Translate from "../Translate/Translate";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const FillPage = styled.div`
  label: FillPage;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--color-cream);
  z-index: 2000;
  display: flex;
  justify-content: center;
  overflow: auto;
  padding: 2em 0;
  transition: padding 0.3s;
  padding-top: calc(var(--safe-area-top, 0));
  padding-bottom: var(--ion-safe-area-bottom, 0);
  transition: padding-bottom 0.5s cubic-bezier(0.38, 0.7, 0.125, 1);
  opacity: 0;
  animation: ${fadeIn} 0.5s ease-in-out forwards;
  animation-delay: 0.5s;

  &:has(:focus-within) {
    padding-bottom: calc(
      var(--stored-keyboard-height, 0px) + var(--ion-safe-area-bottom, 0)
    );
  }
`;

const Content = styled.div`
  label: Content;
  width: min(100%, 620px);
  padding: 2em 0;
  margin: auto;
`;

const LoginOverlay: FC = () => {
  const [{ authenticationStatus }] = useBloc(AuthenticationBloc);
  const [{ partnerSession }] = useBloc(AppViewCubit);

  if (authenticationStatus === "pending") {
    return <Loader gradient active fixed />;
  }

  if (partnerSession) {
    return (
      <FillPage>
        <NiceError>
          <h1>
            <Translate msg="auth.sessionExpired" />
          </h1>
        </NiceError>
      </FillPage>
    );
  }

  return (
    <FillPage>
      <Content>
        <Pad className="wrap-center">
          <LoginForm
            resetPasswordLink={createAppPath(Path.authResetPassword, "auth")}
            registerLink={"/signup/universal" as Path}
          />
        </Pad>
      </Content>
    </FillPage>
  );
};

export default LoginOverlay;
