import { BgVariants } from "@9amhealth/wcl";
import styled from "@emotion/styled";
import React from "react";

const BackgroundStyle = styled.div`
  background-color: var(--color-cream);
  min-height: 100%;
  height: 100%;

  &.animate-primary {
    background: var(
      --bg-primary-gradient,
      linear-gradient(228deg, #ffdfb4 -16.15%, #fddee9 40.76%, #c1d7fb 94.22%)
    );
  }
`;

const FixedBg = styled.div`
  background-color: var(--color-cream);

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &.animate-primary {
    background: var(
      --bg-primary-gradient,
      linear-gradient(228deg, #ffdfb4 -16.15%, #fddee9 40.76%, #c1d7fb 94.22%)
    );
  }
`;

const WithBackground: React.FC<{
  children: React.ReactNode;
  variant?: BgVariants;
  fixed?: boolean;
  style?: React.CSSProperties;
}> = ({ children, variant, fixed, style }) => {
  return (
    <BackgroundStyle className={`${variant} bg-wrapper`} style={style}>
      {fixed && <FixedBg className={variant} />}

      {children}
    </BackgroundStyle>
  );
};

export default WithBackground;
