export default function asTemplateValue(
  value: unknown
): string | number | undefined | boolean {
  if (
    typeof value === "string" ||
    typeof value === "number" ||
    typeof value === "boolean" ||
    value === "undefined"
  ) {
    return value;
  }

  return undefined;
}
