import { Cubit } from "blac";
import reportErrorSentry from "src/lib/reportErrorSentry";
import type { TrackEvent } from "src/state/Track/TrackCubit";
import { VideoPlayerLibInterface } from "src/state/VideoPlayerCubit/VideoPlayerCubit";
import { tracker } from "src/state/state";
import videojs from "video.js";

export type FullVideoPlayerBlocState = {
  playing: boolean;
  ready: boolean;
  player?: VideoPlayerLibInterface;
};

export type VideoPlayerOptions = {
  autoplay?: boolean;
  controls?: boolean;
  loop?: boolean;
  muted?: boolean;
  poster?: string;
  preload?: "auto" | "metadata" | "none";
  ratio?: number;
  sources?: {
    src: string;
    type: string;
  }[];
};

export default class FullVideoPlayerBloc extends Cubit<FullVideoPlayerBlocState> {
  containerElement: HTMLDivElement | null = null;
  videoOptions?: VideoPlayerOptions;
  defaultOptions: VideoPlayerOptions = {
    autoplay: false,
    controls: true,
    loop: false,
    muted: false,
    preload: "auto",
    sources: []
  };

  constructor() {
    super({
      playing: false,
      ready: false
    });
  }

  initVideo = (
    containerElement: HTMLDivElement | null,
    videoOptions: VideoPlayerOptions
  ) => {
    const optionsMerged = {
      ...this.defaultOptions,
      ...videoOptions,
      playsinline: true,
      // use native controls for touch devices
      // in addiction, this allows the video to be played correctly in fullscreen mode
      //  when the app is wrapped in a webview or inappbrowser
      nativeControlsForTouch: true
    };
    if (this.containerElement !== null || containerElement === null) {
      return;
    }

    this.containerElement = containerElement;
    this.videoOptions = optionsMerged;

    const videoElement = document.createElement("video-js");

    videoElement.classList.add("vjs-big-play-centered");
    this.containerElement.appendChild(videoElement);

    const player = videojs(videoElement, optionsMerged, () => {
      this.emit({
        ...this.state,
        playing: videoOptions.autoplay === true,
        ready: true,
        player: player as VideoPlayerLibInterface
      });
      player.preload();

      this.addPlayerListeners();
    });
  };

  errorCount = 0;
  errorLimit = 3;

  addPlayerListeners = () => {
    if (!this.state.player) {
      return;
    }

    const trackingEvents = [
      "play",
      "pause",
      "mute",
      "ended",
      "error",
      "ready",
      "fullscreenchange"
    ];

    const { player } = this.state;

    trackingEvents.forEach((eventName) => {
      player.on(eventName, () => {
        const duration = player.duration();
        const readyState = player.readyState();
        const networkState = player.networkState();
        const currentSrc = player.currentSrc();
        const bufferedPercent = player.bufferedPercent();

        const readyStateName: Record<number, string | undefined> = {
          0: "HAVE_NOTHING",
          1: "HAVE_METADATA",
          2: "HAVE_CURRENT_DATA",
          3: "HAVE_FUTURE_DATA",
          4: "HAVE_ENOUGH_DATA"
        };
        const readyStateNameString = readyStateName[readyState];

        const networkStateName: Record<number, string | undefined> = {
          0: "NETWORK_EMPTY",
          1: "NETWORK_IDLE",
          2: "NETWORK_LOADING",
          3: "NETWORK_NO_SOURCE"
        };
        const networkStateNameString = networkStateName[networkState];
        const error = player.error();

        const trackData = {
          video_playhead_seconds: Math.round(player.currentTime()),
          duration: isNaN(duration) ? -1 : Math.round(duration),
          fullscreen: player.isFullscreen(),
          currentSrc,
          bufferedPercent,
          readyState,
          readyStateNameString,
          networkState,
          networkStateNameString,
          errorCode: error?.code,
          errorMessage: error?.message
        };

        if (eventName === "error") {
          if (this.errorCount === 0) {
            this.trackEvent(eventName, trackData);
            reportErrorSentry(error);
          }

          this.errorCount++;
          setTimeout(() => {
            if (this.videoOptions && this.errorCount >= this.errorLimit) {
              this.reInitVideo(this.containerElement, this.videoOptions);
            }
          }, 1000);
        } else {
          this.trackEvent(eventName, trackData);
        }
      });
    });
  };

  trackEvent = (
    event: string,
    data: Record<string, boolean | number | string | undefined>
  ) => {
    tracker.track(`Video Player Interaction: ${event}` as TrackEvent, { data });
    this.log(event, data);
  };

  loggingEnabled = false;
  log = (event: string, ...data: unknown[]) => {
    if (!this.loggingEnabled) return;
    // eslint-disable-next-line no-console
    console.log(`VIDEO PLAYER EVENT: ${event}`, ...data);
  };

  reInitVideo = (
    containerElement: HTMLDivElement | null,
    videoOptions: VideoPlayerOptions
  ) => {
    this.disposePlayer();
    this.initVideo(containerElement, videoOptions);
  };

  disposePlayer = () => {
    const { player } = this.state;
    if (player && !player.isDisposed()) {
      player.dispose();
    }
    this.containerElement = null;
    this.videoOptions = undefined;
    this.emit({
      ...this.state,
      playing: false,
      ready: false,
      player: undefined
    });
  };
}
