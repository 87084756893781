import type { NineForm, NineInput } from "@9amhealth/wcl";
import { NineButton } from "@9amhealth/wcl/generated/react";
import styled from "@emotion/styled";
import type { IonDatetimeCustomEvent } from "@ionic/core/dist/types/components";
import type { DatetimeChangeEventDetail } from "@ionic/react";
import { IonDatetime, IonModal } from "@ionic/react";
import type { Dayjs } from "dayjs";
import type { FC } from "react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import date, { DateFormats, dateLocal } from "src/lib/date";
import { getSupportedUserLocale } from "src/lib/i18next";
import translate from "src/lib/translate";
import OnEvent from "src/ui/components/OnEvent/OnEvent";

const StyledModal = styled(IonModal)`
  --width: auto;
  --height: auto;
  --border-radius: 0.5em;
`;

const MobileDateInputPicker: FC<{
  value?: Dayjs | string;
  min?: (d: Dayjs) => Dayjs;
  max?: (d: Dayjs) => Dayjs;
  onChange?: (value: string | undefined) => void;
  error?: string;
  name?: string;
  label?: string;
  required?: boolean;
  size?: "full" | "half";
}> = (props) => {
  const inputElRef = useRef<NineInput>(null);
  const [localError, setLocalError] = useState<string>("");
  const [inputKeyUpdateTime, setInputKeyUpdateTime] = useState<number>(0);
  const { min, max, value } = props;
  const lastValidDate = useRef<string>(null);

  const [selectedDate, setSelectedDate] = useState<string | undefined>(
    value ? dateLocal(value).format(DateFormats.ISO_YMDHMS) : undefined
  );

  const dateValid = useMemo(() => {
    if (!selectedDate) {
      return false;
    }

    const day = dateLocal(selectedDate, DateFormats.ISO_YMD);
    return day.isValid();
  }, [selectedDate]);

  const [showPicker, setShowPicker] = useState(false);

  const minDate = min
    ? min(dateLocal()).format(DateFormats.ISO_YMD)
    : undefined;

  const maxDate = max
    ? max(dateLocal()).format(DateFormats.ISO_YMD)
    : undefined;

  const dateInRange = useMemo(() => {
    if (!dateValid) return "invalid";
    const day = dateLocal(selectedDate, DateFormats.ISO_YMD);
    const dayString = day.format(DateFormats.ISO_YMD);

    if (minDate && dayString !== minDate && day.isBefore(minDate)) {
      return "before";
    }

    if (maxDate && dayString !== maxDate && day.isAfter(maxDate)) {
      return "after";
    }

    return true;
  }, [selectedDate, dateValid]);

  const handlePickerChange = (
    event: IonDatetimeCustomEvent<DatetimeChangeEventDetail>
  ) => {
    const v = event.detail.value;

    if (typeof v !== "string") {
      return;
    }

    setSelectedDate(v);
    setInputKeyUpdateTime(Date.now());
  };

  const handlePickerCancel = () => {
    setShowPicker(false);
  };

  const handleInputChange = (
    event: CustomEvent<{
      value: string;
      maskedValue: string;
      runLocalHandler?: boolean;
    }>
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!event.detail || event.detail.runLocalHandler === false) return;

    const withMask = event.detail.maskedValue;
    const noMask = event.detail.value;
    setLocalError("");

    if (!noMask) {
      setSelectedDate(undefined);
      return;
    }

    const d = dateLocal(withMask, DateFormats.INPUT_US);
    const converted = d.isValid() && d.format(DateFormats.ISO_YMD);

    if (converted) {
      setSelectedDate(converted);

      setTimeout(() => {
        if (props.name) {
          const form = inputElRef.current?.closest("nine-form") as
            | NineForm
            | undefined;
          if (form) {
            form.values[props.name] = withMask;
          }
        }
      }, 60);

      return;
    }
  };

  const inputValue = useMemo(() => {
    const converted = date.convert(
      selectedDate ?? "",
      DateFormats.ISO_YMD,
      DateFormats.INPUT_US
    );

    if (!converted) {
      return "";
    }

    return converted;
  }, [selectedDate]);

  useEffect(() => {
    if (selectedDate && dateValid) {
      const day = dateLocal(selectedDate, DateFormats.ISO_YMD);
      const fmt = day.format(DateFormats.ISO_YMD);
      lastValidDate.current = fmt;

      if (dateInRange === "before") {
        const minDateString = dateLocal(minDate).format(
          DateFormats.DISPLAY_US_MDY
        );
        setLocalError(`Date must be after ${minDateString}`);
        props.onChange?.(undefined);
        return;
      }

      if (dateInRange === "after") {
        const maxDateString = dateLocal(maxDate).format(
          DateFormats.DISPLAY_US_MDY
        );
        setLocalError(`Date must be before ${maxDateString}`);
        props.onChange?.(undefined);
        return;
      }

      setLocalError("");
      props.onChange?.(fmt);
    }
  }, [selectedDate, dateInRange]);

  const handleInputBlur = () => {
    if (!dateValid) {
      setLocalError("Invalid date");
      props.onChange?.(undefined);
    }
    if (selectedDate === undefined) {
      setLocalError("");
    }
  };

  return (
    <OnEvent
      events={{
        "nine-change": handleInputChange,
        "nine-blur": handleInputBlur
      }}
    >
      <>
        <nine-input
          type="tel"
          mask="00/00/0000"
          lazy={"false"}
          label={props.label}
          name={props.name}
          required={props.required ? "true" : undefined}
          value={inputValue}
          key={`${inputKeyUpdateTime}`}
          force-error={props.error ?? localError}
          size={props.size}
          ref={inputElRef}
        >
          <NineButton
            track="false"
            variant="ghost"
            arrow=""
            round="true"
            aria-hidden="true"
            slot="decoration-end"
            onClick={() => setShowPicker(true)}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.32 6.178h-2.142a1.05 1.05 0 0 0 .014-.167V4.68a1.167 1.167 0 0 0-1.16-1.164h-.672a1.166 1.166 0 0 0-1.164 1.164v1.332c0 .055.005.11.013.166H9.86c.01-.055.014-.11.014-.167V4.68a1.166 1.166 0 0 0-1.165-1.164h-.665A1.166 1.166 0 0 0 6.879 4.68v1.332c0 .055.005.11.013.166H4.684a1.167 1.167 0 0 0-1.168 1.164V19.32a1.166 1.166 0 0 0 1.164 1.165h14.64a1.166 1.166 0 0 0 1.165-1.165V7.342a1.167 1.167 0 0 0-1.165-1.164Zm-4.128-.167V4.68a.167.167 0 0 1 .168-.168h.672a.167.167 0 0 1 .167.166v1.333a.167.167 0 0 1-.167.167h-.672a.167.167 0 0 1-.164-.167h-.004Zm-7.319 0V4.68a.167.167 0 0 1 .17-.168h.665a.166.166 0 0 1 .166.166v1.333a.166.166 0 0 1-.166.167h-.665a.167.167 0 0 1-.166-.167h-.004ZM4.68 7.176h14.64a.166.166 0 0 1 .168.166v2.828H4.512V7.342a.166.166 0 0 1 .168-.166Zm14.64 12.312H4.68a.168.168 0 0 1-.168-.168v-8.151h14.976v8.151a.167.167 0 0 1-.168.168Z"
                fill="#212121"
              ></path>
            </svg>
          </NineButton>
        </nine-input>
        <StyledModal isOpen={showPicker} onDidDismiss={handlePickerCancel}>
          <IonDatetime
            value={dateValid && dateInRange === true ? selectedDate : undefined}
            min={minDate}
            max={maxDate}
            presentation="date"
            showDefaultButtons={true}
            onIonChange={handlePickerChange}
            onIonCancel={handlePickerCancel}
            locale={getSupportedUserLocale()}
            cancelText={translate("cancel")}
            doneText={translate("done")}
          ></IonDatetime>
        </StyledModal>
      </>
    </OnEvent>
  );
};

export default MobileDateInputPicker;
