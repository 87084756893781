import { isApiLink } from "lib/Urls";

export interface ApiControllerConfig {
  refreshTokenEndpoint: string;
  ignoreTraceForSuccessResponse: RegExp[];
  loggingEnabled: boolean;
  reportError: (
    error: Error | unknown,
    options?: Record<string, unknown>
  ) => void;
  logBreadcrumb: (
    category: string,
    message: string,
    level?: string,
    data?: Record<string, unknown>
  ) => void;
  clientId: string;
  requestsWithoutAuth: string[];
  requestsWithExpected401: string[];
  priorityRequestsWithAuth: string[];
  // Authentication state provider
  authState: {
    accessToken?: string;
    refreshToken?: string;
    authenticationStatus: string;
    refreshAccessToken: () => Promise<void>;
  };
}

type RequestQueueOptions = {
  requiresAuth: boolean;
};

type RequestQueueItem =
  | {
      request: () => Promise<unknown>;
      options: RequestQueueOptions;
      running?: boolean;
    }
  | undefined;

export class ApiController {
  config: ApiControllerConfig;

  constructor(config: ApiControllerConfig) {
    this.config = config;
  }

  /** keeps track of how long requests take to load */
  timer: Map<string, number[]> = new Map();

  logTimer = (url: string, time: number): void => {
    if (!this.timer.has(url)) {
      this.timer.set(url, []);
    }
    this.timer.get(url)?.push(time);
  };

  logAverageTimes = (): void => {
    const t: { url: string; average: number; count: number }[] = [];
    this.timer.forEach((times, url) => {
      const average = times.reduce((acc, time) => acc + time, 0) / times.length;
      t.push({ url, average, count: times.length });
    });
    const sorted = t.sort((a, b) => b.average - a.average);
    // eslint-disable-next-line no-console
    console.table(sorted);
  };

  get loggingEnabled(): boolean {
    return this.config.loggingEnabled;
  }

  private readonly log = (message: string, etc?: unknown): void => {
    if (this.loggingEnabled) {
      // eslint-disable-next-line no-console
      console.warn(`[API-C]: ${message}`, etc ?? "");
    }
    this.config.logBreadcrumb("apiCache", message, "info", { etc });
  };

  handleGlobalFetchError = (
    error: Error | unknown,
    urlOpt?: RequestInfo | URL,
    options: Record<string, unknown> = {}
  ): boolean => {
    try {
      const body =
        (
          error as {
            body?: {
              code?: number;
              message?: string;
              data?: Record<string, unknown>;
            };
          }
        ).body ?? {};

      // pass a response error message to error
      if (error instanceof Error && !error.message && body.message) {
        error.message = body.message;
      }

      if (body.data) {
        options.data = body.data;
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e) {
      // ignore
    }

    this.config.reportError(error, {
      url: urlOpt,
      options
    });

    return false;
  };

  requestQueue: RequestQueueItem[] = [];
  requestsQueuePriority: RequestQueueItem[] = [];

  collectRequestsForBatch = (): RequestQueueItem[] => {
    // only return "normal" requests if user is authenticated
    const requestsRequireAuth =
      this.config.authState.authenticationStatus === "authenticated"
        ? this.requestQueue
        : [];

    // return priority requests if there are any
    const inQueue =
      this.requestsQueuePriority.length > 0
        ? this.requestsQueuePriority
        : requestsRequireAuth;

    // filter out requests that are already running
    const requests = inQueue.filter((request) => !request?.running);

    return requests;
  };

  processRequestQueue = async (): Promise<void> => {
    // stop if priority request is running
    const priorityRequestIsRunning = this.requestsQueuePriority.some(
      (request) => request?.running
    );
    if (priorityRequestIsRunning) {
      this.log("Priority Request is already running");
      return;
    }

    const batchPromise = Promise.all(
      this.collectRequestsForBatch().map(this.processSingleRequest)
    );

    await batchPromise;

    if (this.collectRequestsForBatch().length > 0) {
      void this.processRequestQueue();
    }
  };

  processSingleRequest = async (request: RequestQueueItem): Promise<void> => {
    if (!request || request.running) {
      return;
    }
    request.running = true;

    try {
      await request.request();
    } catch (error) {
      this.handleGlobalFetchError(error);
    } finally {
      // remove request from queue
      const inPriorityQueue = this.requestsQueuePriority.includes(request);
      if (inPriorityQueue) {
        this.requestsQueuePriority = this.requestsQueuePriority.filter(
          (item) => item !== request
        );
      } else {
        this.requestQueue = this.requestQueue.filter(
          (item) => item !== request
        );
      }
    }
  };

  patchRequestOptions = (
    requestInit?: RequestInit,
    options?: {
      requiresAuth?: boolean;
      url: string;
    }
  ): RequestInit | undefined => {
    if (!requestInit) return;
    const { requiresAuth = true, url = "" } = options ?? {};
    const patchedOptions = { ...requestInit };

    const headers = new Headers(requestInit.headers);
    headers.set("X-NineamHealth-Client", this.config.clientId);

    let token = `Bearer ${this.config.authState.accessToken ?? ""}`;

    // use refresh token for refresh token requests
    if (url.endsWith(this.config.refreshTokenEndpoint)) {
      token = `Bearer ${this.config.authState.refreshToken ?? ""}`;
    }

    if (requiresAuth) {
      // add Authorization header
      headers.set("Authorization", token);
    } else {
      // remove Authorization header
      headers.delete("Authorization");
    }

    patchedOptions.headers = headers;

    return patchedOptions;
  };

  logTrace = (url: string, res: Response): void => {
    try {
      const traceId = res.headers.get("X-NineamHealth-TraceId");
      const resCode = res.status;

      const isErrorCode = resCode >= 400;
      if (
        !isErrorCode &&
        this.config.ignoreTraceForSuccessResponse.some((re) => re.test(url))
      )
        return;

      if (traceId) {
        this.config.logBreadcrumb(
          "traceId",
          `${resCode} | ${url} | ${traceId}`,
          "log"
        );
      }
    } catch (e) {
      this.config.logBreadcrumb("error", "Error logging trace", "error", { e });
    }
  };

  /**
   * Patch the response object to allow for easy access to the JSON response
   * normally you can only access the response body once the response is consumed,
   * this patch allows you to access the response body for JSON multiple times
   */
  patchResponse = async (res: Response): Promise<void> => {
    const contentType = res.headers.get("content-type");
    if (!res.ok || contentType !== "application/json") {
      return;
    }

    const resJson = await res.json();

    Object.defineProperty(res, "json", {
      value: () => Promise.resolve(resJson),
      writable: true
    });
  };

  patchGlobalFetch = (): void => {
    const tmpFetch = globalThis.fetch;
    globalThis.fetch = (async (url, options) => {
      this.log(options?.method ?? "GET", url);

      return new Promise((appResolve, appReject) => {
        const urlObj = new URL(url as string);
        const urlPathname = urlObj.pathname;
        const urlIsNineAmHealth = isApiLink(urlObj.toString());
        const queryString = urlObj.search;

        if (!urlIsNineAmHealth) {
          tmpFetch(url as Request, options)
            .then(appResolve)
            .catch((e: unknown) => appReject(e as Error));
          return;
        }

        const isRequestWithoutAuth = this.config.requestsWithoutAuth.some(
          (requestPath) => urlPathname.includes(requestPath)
        );
        const isPriorityRequest =
          isRequestWithoutAuth ||
          this.config.priorityRequestsWithAuth.some((requestPath) =>
            urlPathname.includes(requestPath)
          );

        const requiresAuth = !isRequestWithoutAuth;
        const request = async () => {
          return new Promise((resolveRequest, rejectRequest) => {
            const startTime = Date.now();
            const endTimer = () => {
              const time = Date.now() - startTime;
              const pathKey = `[${options?.method}] ${urlPathname}${queryString}`;
              this.logTimer(pathKey, time);
            };

            const reject = (error: unknown) => {
              rejectRequest(error);
              appReject(error);
              endTimer();
            };

            const resolve = (res: Response) => {
              this.patchResponse(res)
                .then(() => {
                  resolveRequest(res);
                  appResolve(res);
                  endTimer();
                })
                .catch((error: unknown) => {
                  reject(error);
                });
            };

            // add access token to request
            const patchedOptions = this.patchRequestOptions(options, {
              requiresAuth,
              url: urlObj.toString()
            });

            this.config.logBreadcrumb(
              "api",
              `Request: "${urlPathname}". Method: "${options?.method}"`,
              "log"
            );

            // initial attempt to fetch
            void tmpFetch(url as Request, patchedOptions)
              .then((res) => {
                this.logTrace(urlPathname, res);
                // resolve if request succeeded
                if (res.ok) {
                  resolve(res);
                  return;
                }

                if (
                  this.config.requestsWithExpected401.some((requestPath) =>
                    urlPathname.includes(requestPath)
                  ) ||
                  isRequestWithoutAuth ||
                  urlObj.pathname.endsWith(this.config.refreshTokenEndpoint)
                ) {
                  resolve(res);
                  return;
                }

                // if request failed with 401, refresh access token and try again
                if (res.status === 401) {
                  // wait for token refresh to finish
                  void this.config.authState
                    .refreshAccessToken()
                    .then(() => {
                      // get the new access token
                      const patchedOptionsV2 = this.patchRequestOptions(
                        options,
                        {
                          requiresAuth,
                          url: urlObj.toString()
                        }
                      );

                      // try again with the new access token
                      void tmpFetch(url as Request, patchedOptionsV2)
                        .then((resAfterRefresh) => {
                          // resolve the promise if the request succeeds
                          resolve(resAfterRefresh);
                        })
                        .catch((error: unknown) => {
                          // if the request fails again, reject the promise
                          reject(error);
                        });
                    })
                    .catch((refreshError: unknown) => {
                      // reject if refresh token fails
                      reject(refreshError);
                    });
                } else {
                  // reject for other errors
                  resolve(res);
                  return;
                }
              })
              .catch((error: unknown) => {
                // reject for an initial attempt
                reject(error);
              });
          });
        };

        this.log("adding request to queue", url);
        if (isPriorityRequest) {
          this.requestsQueuePriority.push({
            request,
            options: { requiresAuth }
          });
        } else {
          this.requestQueue.push({ request, options: { requiresAuth } });
        }

        void this.processRequestQueue();
      });
    }) as typeof globalThis.fetch;
  };

  requestIncludeResponseHeaders = async <T>(
    method: () => Promise<T>
  ): Promise<{
    body: T;
    headers: Headers;
  }> => {
    let headers = new Headers();

    // mock the global fetch method
    const tmpFetch = globalThis.fetch;
    globalThis.fetch = (async (url, options) => {
      // run the request (do not wait for response)
      const requestPromise = tmpFetch(url as Request, options);
      // reset mock
      globalThis.fetch = tmpFetch;
      const response = await requestPromise;
      // eslint-disable-next-line @typescript-eslint/prefer-destructuring
      headers = response.headers;
      return response;
    }) as typeof globalThis.fetch;

    const apiResponse = await method();

    return {
      body: apiResponse,
      headers
    };
  };

  handleAuthStatusChange = (
    status: typeof this.config.authState.authenticationStatus
  ): void => {
    this.log("handleAuthStatusChange", status);
    if (status === "authenticated") {
      this.requestsQueuePriority = [];
      void this.processRequestQueue();
    }
  };
}
