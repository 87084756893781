import styled from "@emotion/styled";

export const StyledRadio = styled.input`
  --size-ratio: 1.5;
  --space-sm: 0.8rem;
  --color-charcoal-60: #21212199;
  --gradient-dusk: linear-gradient(
    90deg,
    #0e3763 0%,
    #5b4855 52.6%,
    #8a6947 100%
  );

  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  margin: 0 5px 0 0;
  width: calc(var(--space-sm) * var(--size-ratio));
  height: calc(var(--space-sm) * var(--size-ratio));
  border: 1px solid var(--color-charcoal-60);
  transform: translateY(0);
  display: grid;
  /* aligning ::before horizontally and vertically */
  place-content: center;
  position: relative;

  &::before {
    content: "";
    transform: scale(0);
    transition: transform 0.12s ease-in-out;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
  }

  &:checked {
    border: none;
    background: var(--gradient-blue-orange, linear-gradient(
      89.59deg,
      #80aeff 0.36%,
      #f7bde6 52.67%,
      #ffbd70 99.65%
    ));

    &::after {
      top: 1px;
      bottom: 1px;
      left: 1px;
      right: 1px;
      background: linear-gradient(
        270.11deg,
        #ffdfb4 16.73%,
        #fddee9 50.08%,
        #c1d7fb 81.42%
      );
    }

    &::before {
      transform: scale(1);
    }
  }

  border-radius: 50%;

  &::before {
    width: calc(var(--space-sm) * var(--size-ratio) * 0.5833);
    height: calc(var(--space-sm) * var(--size-ratio) * 0.5833);
    border-radius: 50%;
    --gradient-dir: 90deg;
    background: var(--gradient-dusk);
  }

  &:checked {
    &::after {
      border-radius: 50%;
    }

    &::before {
      transform: scale(1);
    }
  }
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
`;

export const RadioFieldError = styled.div`
  padding: 0.5em 1em 1em;
  display: block;
  color: var(--primary-warning-red, #bf1902);

  @keyframes show {
    from {
      opacity: 0;
      transform: translateY(-0.5rem);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  animation: show 0.2s linear forwards;
`;

export const RadioGroupWrapper = styled.div`
  &[aria-disabled="true"] * {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;
