import { ObservationControllerService } from "@9amhealth/openapi";
import { Cubit } from "blac";
import dayjs from "dayjs";
import type {
  Bundle,
  BundleEntry,
  CodeableConcept,
  DiagnosticReport,
  Observation,
  ObservationReferenceRange
} from "fhir/r4";
import { ObservationCategory } from "src/constants/ObservationCategory";
import type { ObservationSource } from "src/constants/ObservationSource";
import { LoincCodingCode, SYSTEM_LOINC } from "src/constants/fhir";
import { dateLocalString } from "src/lib/date";
import reportErrorSentry from "src/lib/reportErrorSentry";
import translate from "src/lib/translate";
import { loadingState } from "src/state/state";
import { LoadingKey } from "../LoadingCubit/LoadingCubit";
import { globalEvents } from "src/constants/globalEvents";

export interface CustomObservationReferenceRange
  extends ObservationReferenceRange {
  interpretationCode?: CodeableConcept;
  id?: string;
  code?: string;
  min?: number;
  max?: number;
  atLeastOneLimit?: boolean;
  onlyOneLimit?: boolean;
  widthPercentage?: number;
  cursorPositionPercentageFromLeft?: number;
}

export interface ObservationBundleSearchParams {
  observationDate?: string;
  category?: ObservationCategory;
  source?: ObservationSource;
  labOrderId?: string;
}

interface ObservationRangesData {
  observationValue?: number;
  ranges?: CustomObservationReferenceRange[];
  normalRange?: CustomObservationReferenceRange;
  normalRangeMin?: number;
  normalRangeMax?: number;
  totalWidth?: number;
}

interface ObservationBundleState {
  observationBundles?: Bundle<Observation>[];
  observationBundle?: Bundle<DiagnosticReport | Observation>;
  observations?: BundleEntry<Observation>[];
  observation?: Observation;
  observationRangesData?: ObservationRangesData;
}

export default class ObservationBundleBloc extends Cubit<ObservationBundleState> {
  constructor(observation?: Observation) {
    super({
      observationBundles: undefined,
      observationBundle: undefined,
      observations: undefined,
      observation,
      observationRangesData: undefined
    });
    window.addEventListener(globalEvents.USER_CLEAR, () => {
      this.emit({
        observationBundles: undefined,
        observationBundle: undefined,
        observations: undefined,
        observation: undefined,
        observationRangesData: undefined
      });
    });
  }

  public readonly loadObservationBundles = async (): Promise<void> => {
    loadingState.start(LoadingKey.loadingObservationBundles);
    try {
      const response = await ObservationControllerService.getObservationBundles(
        [ObservationCategory.LABORATORY]
      );

      // TODO: Update type if BE updates it
      const { data } = response as unknown as {
        status: string;
        data: Bundle<Observation>[];
      };

      // Sort in descending chronological order
      data.sort((a, b) => {
        if (a.timestamp && b.timestamp) {
          return dayjs(a.timestamp).isAfter(b.timestamp) ? -1 : 1;
        }

        return 1;
      });

      this.emit({
        ...this.state,
        observationBundles: data
      });
    } catch (e: unknown) {
      reportErrorSentry(e);
    } finally {
      loadingState.finish(LoadingKey.loadingObservationBundles);
    }
  };

  public readonly loadObservationBundle = async (
    searchParams: ObservationBundleSearchParams
  ): Promise<void> => {
    loadingState.start(LoadingKey.loadingObservationBundle);
    try {
      const response = await ObservationControllerService.getBundleByParams(
        {},
        searchParams.observationDate,
        searchParams.category,
        searchParams.source,
        searchParams.labOrderId
      );

      // TODO: Update type if BE updates it
      const { data } = response as unknown as {
        status: string;
        data: Bundle<DiagnosticReport | Observation>;
      };

      this.emit({
        ...this.state,
        observationBundle: data
      });
    } catch (e: unknown) {
      reportErrorSentry(e);
    } finally {
      loadingState.finish(LoadingKey.loadingObservationBundle);
    }
  };

  public readonly getObservationsFromBundle = (
    observationBundle?: Bundle<DiagnosticReport | Observation>
  ) => {
    const hiddenObservationCodes: LoincCodingCode[] = [
      LoincCodingCode.bmi,
      LoincCodingCode.height,
      LoincCodingCode.weight,
      LoincCodingCode.stepsInDay
    ];

    // Filter out  diagnostic report and bmi, height, and weight observations
    const filteredObservations = observationBundle?.entry?.filter((obs) => {
      return (
        obs.resource?.resourceType === "Observation" &&
        !hiddenObservationCodes.includes(
          obs.resource.code.coding?.[0].code as LoincCodingCode
        )
      );
    }) as BundleEntry<Observation>[] | undefined;

    // Sort observations alphabetically
    filteredObservations?.sort((a, b) =>
      (a.resource?.code.coding?.[0].display ?? "").localeCompare(
        b.resource?.code.coding?.[0].display ?? ""
      )
    );

    this.emit({
      ...this.state,
      observations: filteredObservations
    });
  };

  public readonly getObservationBundleTitle = (title?: string): string => {
    if (!title) {
      return "";
    }

    let observationBundleTitle = translate("observationBundleTitle", {
      context: "importedLabTest"
    });

    if (
      title.toLowerCase().includes("getlabs") ||
      title.toLowerCase().includes("bioreference")
    ) {
      observationBundleTitle = translate("observationBundleTitle", {
        context: "atHomeLabTest"
      });
    } else if (title.toLowerCase().includes("labcorp")) {
      observationBundleTitle = translate("observationBundleTitle", {
        context: "labcorp"
      });
    } else if (title.toLowerCase().includes("questdiagnostics")) {
      observationBundleTitle = translate("observationBundleTitle", {
        context: "quest"
      });
    } else if (title.toLowerCase().includes("tasso")) {
      observationBundleTitle = translate("observationBundleTitle", {
        context: "atHomeSelfTest"
      });
    }

    return observationBundleTitle;
  };

  public readonly getBundleData = (bundle: Bundle<Observation>) => {
    const link = `/app/lab-results/bundle?${
      bundle.link?.[0].url.split("?")[1]
    }`;
    const title = bundle.entry?.[0].resource?.performer?.[0].identifier?.value;
    const label = this.getObservationBundleTitle(title);
    const date = dateLocalString(bundle.timestamp);

    return { link, label, date };
  };

  public readonly getRangeContainingObservation = (
    observation: Observation
  ): CustomObservationReferenceRange | undefined => {
    const observationValue = observation.valueQuantity?.value;
    const observationRanges = observation.contained as
      | CustomObservationReferenceRange[]
      | undefined;

    if (typeof observationValue !== "number" || !observationRanges?.length) {
      return undefined;
    }

    // Sort ranges in order N,L,H,LL,HH (Order of evaluation (inside-out checking logic))
    const sortedObservationRanges = this.sortObservationRangesByCode(
      observationRanges,
      ["N", "L", "H", "LL", "HH"]
    );

    return sortedObservationRanges.find((range) => {
      const min = range.low?.value;
      const max = range.high?.value;

      if (typeof min === "number" && typeof max === "number") {
        if (observationValue >= min && observationValue <= max) {
          return true;
        }
      } else if (
        (typeof min === "number" && observationValue >= min) ||
        (typeof max === "number" && observationValue <= max)
      ) {
        return true;
      }

      return false;
    });
  };

  public readonly getObservationRangesData = (): void => {
    const { observation } = this.state;

    if (!observation) {
      return;
    }

    const observationValue = observation.valueQuantity?.value;
    const ranges = observation.contained as
      | CustomObservationReferenceRange[]
      | undefined;
    const normalRanges = ranges?.filter(
      (range) => range.interpretationCode?.coding?.[0].code === "N"
    );
    const normalRange = normalRanges?.[0];
    const normalRangeMin = normalRange?.low?.value;
    const normalRangeMax = normalRange?.high?.value;

    const minRangeValues = ranges?.map((range) => range.low?.value);
    const maxRangeValues = ranges?.map((range) => range.high?.value);
    const filteredMinRangeValues = minRangeValues
      ? minRangeValues.filter((value) => typeof value === "number")
      : [];
    const filteredMaxRangeValues = maxRangeValues
      ? maxRangeValues.filter((value) => typeof value === "number")
      : [];
    const totalMin = Math.min(...filteredMinRangeValues);
    const totalMax = Math.max(...filteredMaxRangeValues);
    const totalWidth = totalMax - totalMin;

    // Sort ranges in order LL,L,N,H,HH (natural order looking at range bar from left to right)
    const sortedObservationRanges = this.sortObservationRangesByCode(
      ranges ?? []
    );

    // Add custom properties to every range
    sortedObservationRanges.forEach((range) => {
      range.code = range.interpretationCode?.coding?.[0].code;
      range.min = range.low?.value;
      range.max = range.high?.value;

      range.atLeastOneLimit =
        typeof range.min === "number" || typeof range.max === "number";
      range.onlyOneLimit =
        typeof range.min !== "number" || typeof range.max !== "number";

      const onlyNormalRange = ranges?.length === 1 && Boolean(normalRange);
      range.widthPercentage = this.getRangeWidthPercentage(
        range,
        totalWidth,
        onlyNormalRange
      );

      range.cursorPositionPercentageFromLeft =
        this.getCursorPositionPercentageFromLeft(range, observationValue);
    });

    this.emit({
      ...this.state,
      observationRangesData: {
        observationValue,
        ranges: sortedObservationRanges,
        normalRange,
        normalRangeMin,
        normalRangeMax,
        totalWidth
      }
    });
  };

  public readonly getObservationTitle = (observation: Observation) => {
    const codings = observation.code.coding;
    let coding = codings?.find((item) => item.system !== SYSTEM_LOINC);
    if (!coding) {
      coding = codings?.[0];
    }
    return coding?.display;
  };

  public readonly getObservationData = (observation: Observation) => {
    const observationValueString = observation.valueString;
    const observationValue = observation.valueQuantity?.value;
    const observationUnit = observation.valueQuantity?.unit;
    const observationTitle = this.getObservationTitle(observation);

    const ranges = observation.contained as
      | CustomObservationReferenceRange[]
      | undefined;

    const isRangeDefined = ranges?.some(
      (range) =>
        typeof range.low?.value === "number" ||
        typeof range.high?.value === "number"
    );

    return {
      observationValueString,
      observationValue,
      observationUnit,
      observationTitle,
      ranges,
      isRangeDefined
    };
  };

  public readonly sortObservationRangesByCode = (
    ranges: CustomObservationReferenceRange[],
    codeOrder = ["LL", "L", "N", "H", "HH"]
  ) => {
    return [...ranges].sort((a, b) => {
      const codeA = a.interpretationCode?.coding?.[0].code;
      const codeB = b.interpretationCode?.coding?.[0].code;
      const codeAIndex = codeOrder.findIndex((code) => code === codeA);
      const codeBIndex = codeOrder.findIndex((code) => code === codeB);

      return codeAIndex < codeBIndex ? -1 : 1;
    });
  };

  //#region Reference range bar helpers (used mainly in ReferenceRangeBar.tsx)
  public readonly getCursorPositionPercentageFromLeft = (
    range: CustomObservationReferenceRange,
    value?: number
  ): number => {
    const min = range.low?.value;
    const max = range.high?.value;
    // Set percentage to 50 by default to cover cases with only one limit
    let percentage = 50;

    if (
      typeof value === "number" &&
      typeof min === "number" &&
      typeof max === "number"
    ) {
      percentage = ((value - min) / (max - min)) * 100;
    }

    return percentage;
  };

  public readonly getRangeWidthPercentage = (
    range?: CustomObservationReferenceRange,
    totalWidth?: number,
    onlyNormalRange: boolean = false
  ): number => {
    const rangeMin = range?.low?.value;
    const rangeMax = range?.high?.value;
    // Set percentage to 0 by default
    let percentage = 0;

    if (typeof rangeMin === "number" && typeof rangeMax === "number") {
      // If there is only normal range available, set percentage to 50%
      if (onlyNormalRange) {
        percentage = 50;
      } else {
        const rangeWidth = rangeMax - rangeMin;
        if (totalWidth) {
          percentage = (rangeWidth * 100) / totalWidth;
        }
      }
    } else if (typeof rangeMin === "number" || typeof rangeMax === "number") {
      percentage = 75;
    }

    if (percentage < 0) {
      percentage = 0;
    }
    if (percentage > 100) {
      percentage = 100;
    }

    return percentage;
  };
  //#endregion
}
