import React from "react";
import { subscriptionState, userPreferences, userState } from "src/state/state";
import type { SignupCustomCampaign } from "src/ui/components/SignupCustomContent/SignupCustom.types";
import {
  SignupCustomCampaignName,
  SignupCustomStepType
} from "src/ui/components/SignupCustomContent/SignupCustom.types";

import FunnelKey from "src/constants/funnelKey";

import {
  GetSuggestedTreatmentPlanResponse,
  SubscriptionDetailsResponse
} from "@9amhealth/openapi";
import {
  CDCDPP_ELIGIBILITY_QUESTIONNAIRE_ID,
  CDCDPP_PROGRAM_ITEM_SKU
} from "src/constants/misc";
import translate from "src/lib/translate";
import { StorageController } from "src/state/StorageBloc/StorageBloc";
import PersonalizedWelcomeTitle from "src/ui/components/PersonalizedWelcomeTitle/PersonalizedWelcomeTitle";
import CDCDPPCreatingProgram from "src/ui/components/SignupCustomContent/campaigns/cdcdpp/CDCDPPCreatingProgram";
import CDCDPPCustomizedCheckoutScreen from "src/ui/components/SignupCustomContent/campaigns/cdcdpp/CDCDPPCustomizedCheckoutScreen";
import HealthInformationExchangeStep from "src/ui/components/SignupCustomContent/HealthInformationExchangeStep";
import { SignupCustomStepCheckoutRawContent } from "../steps/SignupCustomStepCheckoutCustomRender";

export enum CDC_DPP_FunnelLocalStorageKeys {
  EligibilityCheckCompleted = "9am_cdcdpp_eligibility-check-completed",
  ShippingStepCompleted = "9am_cdcdpp_shipping-step-completed",
  ProcessedInformation = "9am_cdcdpp_processed-information"
}

/**
 * DPP custom campaign
 * /signup/dpp
 */
export const SignupCustomCampaignCDCDPP: SignupCustomCampaign = {
  name: SignupCustomCampaignName.CDC_DPP,
  logo: "https://cdn.sanity.io/images/g38rxyoc/production/8560aac996774505f5deee72c2611673a1907ce2-96x56.png",
  prettyName: "CDC-DPP",
  funnelKey: FunnelKey.cdc_dpp,
  steps: () => [
    {
      name: translate("healthInfoExchange"),
      path: "health-information-exchange",
      isRequired: false,
      type: SignupCustomStepType.InfoPage,

      dataComplete: async () => {
        return userPreferences.isHealthInformationExchangeCompleted;
      },
      render: () => {
        return <HealthInformationExchangeStep />;
      }
    },

    {
      name: translate("register"),
      path: "register",
      type: SignupCustomStepType.Authentication,
      dataComplete: async () => userState.hasAppAccess()
    },
    {
      name: translate("eligibility"),
      path: "eligibility",
      type: SignupCustomStepType.Questionnaire,
      questionnaireId: CDCDPP_ELIGIBILITY_QUESTIONNAIRE_ID,
      questionnaireName: "Signup Questionnaire",
      dataComplete: async (): Promise<boolean> => {
        const [treatment, answeredQuestionnaire] = await Promise.all([
          subscriptionState.checkTreatmentTargetEligibility(
            subscriptionState.knownTreatmentPlanTargets.CDC_DPP
          ),
          userState.checkUserHasAnsweredQuestionnaire(
            CDCDPP_ELIGIBILITY_QUESTIONNAIRE_ID
          )
        ]);

        return (
          treatment?.eligibility ===
            GetSuggestedTreatmentPlanResponse.eligibility.ELIGIBLE &&
          Boolean(answeredQuestionnaire)
        );
      },
      onNextStep: (): void => {
        StorageController.setItem(
          CDC_DPP_FunnelLocalStorageKeys.EligibilityCheckCompleted,
          "true"
        );
      }
    },
    {
      name: translate("shippingAddress"),
      path: "shipping",
      type: SignupCustomStepType.ShippingAddress,
      dataComplete: async (): Promise<boolean> => {
        const hasShipping = await userPreferences.checkUserHasShippingAddress();
        const hasCompletedStep = Boolean(
          StorageController.getItem(
            CDC_DPP_FunnelLocalStorageKeys.ShippingStepCompleted
          )
        );

        return hasShipping && hasCompletedStep;
      },
      onNextStep: (): void => {
        StorageController.setItem(
          CDC_DPP_FunnelLocalStorageKeys.ShippingStepCompleted,
          "true"
        );
      },
      shippingFormPageProps: {
        title: translate("yourDetails")
      }
    },
    {
      name: translate("processing"),
      path: "processing",
      type: SignupCustomStepType.InfoPage,
      dataComplete: async (): Promise<boolean> =>
        StorageController.getItem(
          CDC_DPP_FunnelLocalStorageKeys.ProcessedInformation
        ) === "true",
      render: () => <CDCDPPCreatingProgram />
    },
    {
      name: translate("reviewCheckout"),
      path: "checkout",
      type: SignupCustomStepType.InfoPage,
      dataComplete: async (): Promise<boolean> => {
        await subscriptionState.loadAllSubscriptions();
        const funnelSpecificSubscription =
          subscriptionState.filterAllSubscriptions({
            status: [
              SubscriptionDetailsResponse.status.ACTIVE,
              SubscriptionDetailsResponse.status.IN_REVIEW
            ],
            items: [CDCDPP_PROGRAM_ITEM_SKU],
            looseCheck: true
          })[0] as SubscriptionDetailsResponse | undefined;

        return Boolean(funnelSpecificSubscription);
      },
      render: () => (
        <SignupCustomStepCheckoutRawContent
          loading={false}
          content={{
            title: translate("cdcdcpp.title"),
            subtitle: translate("cdcdpp.description"),
            showMoneyBackGuarantee: false,
            infoSections: () => <CDCDPPCustomizedCheckoutScreen />
          }}
          showShipping={false}
          totalPrice={0}
          priceAfterDiscount={0}
          labTestsIncluded={false}
          cartItems={{
            onboarding: {
              show: false,
              fee: 0
            },
            ongoingCare: {
              show: false,
              fee: 0
            }
          }}
        />
      )
    },
    {
      name: translate("success"),
      path: "checkout-success",
      type: SignupCustomStepType.SuccessPage,
      successPageProps: {
        titleSpacer: false,
        title: <PersonalizedWelcomeTitle />,
        subtitle: translate("cdcdpp.tasks")
      }
    }
  ]
};
