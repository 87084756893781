import type { FC } from "react";
import React, { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { appStatusBarState, tracker, useBloc } from "src/state/state";
import CustomSuspense from "../CustomSuspense/CustomSuspense";
import AppQueryPopupsBloc, {
  AppPopup,
  AppQueryPopupsController
} from "./AppQueryPopupsBloc";

const Async: Record<
  AppPopup,
  | React.LazyExoticComponent<FC<{ returnUrl: string }>>
  | React.LazyExoticComponent<FC<{ returnUrl?: string }>>
  | undefined
> = {
  [AppPopup.medicalInsurance]: React.lazy(
    async () =>
      import("src/ui/components/AppQueryPopups/dialogs/EnterMedicalInsurance")
  ),
  [AppPopup.appointmentBooking]: React.lazy(
    async () =>
      import(
        "src/ui/components/AppQueryPopups/dialogs/AppointmentBookingDialog"
      )
  ),
  [AppPopup.rpmDailyReadings]: React.lazy(
    async () => import("src/ui/components/Chat/dialogs/DailyReadingsDialog")
  ),
  [AppPopup.uploadLabReport]: React.lazy(
    async () =>
      import("src/ui/components/Chat/dialogs/ChatUploadLabResultDialog")
  ),
  [AppPopup.enterWaistCircumference]: React.lazy(
    async () =>
      import("src/ui/components/Chat/dialogs/WaistCircumferenceDialog")
  ),
  [AppPopup.enterBloodGlucose]: React.lazy(
    async () => import("src/ui/components/Chat/dialogs/BloodGlucoseDialog")
  ),
  [AppPopup.enterBloodPressure]: React.lazy(
    async () => import("src/ui/components/Chat/dialogs/BloodPressureDialog")
  ),
  [AppPopup.enterWeight]: React.lazy(
    async () => import("src/ui/components/Chat/dialogs/WeightDialog")
  ),
  [AppPopup.profileSelectPCP]: React.lazy(
    async () => import("src/ui/components/AppQueryPopups/dialogs/SelectPcp")
  ),
  [AppPopup.profileSelectPharmacy]: React.lazy(
    async () =>
      import("src/ui/components/AppQueryPopups/dialogs/SelectPharmacy")
  ),
  [AppPopup.profileEnterPharmacyInsurance]: React.lazy(
    async () =>
      import("src/ui/components/AppQueryPopups/dialogs/EnterPharmacyInsurance")
  ),
  [AppPopup.questionnaire]: React.lazy(
    async () =>
      import("src/ui/components/InAppQuestionnaire/InAppQuestionnaire")
  ),
  [AppPopup.behavioralHealth]: React.lazy(
    async () =>
      import("src/ui/components/AppQueryPopups/dialogs/BehavioralHealthDialog")
  ),
  [AppPopup.teamDetails]: React.lazy(
    async () => import("src/ui/components/Chat/dialogs/ChatCareTeamDialog")
  ),
  [AppPopup.iframe]: React.lazy(
    async () => import("src/ui/components/AppQueryPopups/dialogs/IframePopup")
  ),
  [AppPopup.scheduling]: React.lazy(
    async () => import("src/ui/components/AppQueryPopups/dialogs/IframePopup")
  ),
  [AppPopup.article]: React.lazy(
    async () => import("src/ui/components/AppQueryPopups/dialogs/IframePopup")
  ),
  [AppPopup.appointment]: React.lazy(
    async () =>
      import("src/ui/components/AppQueryPopups/dialogs/AppointmentDialog")
  ),
  [AppPopup.rescheduleAppointment]: React.lazy(
    async () =>
      import(
        "src/ui/components/AppQueryPopups/dialogs/RescheduleAppointmentDialog"
      )
  ),
  [AppPopup.rxDietNutritionProgram]: React.lazy(
    async () =>
      import(
        "src/ui/components/AppQueryPopups/dialogs/RxDietNutritionProgramDialog"
      )
  ),
  [AppPopup.healthSyncSetup]: React.lazy(
    async () =>
      import("src/ui/components/AppQueryPopups/dialogs/HealthSyncSetupDialog")
  ),
  [AppPopup.lifestyleProgram]: React.lazy(
    async () =>
      import("src/ui/components/AppQueryPopups/dialogs/LifestyleProgramDialog")
  ),
  [AppPopup.initialLab]: React.lazy(
    async () =>
      import("src/ui/components/AppQueryPopups/dialogs/InitialLabDialog")
  ),
  [AppPopup.labInstructions]: React.lazy(
    async () =>
      import("src/ui/components/AppQueryPopups/dialogs/LabInstructionsDialog")
  ),
  [AppPopup.sanityContent]: React.lazy(
    async () => import("src/ui/components/AppQueryPopups/dialogs/SanityDialog")
  ),
  [AppPopup.onboardingTasks]: React.lazy(
    async () =>
      import("src/ui/components/AppQueryPopups/dialogs/OnboardingTasksPopup")
  ),
  [AppPopup.fastPass]: React.lazy(
    async () => import("src/ui/components/AppQueryPopups/dialogs/FastPassPopup")
  ),
  [AppPopup.glp1StartDate]: React.lazy(
    async () =>
      import("src/ui/components/AppQueryPopups/dialogs/GLP1StartDateDialog")
  ),
  [AppPopup.faq]: React.lazy(
    async () => import("src/ui/components/AppQueryPopups/dialogs/FaqDialog")
  )
};

const getPopupComponent = (popup: string) => {
  const lowercasePopup = popup.toLowerCase();
  for (const key in Async) {
    if (key.toLowerCase() === lowercasePopup) {
      return Async[key as AppPopup];
    }
  }
};

const AppQueryPopups = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [query] = useSearchParams();
  const [, { setNavigate, setCurrentQuery, dismissModal }] = useBloc(
    AppQueryPopupsBloc,
    {
      create: () => AppQueryPopupsController
    }
  );

  const popupQuery = query.get("popup") ?? "";
  const stayQuery = query.get("stay") ?? "true";

  setNavigate(navigate);

  useEffect(() => {
    setCurrentQuery(popupQuery, stayQuery);
    if (popupQuery) {
      tracker.track("App Popup Opened", {
        data: {
          "Popup ID": popupQuery
        }
      });
      appStatusBarState.setThemeDark();
    } else {
      void dismissModal?.dismiss();
      appStatusBarState.resetThemeDefault();
    }
  }, [popupQuery, stayQuery]);

  const Component = getPopupComponent(popupQuery);

  return (
    <CustomSuspense>
      {Component && <Component returnUrl={pathname} />}
    </CustomSuspense>
  );
};

export default AppQueryPopups;
